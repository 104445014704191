const data = {
"Table1":[
 {
  "0": 0,
  "1": 80,
  "2": 60,
  "3": 80,
  "4": 60,
  "5": 80,
  "6": 60,
  "7": 80,
  "8": 60,
  "9": 80,
  "10": 60,
  "11": 80,
  "12": 60,
  "13": 80,
  "14": 60,
  "15": 80,
  "16": 60,
  "17": 80,
  "18": 60,
  "19": 80,
  "20": 60
 },
 {
  "0": 10,
  "1": 90,
  "2": 70,
  "3": 90,
  "4": 70,
  "5": 90,
  "6": 70,
  "7": 90,
  "8": 70,
  "9": 90,
  "10": 70,
  "11": 90,
  "12": 70,
  "13": 90,
  "14": 70,
  "15": 90,
  "16": 70,
  "17": 90,
  "18": 70,
  "19": 90,
  "20": 70
 },
 {
  "0": 20,
  "1": 100,
  "2": 80,
  "3": 100,
  "4": 80,
  "5": 100,
  "6": 80,
  "7": 100,
  "8": 80,
  "9": 100,
  "10": 80,
  "11": 100,
  "12": 80,
  "13": 100,
  "14": 80,
  "15": 100,
  "16": 80,
  "17": 100,
  "18": 80,
  "19": 100,
  "20": 80
 },
 {
  "0": 30,
  "1": 110,
  "2": 90,
  "3": 110,
  "4": 90,
  "5": 110,
  "6": 90,
  "7": 110,
  "8": 90,
  "9": 110,
  "10": 90,
  "11": 110,
  "12": 90,
  "13": 110,
  "14": 90,
  "15": 110,
  "16": 90,
  "17": 110,
  "18": 90,
  "19": 110,
  "20": 90
 },
 {
  "0": 40,
  "1": 120,
  "2": 100,
  "3": 120,
  "4": 100,
  "5": 120,
  "6": 100,
  "7": 120,
  "8": 100,
  "9": 120,
  "10": 100,
  "11": 120,
  "12": 100,
  "13": 120,
  "14": 100,
  "15": 120,
  "16": 100,
  "17": 120,
  "18": 100,
  "19": 120,
  "20": 100
 },
 {
  "0": 50,
  "1": 130,
  "2": 110,
  "3": 130,
  "4": 110,
  "5": 130,
  "6": 110,
  "7": 130,
  "8": 110,
  "9": 130,
  "10": 110,
  "11": 130,
  "12": 110,
  "13": 130,
  "14": 110,
  "15": 130,
  "16": 110,
  "17": 130,
  "18": 110,
  "19": 130,
  "20": 110
 },
 {
  "0": 60,
  "1": 140,
  "2": 120,
  "3": 140,
  "4": 120,
  "5": 140,
  "6": 120,
  "7": 140,
  "8": 120,
  "9": 140,
  "10": 120,
  "11": 140,
  "12": 120,
  "13": 140,
  "14": 120,
  "15": 140,
  "16": 120,
  "17": 140,
  "18": 120,
  "19": 140,
  "20": 120
 },
 {
  "0": 61,
  "1": 150,
  "2": 120,
  "3": 150,
  "4": 120,
  "5": 140,
  "6": 120,
  "7": 140,
  "8": 120,
  "9": 140,
  "10": 120,
  "11": 140,
  "12": 120,
  "13": 140,
  "14": 120,
  "15": 140,
  "16": 120,
  "17": 140,
  "18": 120,
  "19": 140,
  "20": 120
 },
 {
  "0": 62,
  "1": 150,
  "2": 120,
  "3": 150,
  "4": 120,
  "5": 140,
  "6": 120,
  "7": 140,
  "8": 120,
  "9": 140,
  "10": 120,
  "11": 140,
  "12": 120,
  "13": 140,
  "14": 120,
  "15": 140,
  "16": 120,
  "17": 140,
  "18": 120,
  "19": 140,
  "20": 120
 },
 {
  "0": 63,
  "1": 150,
  "2": 120,
  "3": 150,
  "4": 120,
  "5": 150,
  "6": 120,
  "7": 140,
  "8": 120,
  "9": 140,
  "10": 120,
  "11": 140,
  "12": 120,
  "13": 140,
  "14": 120,
  "15": 140,
  "16": 120,
  "17": 140,
  "18": 120,
  "19": 140,
  "20": 120
 },
 {
  "0": 64,
  "1": 160,
  "2": 130,
  "3": 150,
  "4": 130,
  "5": 150,
  "6": 130,
  "7": 140,
  "8": 130,
  "9": 140,
  "10": 120,
  "11": 140,
  "12": 120,
  "13": 140,
  "14": 120,
  "15": 140,
  "16": 120,
  "17": 140,
  "18": 120,
  "19": 140,
  "20": 120
 },
 {
  "0": 65,
  "1": 160,
  "2": 130,
  "3": 160,
  "4": 130,
  "5": 160,
  "6": 130,
  "7": 140,
  "8": 130,
  "9": 140,
  "10": 130,
  "11": 140,
  "12": 130,
  "13": 140,
  "14": 120,
  "15": 140,
  "16": 120,
  "17": 140,
  "18": 120,
  "19": 140,
  "20": 120
 },
 {
  "0": 66,
  "1": 160,
  "2": 130,
  "3": 160,
  "4": 130,
  "5": 160,
  "6": 130,
  "7": 150,
  "8": 130,
  "9": 140,
  "10": 130,
  "11": 140,
  "12": 130,
  "13": 140,
  "14": 120,
  "15": 140,
  "16": 120,
  "17": 140,
  "18": 120,
  "19": 140,
  "20": 120
 },
 {
  "0": 67,
  "1": 170,
  "2": 130,
  "3": 170,
  "4": 130,
  "5": 160,
  "6": 130,
  "7": 150,
  "8": 130,
  "9": 140,
  "10": 130,
  "11": 140,
  "12": 130,
  "13": 140,
  "14": 130,
  "15": 140,
  "16": 120,
  "17": 140,
  "18": 120,
  "19": 140,
  "20": 120
 },
 {
  "0": 68,
  "1": 180,
  "2": 130,
  "3": 170,
  "4": 130,
  "5": 170,
  "6": 130,
  "7": 160,
  "8": 130,
  "9": 150,
  "10": 130,
  "11": 140,
  "12": 130,
  "13": 140,
  "14": 130,
  "15": 140,
  "16": 120,
  "17": 140,
  "18": 120,
  "19": 140,
  "20": 120
 },
 {
  "0": 69,
  "1": 190,
  "2": 130,
  "3": 180,
  "4": 130,
  "5": 180,
  "6": 130,
  "7": 170,
  "8": 130,
  "9": 150,
  "10": 130,
  "11": 150,
  "12": 130,
  "13": 140,
  "14": 130,
  "15": 140,
  "16": 120,
  "17": 140,
  "18": 120,
  "19": 140,
  "20": 120
 },
 {
  "0": 70,
  "1": 190,
  "2": 130,
  "3": 190,
  "4": 130,
  "5": 190,
  "6": 130,
  "7": 180,
  "8": 130,
  "9": 160,
  "10": 130,
  "11": 150,
  "12": 130,
  "13": 150,
  "14": 130,
  "15": 150,
  "16": 130,
  "17": 140,
  "18": 120,
  "19": 140,
  "20": 120
 },
 {
  "0": 71,
  "1": 190,
  "2": 140,
  "3": 200,
  "4": 140,
  "5": 200,
  "6": 140,
  "7": 190,
  "8": 140,
  "9": 170,
  "10": 140,
  "11": 150,
  "12": 130,
  "13": 150,
  "14": 130,
  "15": 150,
  "16": 130,
  "17": 150,
  "18": 130,
  "19": 140,
  "20": 120
 },
 {
  "0": 72,
  "1": 200,
  "2": 140,
  "3": 200,
  "4": 140,
  "5": 200,
  "6": 140,
  "7": 190,
  "8": 140,
  "9": 180,
  "10": 140,
  "11": 150,
  "12": 140,
  "13": 150,
  "14": 130,
  "15": 150,
  "16": 130,
  "17": 150,
  "18": 130,
  "19": 150,
  "20": 130
 },
 {
  "0": 73,
  "1": 200,
  "2": 140,
  "3": 210,
  "4": 140,
  "5": 210,
  "6": 140,
  "7": 200,
  "8": 140,
  "9": 180,
  "10": 140,
  "11": 160,
  "12": 140,
  "13": 150,
  "14": 140,
  "15": 150,
  "16": 130,
  "17": 150,
  "18": 130,
  "19": 150,
  "20": 130
 },
 {
  "0": 74,
  "1": 210,
  "2": 140,
  "3": 210,
  "4": 140,
  "5": 210,
  "6": 140,
  "7": 200,
  "8": 140,
  "9": 190,
  "10": 140,
  "11": 160,
  "12": 140,
  "13": 150,
  "14": 140,
  "15": 150,
  "16": 130,
  "17": 150,
  "18": 130,
  "19": 150,
  "20": 130
 },
 {
  "0": 75,
  "1": 210,
  "2": 140,
  "3": 220,
  "4": 140,
  "5": 220,
  "6": 140,
  "7": 210,
  "8": 140,
  "9": 190,
  "10": 140,
  "11": 170,
  "12": 140,
  "13": 150,
  "14": 140,
  "15": 150,
  "16": 130,
  "17": 150,
  "18": 130,
  "19": 150,
  "20": 130
 },
 {
  "0": 76,
  "1": 210,
  "2": 140,
  "3": 220,
  "4": 140,
  "5": 220,
  "6": 140,
  "7": 210,
  "8": 140,
  "9": 200,
  "10": 140,
  "11": 180,
  "12": 140,
  "13": 150,
  "14": 140,
  "15": 150,
  "16": 130,
  "17": 150,
  "18": 130,
  "19": 150,
  "20": 130
 },
 {
  "0": 77,
  "1": 220,
  "2": 140,
  "3": 230,
  "4": 140,
  "5": 230,
  "6": 140,
  "7": 220,
  "8": 140,
  "9": 200,
  "10": 140,
  "11": 190,
  "12": 140,
  "13": 150,
  "14": 140,
  "15": 150,
  "16": 130,
  "17": 150,
  "18": 130,
  "19": 150,
  "20": 130
 },
 {
  "0": 78,
  "1": 220,
  "2": 150,
  "3": 230,
  "4": 150,
  "5": 230,
  "6": 140,
  "7": 220,
  "8": 140,
  "9": 210,
  "10": 140,
  "11": 190,
  "12": 140,
  "13": 160,
  "14": 140,
  "15": 150,
  "16": 130,
  "17": 150,
  "18": 130,
  "19": 150,
  "20": 130
 },
 {
  "0": 79,
  "1": 230,
  "2": 150,
  "3": 240,
  "4": 150,
  "5": 240,
  "6": 150,
  "7": 230,
  "8": 150,
  "9": 210,
  "10": 150,
  "11": 200,
  "12": 150,
  "13": 160,
  "14": 150,
  "15": 160,
  "16": 130,
  "17": 160,
  "18": 130,
  "19": 150,
  "20": 130
 },
 {
  "0": 80,
  "1": 230,
  "2": 150,
  "3": 240,
  "4": 150,
  "5": 240,
  "6": 150,
  "7": 230,
  "8": 150,
  "9": 210,
  "10": 150,
  "11": 200,
  "12": 150,
  "13": 170,
  "14": 150,
  "15": 160,
  "16": 140,
  "17": 160,
  "18": 140,
  "19": 150,
  "20": 140
 },
 {
  "0": 81,
  "1": 230,
  "2": 150,
  "3": 250,
  "4": 150,
  "5": 250,
  "6": 150,
  "7": 240,
  "8": 150,
  "9": 220,
  "10": 150,
  "11": 210,
  "12": 150,
  "13": 180,
  "14": 150,
  "15": 160,
  "16": 140,
  "17": 160,
  "18": 140,
  "19": 150,
  "20": 140
 },
 {
  "0": 82,
  "1": 240,
  "2": 150,
  "3": 250,
  "4": 150,
  "5": 250,
  "6": 150,
  "7": 240,
  "8": 150,
  "9": 230,
  "10": 150,
  "11": 210,
  "12": 150,
  "13": 180,
  "14": 150,
  "15": 160,
  "16": 140,
  "17": 160,
  "18": 140,
  "19": 160,
  "20": 140
 },
 {
  "0": 83,
  "1": 250,
  "2": 150,
  "3": 250,
  "4": 150,
  "5": 250,
  "6": 150,
  "7": 250,
  "8": 150,
  "9": 230,
  "10": 150,
  "11": 210,
  "12": 150,
  "13": 190,
  "14": 150,
  "15": 160,
  "16": 140,
  "17": 160,
  "18": 140,
  "19": 160,
  "20": 140
 },
 {
  "0": 84,
  "1": 260,
  "2": 150,
  "3": 260,
  "4": 150,
  "5": 260,
  "6": 150,
  "7": 250,
  "8": 150,
  "9": 240,
  "10": 150,
  "11": 220,
  "12": 150,
  "13": 190,
  "14": 150,
  "15": 170,
  "16": 140,
  "17": 160,
  "18": 140,
  "19": 160,
  "20": 140
 },
 {
  "0": 85,
  "1": 260,
  "2": 150,
  "3": 260,
  "4": 150,
  "5": 270,
  "6": 150,
  "7": 260,
  "8": 150,
  "9": 240,
  "10": 150,
  "11": 230,
  "12": 150,
  "13": 200,
  "14": 150,
  "15": 180,
  "16": 140,
  "17": 160,
  "18": 140,
  "19": 160,
  "20": 140
 },
 {
  "0": 86,
  "1": 260,
  "2": 150,
  "3": 270,
  "4": 150,
  "5": 270,
  "6": 160,
  "7": 260,
  "8": 150,
  "9": 250,
  "10": 150,
  "11": 230,
  "12": 150,
  "13": 200,
  "14": 150,
  "15": 190,
  "16": 140,
  "17": 160,
  "18": 140,
  "19": 160,
  "20": 140
 },
 {
  "0": 87,
  "1": 270,
  "2": 160,
  "3": 270,
  "4": 160,
  "5": 280,
  "6": 160,
  "7": 270,
  "8": 150,
  "9": 250,
  "10": 150,
  "11": 240,
  "12": 150,
  "13": 210,
  "14": 150,
  "15": 190,
  "16": 140,
  "17": 160,
  "18": 140,
  "19": 160,
  "20": 140
 },
 {
  "0": 88,
  "1": 270,
  "2": 160,
  "3": 280,
  "4": 160,
  "5": 290,
  "6": 160,
  "7": 280,
  "8": 160,
  "9": 260,
  "10": 150,
  "11": 240,
  "12": 150,
  "13": 210,
  "14": 150,
  "15": 200,
  "16": 140,
  "17": 160,
  "18": 140,
  "19": 160,
  "20": 140
 },
 {
  "0": 89,
  "1": 270,
  "2": 160,
  "3": 290,
  "4": 170,
  "5": 300,
  "6": 170,
  "7": 290,
  "8": 160,
  "9": 260,
  "10": 150,
  "11": 250,
  "12": 150,
  "13": 220,
  "14": 150,
  "15": 200,
  "16": 140,
  "17": 170,
  "18": 140,
  "19": 160,
  "20": 140
 },
 {
  "0": 90,
  "1": 280,
  "2": 160,
  "3": 310,
  "4": 170,
  "5": 310,
  "6": 170,
  "7": 300,
  "8": 160,
  "9": 270,
  "10": 160,
  "11": 250,
  "12": 160,
  "13": 230,
  "14": 160,
  "15": 210,
  "16": 150,
  "17": 170,
  "18": 150,
  "19": 160,
  "20": 150
 },
 {
  "0": 91,
  "1": 290,
  "2": 170,
  "3": 310,
  "4": 180,
  "5": 320,
  "6": 180,
  "7": 310,
  "8": 170,
  "9": 280,
  "10": 160,
  "11": 260,
  "12": 160,
  "13": 240,
  "14": 160,
  "15": 210,
  "16": 150,
  "17": 180,
  "18": 150,
  "19": 160,
  "20": 150
 },
 {
  "0": 92,
  "1": 290,
  "2": 170,
  "3": 320,
  "4": 180,
  "5": 320,
  "6": 180,
  "7": 310,
  "8": 170,
  "9": 290,
  "10": 160,
  "11": 270,
  "12": 160,
  "13": 250,
  "14": 160,
  "15": 210,
  "16": 150,
  "17": 180,
  "18": 150,
  "19": 170,
  "20": 150
 },
 {
  "0": 93,
  "1": 300,
  "2": 170,
  "3": 320,
  "4": 180,
  "5": 320,
  "6": 180,
  "7": 320,
  "8": 180,
  "9": 300,
  "10": 170,
  "11": 280,
  "12": 160,
  "13": 260,
  "14": 160,
  "15": 220,
  "16": 150,
  "17": 190,
  "18": 150,
  "19": 180,
  "20": 150
 },
 {
  "0": 94,
  "1": 300,
  "2": 180,
  "3": 320,
  "4": 190,
  "5": 320,
  "6": 190,
  "7": 320,
  "8": 180,
  "9": 310,
  "10": 170,
  "11": 290,
  "12": 160,
  "13": 270,
  "14": 160,
  "15": 230,
  "16": 150,
  "17": 200,
  "18": 150,
  "19": 190,
  "20": 150
 },
 {
  "0": 95,
  "1": 310,
  "2": 180,
  "3": 320,
  "4": 190,
  "5": 320,
  "6": 200,
  "7": 320,
  "8": 190,
  "9": 320,
  "10": 180,
  "11": 300,
  "12": 170,
  "13": 280,
  "14": 160,
  "15": 240,
  "16": 150,
  "17": 200,
  "18": 150,
  "19": 200,
  "20": 150
 },
 {
  "0": 96,
  "1": 320,
  "2": 180,
  "3": 320,
  "4": 200,
  "5": 320,
  "6": 200,
  "7": 320,
  "8": 200,
  "9": 320,
  "10": 180,
  "11": 310,
  "12": 180,
  "13": 290,
  "14": 160,
  "15": 250,
  "16": 150,
  "17": 210,
  "18": 150,
  "19": 200,
  "20": 150
 },
 {
  "0": 97,
  "1": 320,
  "2": 190,
  "3": 320,
  "4": 200,
  "5": 320,
  "6": 200,
  "7": 320,
  "8": 200,
  "9": 320,
  "10": 190,
  "11": 320,
  "12": 180,
  "13": 300,
  "14": 170,
  "15": 260,
  "16": 160,
  "17": 220,
  "18": 150,
  "19": 200,
  "20": 150
 },
 {
  "0": 98,
  "1": 320,
  "2": 200,
  "3": 320,
  "4": 210,
  "5": 320,
  "6": 210,
  "7": 320,
  "8": 210,
  "9": 320,
  "10": 190,
  "11": 320,
  "12": 190,
  "13": 310,
  "14": 170,
  "15": 270,
  "16": 170,
  "17": 230,
  "18": 150,
  "19": 210,
  "20": 150
 },
 {
  "0": 99,
  "1": 330,
  "2": 200,
  "3": 330,
  "4": 220,
  "5": 330,
  "6": 220,
  "7": 330,
  "8": 220,
  "9": 330,
  "10": 200,
  "11": 330,
  "12": 200,
  "13": 320,
  "14": 180,
  "15": 280,
  "16": 180,
  "17": 240,
  "18": 160,
  "19": 220,
  "20": 160
 },
 {
  "0": 100,
  "1": 340,
  "2": 210,
  "3": 340,
  "4": 230,
  "5": 340,
  "6": 230,
  "7": 340,
  "8": 230,
  "9": 340,
  "10": 210,
  "11": 340,
  "12": 210,
  "13": 330,
  "14": 190,
  "15": 290,
  "16": 190,
  "17": 250,
  "18": 170,
  "19": 230,
  "20": 170
 },
 {
  "0": "Points",
  "1": "M",
  "2": "F",
  "3": "M",
  "4": "F",
  "5": "M",
  "6": "F",
  "7": "M",
  "8": "F",
  "9": "M",
  "10": "F",
  "11": "M",
  "12": "F",
  "13": "M",
  "14": "F",
  "15": "M",
  "16": "F",
  "17": "M",
  "18": "F",
  "19": "M",
  "20": "F"
 },
 {
  "1": "MaxDeadlift(MDL)-Three-Repetitions(LBS)"
 },
 {
  "1": "17-21",
  "3": "22-26",
  "5": "27-31",
  "7": "32-36",
  "9": "37-41",
  "11": "42-46",
  "13": "47-51",
  "15": "52-56",
  "17": "57-61",
  "19": "Over62"
 }
],
"Table2":[
 {
  "0": 0,
  "1": 4,
  "2": 2.6,
  "3": 4.2,
  "4": 2.7,
  "5": 4.3,
  "6": 2.8,
  "7": 4.4,
  "8": 2.8,
  "9": 4.4,
  "10": 2.8,
  "11": 4.3,
  "12": 2.5,
  "13": 4.1,
  "14": 2.3,
  "15": 4,
  "16": 2.3,
  "17": 4,
  "18": 2.3,
  "19": 3.7,
  "20": 2.3
 },
 {
  "0": 1,
  "1": 4,
  "2": 2.6,
  "3": 4.2,
  "4": 2.7,
  "5": 4.3,
  "6": 2.8,
  "7": 4.4,
  "8": 2.8,
  "9": 4.4,
  "10": 2.8,
  "11": 4.3,
  "12": 2.5,
  "13": 4.1,
  "14": 2.3,
  "15": 4,
  "16": 2.3,
  "17": 4,
  "18": 2.3,
  "19": 3.7,
  "20": 2.3
 },
 {
  "0": 2,
  "1": 4.1,
  "2": 2.7,
  "3": 4.3,
  "4": 2.8,
  "5": 4.4,
  "6": 2.9,
  "7": 4.5,
  "8": 2.9,
  "9": 4.5,
  "10": 2.9,
  "11": 4.4,
  "12": 2.6,
  "13": 4.2,
  "14": 2.4,
  "15": 4.1,
  "16": 2.4,
  "17": 4.1,
  "18": 2.4,
  "19": 3.8,
  "20": 2.4
 },
 {
  "0": 3,
  "1": 4.1,
  "2": 2.7,
  "3": 4.3,
  "4": 2.8,
  "5": 4.4,
  "6": 2.9,
  "7": 4.5,
  "8": 2.9,
  "9": 4.5,
  "10": 2.9,
  "11": 4.4,
  "12": 2.6,
  "13": 4.2,
  "14": 2.4,
  "15": 4.1,
  "16": 2.4,
  "17": 4.1,
  "18": 2.4,
  "19": 3.8,
  "20": 2.4
 },
 {
  "0": 4,
  "1": 4.2,
  "2": 2.8,
  "3": 4.4,
  "4": 2.9,
  "5": 4.5,
  "6": 3,
  "7": 4.6,
  "8": 3,
  "9": 4.6,
  "10": 3,
  "11": 4.5,
  "12": 2.7,
  "13": 4.3,
  "14": 2.5,
  "15": 4.2,
  "16": 2.5,
  "17": 4.2,
  "18": 2.5,
  "19": 3.9,
  "20": 2.5
 },
 {
  "0": 5,
  "1": 4.2,
  "2": 2.8,
  "3": 4.4,
  "4": 2.9,
  "5": 4.5,
  "6": 3,
  "7": 4.6,
  "8": 3,
  "9": 4.6,
  "10": 3,
  "11": 4.5,
  "12": 2.7,
  "13": 4.3,
  "14": 2.5,
  "15": 4.2,
  "16": 2.5,
  "17": 4.2,
  "18": 2.5,
  "19": 3.9,
  "20": 2.5
 },
 {
  "0": 6,
  "1": 4.3,
  "2": 2.9,
  "3": 4.5,
  "4": 3,
  "5": 4.6,
  "6": 3.1,
  "7": 4.7,
  "8": 3.1,
  "9": 4.7,
  "10": 3.1,
  "11": 4.6,
  "12": 2.8,
  "13": 4.4,
  "14": 2.6,
  "15": 4.3,
  "16": 2.6,
  "17": 4.3,
  "18": 2.6,
  "19": 4,
  "20": 2.6
 },
 {
  "0": 7,
  "1": 4.3,
  "2": 2.9,
  "3": 4.5,
  "4": 3,
  "5": 4.6,
  "6": 3.1,
  "7": 4.7,
  "8": 3.1,
  "9": 4.7,
  "10": 3.1,
  "11": 4.6,
  "12": 2.8,
  "13": 4.4,
  "14": 2.6,
  "15": 4.3,
  "16": 2.6,
  "17": 4.3,
  "18": 2.6,
  "19": 4,
  "20": 2.6
 },
 {
  "0": 8,
  "1": 4.4,
  "2": 3,
  "3": 4.6,
  "4": 3.1,
  "5": 4.7,
  "6": 3.2,
  "7": 4.8,
  "8": 3.2,
  "9": 4.8,
  "10": 3.2,
  "11": 4.7,
  "12": 2.9,
  "13": 4.5,
  "14": 2.7,
  "15": 4.4,
  "16": 2.7,
  "17": 4.4,
  "18": 2.7,
  "19": 4.1,
  "20": 2.7
 },
 {
  "0": 9,
  "1": 4.4,
  "2": 3,
  "3": 4.6,
  "4": 3.1,
  "5": 4.7,
  "6": 3.2,
  "7": 4.8,
  "8": 3.2,
  "9": 4.8,
  "10": 3.2,
  "11": 4.7,
  "12": 2.9,
  "13": 4.5,
  "14": 2.7,
  "15": 4.4,
  "16": 2.7,
  "17": 4.4,
  "18": 2.7,
  "19": 4.1,
  "20": 2.7
 },
 {
  "0": 10,
  "1": 4.5,
  "2": 3,
  "3": 4.7,
  "4": 3.1,
  "5": 4.8,
  "6": 3.2,
  "7": 4.9,
  "8": 3.2,
  "9": 4.9,
  "10": 3.2,
  "11": 4.7,
  "12": 2.9,
  "13": 4.5,
  "14": 2.7,
  "15": 4.4,
  "16": 2.7,
  "17": 4.4,
  "18": 2.7,
  "19": 4.1,
  "20": 2.7
 },
 {
  "0": 11,
  "1": 4.5,
  "2": 3.1,
  "3": 4.7,
  "4": 3.2,
  "5": 4.8,
  "6": 3.3,
  "7": 4.9,
  "8": 3.3,
  "9": 4.9,
  "10": 3.3,
  "11": 4.8,
  "12": 3,
  "13": 4.6,
  "14": 2.8,
  "15": 4.5,
  "16": 2.7,
  "17": 4.5,
  "18": 2.8,
  "19": 4.1,
  "20": 2.8
 },
 {
  "0": 12,
  "1": 4.6,
  "2": 3.1,
  "3": 4.8,
  "4": 3.2,
  "5": 4.9,
  "6": 3.3,
  "7": 5,
  "8": 3.3,
  "9": 5,
  "10": 3.3,
  "11": 4.8,
  "12": 3,
  "13": 4.6,
  "14": 2.8,
  "15": 4.5,
  "16": 2.8,
  "17": 4.5,
  "18": 2.8,
  "19": 4.2,
  "20": 2.8
 },
 {
  "0": 13,
  "1": 4.6,
  "2": 3.1,
  "3": 4.8,
  "4": 3.2,
  "5": 4.9,
  "6": 3.3,
  "7": 5,
  "8": 3.3,
  "9": 5,
  "10": 3.3,
  "11": 4.8,
  "12": 3,
  "13": 4.7,
  "14": 2.8,
  "15": 4.6,
  "16": 2.8,
  "17": 4.6,
  "18": 2.8,
  "19": 4.2,
  "20": 2.8
 },
 {
  "0": 14,
  "1": 4.7,
  "2": 3.2,
  "3": 4.9,
  "4": 3.3,
  "5": 5,
  "6": 3.4,
  "7": 5.1,
  "8": 3.4,
  "9": 5.1,
  "10": 3.4,
  "11": 4.9,
  "12": 3.1,
  "13": 4.7,
  "14": 2.9,
  "15": 4.6,
  "16": 2.8,
  "17": 4.6,
  "18": 2.9,
  "19": 4.2,
  "20": 2.9
 },
 {
  "0": 15,
  "1": 4.7,
  "2": 3.2,
  "3": 4.9,
  "4": 3.3,
  "5": 5.1,
  "6": 3.4,
  "7": 5.1,
  "8": 3.4,
  "9": 5.1,
  "10": 3.4,
  "11": 4.9,
  "12": 3.1,
  "13": 4.7,
  "14": 2.9,
  "15": 4.6,
  "16": 2.8,
  "17": 4.6,
  "18": 2.9,
  "19": 4.2,
  "20": 2.9
 },
 {
  "0": 16,
  "1": 4.8,
  "2": 3.2,
  "3": 5,
  "4": 3.3,
  "5": 5.1,
  "6": 3.4,
  "7": 5.2,
  "8": 3.4,
  "9": 5.2,
  "10": 3.4,
  "11": 4.9,
  "12": 3.1,
  "13": 4.8,
  "14": 2.9,
  "15": 4.7,
  "16": 2.8,
  "17": 4.7,
  "18": 2.9,
  "19": 4.2,
  "20": 2.9
 },
 {
  "0": 17,
  "1": 4.8,
  "2": 3.2,
  "3": 5,
  "4": 3.3,
  "5": 5.2,
  "6": 3.4,
  "7": 5.2,
  "8": 3.4,
  "9": 5.2,
  "10": 3.4,
  "11": 4.9,
  "12": 3.1,
  "13": 4.8,
  "14": 2.9,
  "15": 4.7,
  "16": 2.9,
  "17": 4.7,
  "18": 2.9,
  "19": 4.3,
  "20": 2.9
 },
 {
  "0": 18,
  "1": 4.9,
  "2": 3.3,
  "3": 5.1,
  "4": 3.4,
  "5": 5.2,
  "6": 3.5,
  "7": 5.3,
  "8": 3.5,
  "9": 5.3,
  "10": 3.5,
  "11": 5,
  "12": 3.2,
  "13": 4.9,
  "14": 3,
  "15": 4.8,
  "16": 2.9,
  "17": 4.8,
  "18": 3,
  "19": 4.3,
  "20": 3
 },
 {
  "0": 19,
  "1": 4.9,
  "2": 3.3,
  "3": 5.1,
  "4": 3.4,
  "5": 5.3,
  "6": 3.5,
  "7": 5.3,
  "8": 3.5,
  "9": 5.3,
  "10": 3.5,
  "11": 5,
  "12": 3.2,
  "13": 4.9,
  "14": 3,
  "15": 4.8,
  "16": 2.9,
  "17": 4.8,
  "18": 3,
  "19": 4.3,
  "20": 3
 },
 {
  "0": 20,
  "1": 4.9,
  "2": 3.3,
  "3": 5.1,
  "4": 3.4,
  "5": 5.3,
  "6": 3.5,
  "7": 5.4,
  "8": 3.5,
  "9": 5.3,
  "10": 3.5,
  "11": 5,
  "12": 3.2,
  "13": 4.9,
  "14": 3,
  "15": 4.8,
  "16": 2.9,
  "17": 4.8,
  "18": 3,
  "19": 4.3,
  "20": 3
 },
 {
  "0": 21,
  "1": 5,
  "2": 3.3,
  "3": 5.2,
  "4": 3.4,
  "5": 5.4,
  "6": 3.5,
  "7": 5.4,
  "8": 3.5,
  "9": 5.4,
  "10": 3.5,
  "11": 5.1,
  "12": 3.2,
  "13": 5,
  "14": 3.1,
  "15": 4.8,
  "16": 2.9,
  "17": 4.8,
  "18": 3,
  "19": 4.3,
  "20": 3
 },
 {
  "0": 22,
  "1": 5,
  "2": 3.3,
  "3": 5.2,
  "4": 3.5,
  "5": 5.4,
  "6": 3.6,
  "7": 5.5,
  "8": 3.6,
  "9": 5.4,
  "10": 3.5,
  "11": 5.1,
  "12": 3.3,
  "13": 5,
  "14": 3.1,
  "15": 4.8,
  "16": 2.9,
  "17": 4.8,
  "18": 3,
  "19": 4.3,
  "20": 3
 },
 {
  "0": 23,
  "1": 5,
  "2": 3.3,
  "3": 5.3,
  "4": 3.5,
  "5": 5.5,
  "6": 3.6,
  "7": 5.5,
  "8": 3.6,
  "9": 5.5,
  "10": 3.5,
  "11": 5.2,
  "12": 3.3,
  "13": 5.1,
  "14": 3.1,
  "15": 4.8,
  "16": 2.9,
  "17": 4.8,
  "18": 3,
  "19": 4.3,
  "20": 3
 },
 {
  "0": 24,
  "1": 5.1,
  "2": 3.4,
  "3": 5.3,
  "4": 3.5,
  "5": 5.5,
  "6": 3.6,
  "7": 5.6,
  "8": 3.6,
  "9": 5.5,
  "10": 3.6,
  "11": 5.2,
  "12": 3.3,
  "13": 5.1,
  "14": 3.2,
  "15": 4.9,
  "16": 3,
  "17": 4.9,
  "18": 3.1,
  "19": 4.4,
  "20": 3.1
 },
 {
  "0": 25,
  "1": 5.1,
  "2": 3.4,
  "3": 5.3,
  "4": 3.5,
  "5": 5.5,
  "6": 3.6,
  "7": 5.6,
  "8": 3.6,
  "9": 5.5,
  "10": 3.6,
  "11": 5.2,
  "12": 3.3,
  "13": 5.1,
  "14": 3.2,
  "15": 4.9,
  "16": 3,
  "17": 4.9,
  "18": 3.1,
  "19": 4.4,
  "20": 3.1
 },
 {
  "0": 26,
  "1": 5.1,
  "2": 3.4,
  "3": 5.4,
  "4": 3.5,
  "5": 5.6,
  "6": 3.6,
  "7": 5.7,
  "8": 3.6,
  "9": 5.6,
  "10": 3.6,
  "11": 5.3,
  "12": 3.3,
  "13": 5.2,
  "14": 3.2,
  "15": 4.9,
  "16": 3,
  "17": 4.9,
  "18": 3.1,
  "19": 4.4,
  "20": 3.1
 },
 {
  "0": 27,
  "1": 5.1,
  "2": 3.4,
  "3": 5.4,
  "4": 3.6,
  "5": 5.6,
  "6": 3.7,
  "7": 5.7,
  "8": 3.7,
  "9": 5.6,
  "10": 3.6,
  "11": 5.3,
  "12": 3.4,
  "13": 5.2,
  "14": 3.2,
  "15": 4.9,
  "16": 3,
  "17": 4.9,
  "18": 3.1,
  "19": 4.4,
  "20": 3.1
 },
 {
  "0": 28,
  "1": 5.2,
  "2": 3.4,
  "3": 5.5,
  "4": 3.6,
  "5": 5.7,
  "6": 3.7,
  "7": 5.8,
  "8": 3.7,
  "9": 5.7,
  "10": 3.6,
  "11": 5.4,
  "12": 3.4,
  "13": 5.3,
  "14": 3.3,
  "15": 4.9,
  "16": 3,
  "17": 4.9,
  "18": 3.1,
  "19": 4.4,
  "20": 3.1
 },
 {
  "0": 29,
  "1": 5.2,
  "2": 3.4,
  "3": 5.5,
  "4": 3.6,
  "5": 5.7,
  "6": 3.7,
  "7": 5.8,
  "8": 3.7,
  "9": 5.7,
  "10": 3.6,
  "11": 5.4,
  "12": 3.4,
  "13": 5.3,
  "14": 3.3,
  "15": 4.9,
  "16": 3,
  "17": 4.9,
  "18": 3.1,
  "19": 4.4,
  "20": 3.1
 },
 {
  "0": 30,
  "1": 5.2,
  "2": 3.4,
  "3": 5.5,
  "4": 3.6,
  "5": 5.7,
  "6": 3.7,
  "7": 5.8,
  "8": 3.7,
  "9": 5.7,
  "10": 3.6,
  "11": 5.4,
  "12": 3.4,
  "13": 5.3,
  "14": 3.3,
  "15": 4.9,
  "16": 3,
  "17": 4.9,
  "18": 3.1,
  "19": 4.4,
  "20": 3.1
 },
 {
  "0": 31,
  "1": 5.3,
  "2": 3.4,
  "3": 5.6,
  "4": 3.6,
  "5": 5.7,
  "6": 3.7,
  "7": 5.8,
  "8": 3.7,
  "9": 5.7,
  "10": 3.6,
  "11": 5.5,
  "12": 3.4,
  "13": 5.3,
  "14": 3.3,
  "15": 5,
  "16": 3,
  "17": 4.9,
  "18": 3.1,
  "19": 4.4,
  "20": 3.1
 },
 {
  "0": 32,
  "1": 5.3,
  "2": 3.5,
  "3": 5.6,
  "4": 3.6,
  "5": 5.8,
  "6": 3.7,
  "7": 5.9,
  "8": 3.7,
  "9": 5.8,
  "10": 3.7,
  "11": 5.5,
  "12": 3.4,
  "13": 5.4,
  "14": 3.3,
  "15": 5,
  "16": 3.1,
  "17": 4.9,
  "18": 3.1,
  "19": 4.5,
  "20": 3.1
 },
 {
  "0": 33,
  "1": 5.3,
  "2": 3.5,
  "3": 5.7,
  "4": 3.6,
  "5": 5.8,
  "6": 3.7,
  "7": 5.9,
  "8": 3.7,
  "9": 5.8,
  "10": 3.7,
  "11": 5.6,
  "12": 3.4,
  "13": 5.4,
  "14": 3.3,
  "15": 5,
  "16": 3.1,
  "17": 4.9,
  "18": 3.1,
  "19": 4.5,
  "20": 3.1
 },
 {
  "0": 34,
  "1": 5.4,
  "2": 3.5,
  "3": 5.7,
  "4": 3.6,
  "5": 5.8,
  "6": 3.8,
  "7": 5.9,
  "8": 3.8,
  "9": 5.8,
  "10": 3.7,
  "11": 5.6,
  "12": 3.5,
  "13": 5.4,
  "14": 3.4,
  "15": 5.1,
  "16": 3.1,
  "17": 4.9,
  "18": 3.2,
  "19": 4.5,
  "20": 3.2
 },
 {
  "0": 35,
  "1": 5.4,
  "2": 3.5,
  "3": 5.7,
  "4": 3.8,
  "5": 5.8,
  "6": 3.8,
  "7": 5.9,
  "8": 3.8,
  "9": 5.8,
  "10": 3.7,
  "11": 5.6,
  "12": 3.5,
  "13": 5.4,
  "14": 3.4,
  "15": 5.1,
  "16": 3.1,
  "17": 4.9,
  "18": 3.2,
  "19": 4.5,
  "20": 3.2
 },
 {
  "0": 36,
  "1": 5.4,
  "2": 3.5,
  "3": 5.8,
  "4": 3.8,
  "5": 5.8,
  "6": 3.8,
  "7": 5.9,
  "8": 3.8,
  "9": 5.8,
  "10": 3.7,
  "11": 5.7,
  "12": 3.5,
  "13": 5.4,
  "14": 3.4,
  "15": 5.1,
  "16": 3.1,
  "17": 4.9,
  "18": 3.2,
  "19": 4.5,
  "20": 3.2
 },
 {
  "0": 37,
  "1": 5.4,
  "2": 3.6,
  "3": 5.8,
  "4": 3.8,
  "5": 5.9,
  "6": 3.8,
  "7": 6,
  "8": 3.8,
  "9": 5.9,
  "10": 3.8,
  "11": 5.7,
  "12": 3.5,
  "13": 5.5,
  "14": 3.4,
  "15": 5.1,
  "16": 3.2,
  "17": 4.9,
  "18": 3.2,
  "19": 4.6,
  "20": 3.2
 },
 {
  "0": 38,
  "1": 5.5,
  "2": 3.6,
  "3": 5.9,
  "4": 3.8,
  "5": 5.9,
  "6": 3.8,
  "7": 6,
  "8": 3.8,
  "9": 5.9,
  "10": 3.8,
  "11": 5.8,
  "12": 3.5,
  "13": 5.5,
  "14": 3.4,
  "15": 5.2,
  "16": 3.2,
  "17": 4.9,
  "18": 3.2,
  "19": 4.6,
  "20": 3.2
 },
 {
  "0": 39,
  "1": 5.5,
  "2": 3.6,
  "3": 5.9,
  "4": 3.8,
  "5": 5.9,
  "6": 3.8,
  "7": 6,
  "8": 3.8,
  "9": 5.9,
  "10": 3.8,
  "11": 5.8,
  "12": 3.5,
  "13": 5.5,
  "14": 3.4,
  "15": 5.2,
  "16": 3.2,
  "17": 4.9,
  "18": 3.2,
  "19": 4.6,
  "20": 3.2
 },
 {
  "0": 40,
  "1": 5.5,
  "2": 3.6,
  "3": 5.9,
  "4": 3.8,
  "5": 5.9,
  "6": 3.8,
  "7": 6,
  "8": 3.8,
  "9": 5.9,
  "10": 3.8,
  "11": 5.8,
  "12": 3.5,
  "13": 5.5,
  "14": 3.4,
  "15": 5.2,
  "16": 3.2,
  "17": 4.9,
  "18": 3.2,
  "19": 4.6,
  "20": 3.2
 },
 {
  "0": 41,
  "1": 5.5,
  "2": 3.6,
  "3": 5.9,
  "4": 3.8,
  "5": 6,
  "6": 3.8,
  "7": 6,
  "8": 3.8,
  "9": 5.9,
  "10": 3.8,
  "11": 5.8,
  "12": 3.5,
  "13": 5.5,
  "14": 3.4,
  "15": 5.2,
  "16": 3.2,
  "17": 4.9,
  "18": 3.2,
  "19": 4.6,
  "20": 3.2
 },
 {
  "0": 42,
  "1": 5.6,
  "2": 3.6,
  "3": 5.9,
  "4": 3.8,
  "5": 6,
  "6": 3.8,
  "7": 6.1,
  "8": 3.8,
  "9": 6,
  "10": 3.8,
  "11": 5.8,
  "12": 3.6,
  "13": 5.6,
  "14": 3.4,
  "15": 5.3,
  "16": 3.2,
  "17": 5,
  "18": 3.2,
  "19": 4.6,
  "20": 3.2
 },
 {
  "0": 43,
  "1": 5.6,
  "2": 3.6,
  "3": 5.9,
  "4": 3.8,
  "5": 6,
  "6": 3.8,
  "7": 6.1,
  "8": 3.8,
  "9": 6,
  "10": 3.8,
  "11": 5.8,
  "12": 3.6,
  "13": 5.6,
  "14": 3.4,
  "15": 5.3,
  "16": 3.2,
  "17": 5,
  "18": 3.2,
  "19": 4.6,
  "20": 3.2
 },
 {
  "0": 44,
  "1": 5.6,
  "2": 3.7,
  "3": 6,
  "4": 3.8,
  "5": 6.1,
  "6": 3.9,
  "7": 6.1,
  "8": 3.9,
  "9": 6,
  "10": 3.9,
  "11": 5.9,
  "12": 3.6,
  "13": 5.6,
  "14": 3.5,
  "15": 5.3,
  "16": 3.3,
  "17": 5,
  "18": 3.3,
  "19": 4.7,
  "20": 3.3
 },
 {
  "0": 45,
  "1": 5.6,
  "2": 3.7,
  "3": 6,
  "4": 3.8,
  "5": 6.1,
  "6": 3.9,
  "7": 6.1,
  "8": 3.9,
  "9": 6,
  "10": 3.9,
  "11": 5.9,
  "12": 3.6,
  "13": 5.6,
  "14": 3.5,
  "15": 5.3,
  "16": 3.3,
  "17": 5,
  "18": 3.3,
  "19": 4.7,
  "20": 3.3
 },
 {
  "0": 46,
  "1": 5.6,
  "2": 3.7,
  "3": 6,
  "4": 3.8,
  "5": 6.1,
  "6": 3.9,
  "7": 6.1,
  "8": 3.9,
  "9": 6,
  "10": 3.9,
  "11": 5.9,
  "12": 3.6,
  "13": 5.6,
  "14": 3.5,
  "15": 5.3,
  "16": 3.3,
  "17": 5,
  "18": 3.3,
  "19": 4.7,
  "20": 3.3
 },
 {
  "0": 47,
  "1": 5.7,
  "2": 3.7,
  "3": 6,
  "4": 3.8,
  "5": 6.1,
  "6": 3.9,
  "7": 6.2,
  "8": 3.9,
  "9": 6.1,
  "10": 3.9,
  "11": 5.9,
  "12": 3.7,
  "13": 5.7,
  "14": 3.5,
  "15": 5.4,
  "16": 3.3,
  "17": 5.1,
  "18": 3.3,
  "19": 4.7,
  "20": 3.3
 },
 {
  "0": 48,
  "1": 5.7,
  "2": 3.7,
  "3": 6,
  "4": 3.8,
  "5": 6.2,
  "6": 3.9,
  "7": 6.2,
  "8": 3.9,
  "9": 6.1,
  "10": 3.9,
  "11": 5.9,
  "12": 3.7,
  "13": 5.7,
  "14": 3.5,
  "15": 5.4,
  "16": 3.3,
  "17": 5.1,
  "18": 3.3,
  "19": 4.7,
  "20": 3.3
 },
 {
  "0": 49,
  "1": 5.7,
  "2": 3.7,
  "3": 6,
  "4": 3.8,
  "5": 6.2,
  "6": 3.9,
  "7": 6.2,
  "8": 3.9,
  "9": 6.1,
  "10": 3.9,
  "11": 5.9,
  "12": 3.7,
  "13": 5.7,
  "14": 3.5,
  "15": 5.4,
  "16": 3.3,
  "17": 5.1,
  "18": 3.3,
  "19": 4.7,
  "20": 3.3
 },
 {
  "0": 50,
  "1": 5.7,
  "2": 3.7,
  "3": 6,
  "4": 3.8,
  "5": 6.2,
  "6": 3.9,
  "7": 6.2,
  "8": 3.9,
  "9": 6.1,
  "10": 3.9,
  "11": 5.9,
  "12": 3.7,
  "13": 5.7,
  "14": 3.5,
  "15": 5.4,
  "16": 3.3,
  "17": 5.1,
  "18": 3.3,
  "19": 4.7,
  "20": 3.3
 },
 {
  "0": 51,
  "1": 5.7,
  "2": 3.7,
  "3": 6,
  "4": 3.8,
  "5": 6.2,
  "6": 3.9,
  "7": 6.2,
  "8": 3.9,
  "9": 6.1,
  "10": 3.9,
  "11": 5.9,
  "12": 3.7,
  "13": 5.7,
  "14": 3.5,
  "15": 5.4,
  "16": 3.3,
  "17": 5.1,
  "18": 3.3,
  "19": 4.7,
  "20": 3.3
 },
 {
  "0": 52,
  "1": 5.8,
  "2": 3.7,
  "3": 6.1,
  "4": 3.8,
  "5": 6.3,
  "6": 4,
  "7": 6.3,
  "8": 3.9,
  "9": 6.2,
  "10": 3.9,
  "11": 6,
  "12": 3.7,
  "13": 5.8,
  "14": 3.5,
  "15": 5.5,
  "16": 3.3,
  "17": 5.1,
  "18": 3.3,
  "19": 4.7,
  "20": 3.3
 },
 {
  "0": 53,
  "1": 5.8,
  "2": 3.7,
  "3": 6.1,
  "4": 3.8,
  "5": 6.3,
  "6": 4,
  "7": 6.3,
  "8": 3.9,
  "9": 6.2,
  "10": 3.9,
  "11": 6,
  "12": 3.7,
  "13": 5.8,
  "14": 3.5,
  "15": 5.5,
  "16": 3.3,
  "17": 5.1,
  "18": 3.3,
  "19": 4.7,
  "20": 3.3
 },
 {
  "0": 54,
  "1": 5.8,
  "2": 3.8,
  "3": 6.1,
  "4": 3.9,
  "5": 6.3,
  "6": 4,
  "7": 6.3,
  "8": 4,
  "9": 6.2,
  "10": 4,
  "11": 6,
  "12": 3.8,
  "13": 5.8,
  "14": 3.6,
  "15": 5.5,
  "16": 3.4,
  "17": 5.2,
  "18": 3.3,
  "19": 4.8,
  "20": 3.3
 },
 {
  "0": 55,
  "1": 5.8,
  "2": 3.8,
  "3": 6.1,
  "4": 3.9,
  "5": 6.3,
  "6": 4,
  "7": 6.3,
  "8": 4,
  "9": 6.2,
  "10": 4,
  "11": 6,
  "12": 3.8,
  "13": 5.8,
  "14": 3.6,
  "15": 5.5,
  "16": 3.4,
  "17": 5.2,
  "18": 3.3,
  "19": 4.8,
  "20": 3.3
 },
 {
  "0": 56,
  "1": 5.8,
  "2": 3.8,
  "3": 6.1,
  "4": 3.9,
  "5": 6.3,
  "6": 4,
  "7": 6.3,
  "8": 4,
  "9": 6.2,
  "10": 4,
  "11": 6,
  "12": 3.8,
  "13": 5.8,
  "14": 3.6,
  "15": 5.5,
  "16": 3.4,
  "17": 5.2,
  "18": 3.3,
  "19": 4.8,
  "20": 3.3
 },
 {
  "0": 57,
  "1": 5.9,
  "2": 3.8,
  "3": 6.2,
  "4": 3.9,
  "5": 6.4,
  "6": 4.1,
  "7": 6.4,
  "8": 4,
  "9": 6.3,
  "10": 4,
  "11": 6.1,
  "12": 3.8,
  "13": 5.9,
  "14": 3.6,
  "15": 5.6,
  "16": 3.4,
  "17": 5.2,
  "18": 3.3,
  "19": 4.8,
  "20": 3.3
 },
 {
  "0": 58,
  "1": 5.9,
  "2": 3.8,
  "3": 6.2,
  "4": 3.9,
  "5": 6.4,
  "6": 4.1,
  "7": 6.4,
  "8": 4,
  "9": 6.3,
  "10": 4,
  "11": 6.1,
  "12": 3.8,
  "13": 5.9,
  "14": 3.6,
  "15": 5.6,
  "16": 3.4,
  "17": 5.2,
  "18": 3.3,
  "19": 4.8,
  "20": 3.3
 },
 {
  "0": 59,
  "1": 5.9,
  "2": 3.8,
  "3": 6.2,
  "4": 3.9,
  "5": 6.4,
  "6": 4.1,
  "7": 6.4,
  "8": 4,
  "9": 6.3,
  "10": 4,
  "11": 6.1,
  "12": 3.8,
  "13": 5.9,
  "14": 3.6,
  "15": 5.6,
  "16": 3.4,
  "17": 5.2,
  "18": 3.3,
  "19": 4.8,
  "20": 3.3
 },
 {
  "0": 60,
  "1": 6,
  "2": 3.9,
  "3": 6.3,
  "4": 4,
  "5": 6.5,
  "6": 4.2,
  "7": 6.5,
  "8": 4.1,
  "9": 6.4,
  "10": 4.1,
  "11": 6.2,
  "12": 3.9,
  "13": 6,
  "14": 3.7,
  "15": 5.7,
  "16": 3.5,
  "17": 5.3,
  "18": 3.4,
  "19": 4.9,
  "20": 3.4
 },
 {
  "0": 61,
  "1": 6.6,
  "2": 4.4,
  "3": 6.9,
  "4": 4.4,
  "5": 7.1,
  "6": 4.6,
  "7": 7.1,
  "8": 4.5,
  "9": 7,
  "10": 4.4,
  "11": 6.7,
  "12": 4.2,
  "13": 6.4,
  "14": 4.1,
  "15": 6,
  "16": 3.9,
  "17": 5.7,
  "18": 3.6,
  "19": 5.1,
  "20": 3.6
 },
 {
  "0": 62,
  "1": 6.9,
  "2": 4.6,
  "3": 7.3,
  "4": 4.6,
  "5": 7.5,
  "6": 4.7,
  "7": 7.4,
  "8": 4.7,
  "9": 7.3,
  "10": 4.5,
  "11": 7.1,
  "12": 4.5,
  "13": 6.7,
  "14": 4.2,
  "15": 6.2,
  "16": 4.1,
  "17": 6,
  "18": 3.8,
  "19": 5.4,
  "20": 3.9
 },
 {
  "0": 63,
  "1": 7.2,
  "2": 4.7,
  "3": 7.5,
  "4": 4.7,
  "5": 7.7,
  "6": 4.8,
  "7": 7.6,
  "8": 4.8,
  "9": 7.5,
  "10": 4.6,
  "11": 7.3,
  "12": 4.6,
  "13": 6.9,
  "14": 4.4,
  "15": 6.4,
  "16": 4.1,
  "17": 6.1,
  "18": 3.9,
  "19": 5.7,
  "20": 4
 },
 {
  "0": 64,
  "1": 7.4,
  "2": 4.7,
  "3": 7.7,
  "4": 4.8,
  "5": 7.9,
  "6": 4.9,
  "7": 7.9,
  "8": 4.9,
  "9": 7.7,
  "10": 4.7,
  "11": 7.4,
  "12": 4.6,
  "13": 7.1,
  "14": 4.5,
  "15": 6.6,
  "16": 4.2,
  "17": 6.2,
  "18": 4,
  "19": 5.9,
  "20": 4
 },
 {
  "0": 65,
  "1": 7.5,
  "2": 4.8,
  "3": 7.8,
  "4": 4.9,
  "5": 8.1,
  "6": 5,
  "7": 8.1,
  "8": 5,
  "9": 7.8,
  "10": 4.8,
  "11": 7.6,
  "12": 4.7,
  "13": 7.2,
  "14": 4.5,
  "15": 6.7,
  "16": 4.3,
  "17": 6.3,
  "18": 4.1,
  "19": 5.9,
  "20": 4.1
 },
 {
  "0": 66,
  "1": 7.7,
  "2": 4.9,
  "3": 8.1,
  "4": 5,
  "5": 8.3,
  "6": 5.1,
  "7": 8.2,
  "8": 5.1,
  "9": 8.1,
  "10": 4.8,
  "11": 7.8,
  "12": 4.8,
  "13": 7.4,
  "14": 4.5,
  "15": 6.9,
  "16": 4.3,
  "17": 6.5,
  "18": 4.2,
  "19": 6.1,
  "20": 4.2
 },
 {
  "0": 67,
  "1": 7.9,
  "2": 4.9,
  "3": 8.2,
  "4": 5.1,
  "5": 8.4,
  "6": 5.1,
  "7": 8.3,
  "8": 5.1,
  "9": 8.2,
  "10": 4.9,
  "11": 7.9,
  "12": 4.9,
  "13": 7.5,
  "14": 4.6,
  "15": 6.9,
  "16": 4.4,
  "17": 6.6,
  "18": 4.3,
  "19": 6.1,
  "20": 4.3
 },
 {
  "0": 68,
  "1": 8,
  "2": 5,
  "3": 8.3,
  "4": 5.1,
  "5": 8.5,
  "6": 5.2,
  "7": 8.5,
  "8": 5.2,
  "9": 8.3,
  "10": 5,
  "11": 8,
  "12": 5,
  "13": 7.6,
  "14": 4.7,
  "15": 7,
  "16": 4.5,
  "17": 6.7,
  "18": 4.4,
  "19": 6.2,
  "20": 4.4
 },
 {
  "0": 69,
  "1": 8.1,
  "2": 5.1,
  "3": 8.5,
  "4": 5.2,
  "5": 8.6,
  "6": 5.2,
  "7": 8.6,
  "8": 5.2,
  "9": 8.5,
  "10": 5.1,
  "11": 8.1,
  "12": 5.1,
  "13": 7.7,
  "14": 4.8,
  "15": 7.1,
  "16": 4.5,
  "17": 6.8,
  "18": 4.5,
  "19": 6.4,
  "20": 4.5
 },
 {
  "0": 70,
  "1": 8.2,
  "2": 5.1,
  "3": 8.6,
  "4": 5.2,
  "5": 8.8,
  "6": 5.3,
  "7": 8.7,
  "8": 5.3,
  "9": 8.6,
  "10": 5.1,
  "11": 8.2,
  "12": 5.1,
  "13": 7.9,
  "14": 4.8,
  "15": 7.3,
  "16": 4.6,
  "17": 6.9,
  "18": 4.5,
  "19": 6.6,
  "20": 4.5
 },
 {
  "0": 71,
  "1": 8.3,
  "2": 5.2,
  "3": 8.8,
  "4": 5.3,
  "5": 8.9,
  "6": 5.4,
  "7": 8.8,
  "8": 5.4,
  "9": 8.7,
  "10": 5.1,
  "11": 8.3,
  "12": 5.1,
  "13": 8,
  "14": 4.9,
  "15": 7.4,
  "16": 4.6,
  "17": 6.9,
  "18": 4.6,
  "19": 6.7,
  "20": 4.6
 },
 {
  "0": 72,
  "1": 8.4,
  "2": 5.3,
  "3": 8.9,
  "4": 5.4,
  "5": 9,
  "6": 5.5,
  "7": 8.9,
  "8": 5.4,
  "9": 8.8,
  "10": 5.2,
  "11": 8.4,
  "12": 5.2,
  "13": 8.1,
  "14": 4.9,
  "15": 7.5,
  "16": 4.7,
  "17": 7,
  "18": 4.8,
  "19": 6.8,
  "20": 4.7
 },
 {
  "0": 73,
  "1": 8.5,
  "2": 5.3,
  "3": 9,
  "4": 5.4,
  "5": 9.2,
  "6": 5.5,
  "7": 9.1,
  "8": 5.5,
  "9": 8.9,
  "10": 5.3,
  "11": 8.6,
  "12": 5.2,
  "13": 8.1,
  "14": 5,
  "15": 7.6,
  "16": 4.8,
  "17": 7.1,
  "18": 4.8,
  "19": 7,
  "20": 4.8
 },
 {
  "0": 74,
  "1": 8.6,
  "2": 5.4,
  "3": 9.1,
  "4": 5.5,
  "5": 9.2,
  "6": 5.6,
  "7": 9.2,
  "8": 5.6,
  "9": 9,
  "10": 5.3,
  "11": 8.6,
  "12": 5.3,
  "13": 8.2,
  "14": 5,
  "15": 7.7,
  "16": 4.8,
  "17": 7.2,
  "18": 4.8,
  "19": 7.1,
  "20": 4.8
 },
 {
  "0": 75,
  "1": 8.8,
  "2": 5.5,
  "3": 9.2,
  "4": 5.6,
  "5": 9.3,
  "6": 5.6,
  "7": 9.3,
  "8": 5.6,
  "9": 9.1,
  "10": 5.4,
  "11": 8.7,
  "12": 5.3,
  "13": 8.3,
  "14": 5.1,
  "15": 7.7,
  "16": 4.9,
  "17": 7.3,
  "18": 4.9,
  "19": 7.1,
  "20": 4.9
 },
 {
  "0": 76,
  "1": 8.9,
  "2": 5.6,
  "3": 9.3,
  "4": 5.7,
  "5": 9.4,
  "6": 5.7,
  "7": 9.4,
  "8": 5.7,
  "9": 9.2,
  "10": 5.5,
  "11": 8.8,
  "12": 5.4,
  "13": 8.4,
  "14": 5.1,
  "15": 7.8,
  "16": 4.9,
  "17": 7.4,
  "18": 4.9,
  "19": 7.2,
  "20": 4.9
 },
 {
  "0": 77,
  "1": 9,
  "2": 5.6,
  "3": 9.4,
  "4": 5.7,
  "5": 9.6,
  "6": 5.8,
  "7": 9.5,
  "8": 5.7,
  "9": 9.3,
  "10": 5.5,
  "11": 8.9,
  "12": 5.5,
  "13": 8.5,
  "14": 5.1,
  "15": 7.9,
  "16": 5,
  "17": 7.4,
  "18": 5,
  "19": 7.3,
  "20": 5
 },
 {
  "0": 78,
  "1": 9.1,
  "2": 5.7,
  "3": 9.5,
  "4": 5.7,
  "5": 9.7,
  "6": 5.8,
  "7": 9.6,
  "8": 5.8,
  "9": 9.4,
  "10": 5.6,
  "11": 9,
  "12": 5.5,
  "13": 8.6,
  "14": 5.2,
  "15": 8,
  "16": 5,
  "17": 7.5,
  "18": 5,
  "19": 7.3,
  "20": 5
 },
 {
  "0": 79,
  "1": 9.2,
  "2": 5.7,
  "3": 9.6,
  "4": 5.8,
  "5": 9.7,
  "6": 5.9,
  "7": 9.7,
  "8": 5.8,
  "9": 9.5,
  "10": 5.6,
  "11": 9.1,
  "12": 5.6,
  "13": 8.6,
  "14": 5.2,
  "15": 8,
  "16": 5.1,
  "17": 7.6,
  "18": 5,
  "19": 7.4,
  "20": 5
 },
 {
  "0": 80,
  "1": 9.3,
  "2": 5.8,
  "3": 9.7,
  "4": 5.9,
  "5": 9.8,
  "6": 6.1,
  "7": 9.8,
  "8": 5.9,
  "9": 9.6,
  "10": 5.7,
  "11": 9.2,
  "12": 5.7,
  "13": 8.7,
  "14": 5.3,
  "15": 8.1,
  "16": 5.1,
  "17": 7.7,
  "18": 5.1,
  "19": 7.4,
  "20": 5.1
 },
 {
  "0": 81,
  "1": 9.4,
  "2": 5.9,
  "3": 9.8,
  "4": 6,
  "5": 10,
  "6": 6.2,
  "7": 9.9,
  "8": 6,
  "9": 9.7,
  "10": 5.7,
  "11": 9.3,
  "12": 5.7,
  "13": 8.8,
  "14": 5.4,
  "15": 8.2,
  "16": 5.1,
  "17": 7.8,
  "18": 5.1,
  "19": 7.5,
  "20": 5.1
 },
 {
  "0": 82,
  "1": 9.5,
  "2": 5.9,
  "3": 9.9,
  "4": 6,
  "5": 10.1,
  "6": 6.2,
  "7": 10,
  "8": 6.1,
  "9": 9.8,
  "10": 5.8,
  "11": 9.4,
  "12": 5.7,
  "13": 8.9,
  "14": 5.4,
  "15": 8.3,
  "16": 5.2,
  "17": 7.9,
  "18": 5.1,
  "19": 7.5,
  "20": 5.1
 },
 {
  "0": 83,
  "1": 9.6,
  "2": 6,
  "3": 10,
  "4": 6.1,
  "5": 10.2,
  "6": 6.3,
  "7": 10.1,
  "8": 6.2,
  "9": 9.9,
  "10": 5.9,
  "11": 9.5,
  "12": 5.8,
  "13": 9,
  "14": 5.5,
  "15": 8.4,
  "16": 5.3,
  "17": 8,
  "18": 5.1,
  "19": 7.6,
  "20": 5.2
 },
 {
  "0": 84,
  "1": 9.7,
  "2": 6,
  "3": 10.1,
  "4": 6.2,
  "5": 10.4,
  "6": 6.4,
  "7": 10.2,
  "8": 6.2,
  "9": 10.1,
  "10": 6,
  "11": 9.6,
  "12": 5.9,
  "13": 9.1,
  "14": 5.5,
  "15": 8.5,
  "16": 5.3,
  "17": 8,
  "18": 5.2,
  "19": 7.7,
  "20": 5.2
 },
 {
  "0": 85,
  "1": 9.8,
  "2": 6.1,
  "3": 10.2,
  "4": 6.3,
  "5": 10.5,
  "6": 6.4,
  "7": 10.3,
  "8": 6.3,
  "9": 10.2,
  "10": 6,
  "11": 9.7,
  "12": 5.9,
  "13": 9.2,
  "14": 5.6,
  "15": 8.5,
  "16": 5.4,
  "17": 8.1,
  "18": 5.2,
  "19": 7.7,
  "20": 5.2
 },
 {
  "0": 86,
  "1": 9.9,
  "2": 6.2,
  "3": 10.3,
  "4": 6.4,
  "5": 10.6,
  "6": 6.5,
  "7": 10.4,
  "8": 6.3,
  "9": 10.3,
  "10": 6.1,
  "11": 9.8,
  "12": 6,
  "13": 9.3,
  "14": 5.7,
  "15": 8.6,
  "16": 5.4,
  "17": 8.2,
  "18": 5.2,
  "19": 7.8,
  "20": 5.3
 },
 {
  "0": 87,
  "1": 10,
  "2": 6.3,
  "3": 10.4,
  "4": 6.5,
  "5": 10.7,
  "6": 6.6,
  "7": 10.5,
  "8": 6.4,
  "9": 10.4,
  "10": 6.2,
  "11": 9.9,
  "12": 6.1,
  "13": 9.4,
  "14": 5.8,
  "15": 8.7,
  "16": 5.5,
  "17": 8.3,
  "18": 5.3,
  "19": 7.8,
  "20": 5.3
 },
 {
  "0": 88,
  "1": 10.3,
  "2": 6.4,
  "3": 10.6,
  "4": 6.6,
  "5": 10.9,
  "6": 6.7,
  "7": 10.7,
  "8": 6.5,
  "9": 10.5,
  "10": 6.3,
  "11": 10.1,
  "12": 6.2,
  "13": 9.5,
  "14": 5.8,
  "15": 8.8,
  "16": 5.5,
  "17": 8.4,
  "18": 5.3,
  "19": 7.9,
  "20": 5.4
 },
 {
  "0": 89,
  "1": 10.4,
  "2": 6.4,
  "3": 10.7,
  "4": 6.7,
  "5": 11,
  "6": 6.8,
  "7": 10.8,
  "8": 6.7,
  "9": 10.6,
  "10": 6.3,
  "11": 10.3,
  "12": 6.3,
  "13": 9.6,
  "14": 5.9,
  "15": 8.9,
  "16": 5.6,
  "17": 8.4,
  "18": 5.4,
  "19": 7.9,
  "20": 5.4
 },
 {
  "0": 90,
  "1": 10.5,
  "2": 6.5,
  "3": 11,
  "4": 6.8,
  "5": 11.1,
  "6": 6.9,
  "7": 11,
  "8": 6.8,
  "9": 10.7,
  "10": 6.4,
  "11": 10.4,
  "12": 6.4,
  "13": 9.7,
  "14": 6,
  "15": 9,
  "16": 5.7,
  "17": 8.5,
  "18": 5.5,
  "19": 8,
  "20": 5.5
 },
 {
  "0": 91,
  "1": 10.6,
  "2": 6.6,
  "3": 11.1,
  "4": 6.9,
  "5": 11.3,
  "6": 7,
  "7": 11.2,
  "8": 6.9,
  "9": 10.9,
  "10": 6.6,
  "11": 10.5,
  "12": 6.5,
  "13": 9.9,
  "14": 6.1,
  "15": 9.1,
  "16": 5.8,
  "17": 8.7,
  "18": 5.6,
  "19": 8.1,
  "20": 5.6
 },
 {
  "0": 92,
  "1": 10.7,
  "2": 6.8,
  "3": 11.3,
  "4": 7,
  "5": 11.4,
  "6": 7.1,
  "7": 11.3,
  "8": 7,
  "9": 11.1,
  "10": 6.6,
  "11": 10.6,
  "12": 6.6,
  "13": 10,
  "14": 6.1,
  "15": 9.2,
  "16": 5.9,
  "17": 8.8,
  "18": 5.7,
  "19": 8.2,
  "20": 5.7
 },
 {
  "0": 93,
  "1": 10.9,
  "2": 6.9,
  "3": 11.4,
  "4": 7.1,
  "5": 11.6,
  "6": 7.2,
  "7": 11.4,
  "8": 7.1,
  "9": 11.2,
  "10": 6.7,
  "11": 10.7,
  "12": 6.7,
  "13": 10.1,
  "14": 6.1,
  "15": 9.3,
  "16": 6,
  "17": 8.9,
  "18": 5.8,
  "19": 8.3,
  "20": 5.8
 },
 {
  "0": 94,
  "1": 11,
  "2": 7,
  "3": 11.5,
  "4": 7.2,
  "5": 11.7,
  "6": 7.3,
  "7": 11.6,
  "8": 7.3,
  "9": 11.4,
  "10": 6.8,
  "11": 10.8,
  "12": 6.8,
  "13": 10.2,
  "14": 6.2,
  "15": 9.5,
  "16": 6.1,
  "17": 9,
  "18": 5.9,
  "19": 8.5,
  "20": 5.9
 },
 {
  "0": 95,
  "1": 11.3,
  "2": 7.2,
  "3": 11.8,
  "4": 7.4,
  "5": 12,
  "6": 7.5,
  "7": 11.8,
  "8": 7.4,
  "9": 11.6,
  "10": 7,
  "11": 11.1,
  "12": 6.9,
  "13": 10.4,
  "14": 6.4,
  "15": 9.6,
  "16": 6.2,
  "17": 9.1,
  "18": 6,
  "19": 8.6,
  "20": 6
 },
 {
  "0": 96,
  "1": 11.5,
  "2": 7.3,
  "3": 12,
  "4": 7.5,
  "5": 12.2,
  "6": 7.7,
  "7": 12,
  "8": 7.6,
  "9": 11.8,
  "10": 7.2,
  "11": 11.3,
  "12": 7.1,
  "13": 10.6,
  "14": 6.5,
  "15": 9.8,
  "16": 6.3,
  "17": 9.3,
  "18": 6.1,
  "19": 8.6,
  "20": 6.1
 },
 {
  "0": 97,
  "1": 11.7,
  "2": 7.5,
  "3": 12.2,
  "4": 7.7,
  "5": 12.4,
  "6": 8,
  "7": 12.2,
  "8": 7.8,
  "9": 12,
  "10": 7.3,
  "11": 11.4,
  "12": 7.2,
  "13": 10.7,
  "14": 6.8,
  "15": 10,
  "16": 6.5,
  "17": 9.4,
  "18": 6.2,
  "19": 8.7,
  "20": 6.2
 },
 {
  "0": 98,
  "1": 12,
  "2": 7.7,
  "3": 12.5,
  "4": 7.9,
  "5": 12.6,
  "6": 8.2,
  "7": 12.4,
  "8": 8,
  "9": 12.2,
  "10": 7.5,
  "11": 11.7,
  "12": 7.4,
  "13": 11,
  "14": 7.1,
  "15": 10.2,
  "16": 6.6,
  "17": 9.5,
  "18": 6.3,
  "19": 8.7,
  "20": 6.3
 },
 {
  "0": 99,
  "1": 12.4,
  "2": 8.2,
  "3": 12.9,
  "4": 8.4,
  "5": 12.9,
  "6": 8.5,
  "7": 12.6,
  "8": 8.4,
  "9": 12.6,
  "10": 8,
  "11": 12.1,
  "12": 7.9,
  "13": 11.4,
  "14": 7.6,
  "15": 10.4,
  "16": 7.1,
  "17": 9.7,
  "18": 6.4,
  "19": 8.8,
  "20": 6.4
 },
 {
  "0": 100,
  "1": 12.6,
  "2": 8.4,
  "3": 13,
  "4": 8.5,
  "5": 13.1,
  "6": 8.7,
  "7": 12.9,
  "8": 8.6,
  "9": 12.8,
  "10": 8.2,
  "11": 12.3,
  "12": 8.1,
  "13": 11.6,
  "14": 7.8,
  "15": 10.6,
  "16": 7.4,
  "17": 9.9,
  "18": 6.6,
  "19": 9,
  "20": 6.6
 },
 {
  "0": "Points",
  "1": "M",
  "2": "F",
  "3": "M",
  "4": "F",
  "5": "M",
  "6": "F",
  "7": "M",
  "8": "F",
  "9": "M",
  "10": "F",
  "11": "M",
  "12": "F",
  "13": "M",
  "14": "F",
  "15": "M",
  "16": "F",
  "17": "M",
  "18": "F",
  "19": "M",
  "20": "F"
 },
 {
  "1": "StandingPowerThrow(SPT)(Distance-metersandcentimeters)"
 },
 {
  "1": "17-21",
  "3": "22-26",
  "5": "27-31",
  "7": "32-36",
  "9": "37-41",
  "11": "42-46",
  "13": "47-51",
  "15": "52-56",
  "17": "57-61",
  "19": "Over62"
 }
],
"Table3":[
 {
  "0": 0,
  "1": 4,
  "2": 4,
  "3": 4,
  "4": 4,
  "5": 4,
  "6": 4,
  "7": 4,
  "8": 4,
  "9": 4,
  "10": 4,
  "11": 4,
  "12": 4,
  "13": 4,
  "14": 4,
  "15": 4,
  "16": 4,
  "17": 4,
  "18": 4,
  "19": 4,
  "20": 4
 },
 {
  "0": 10,
  "1": 5,
  "2": 5,
  "3": 5,
  "4": 5,
  "5": 5,
  "6": 5,
  "7": 5,
  "8": 5,
  "9": 5,
  "10": 5,
  "11": 5,
  "12": 5,
  "13": 5,
  "14": 5,
  "15": 5,
  "16": 5,
  "17": 5,
  "18": 5,
  "19": 5,
  "20": 5
 },
 {
  "0": 20,
  "1": 6,
  "2": 6,
  "3": 6,
  "4": 6,
  "5": 6,
  "6": 6,
  "7": 6,
  "8": 6,
  "9": 6,
  "10": 6,
  "11": 6,
  "12": 6,
  "13": 6,
  "14": 6,
  "15": 6,
  "16": 6,
  "17": 6,
  "18": 6,
  "19": 6,
  "20": 6
 },
 {
  "0": 30,
  "1": 7,
  "2": 7,
  "3": 7,
  "4": 7,
  "5": 7,
  "6": 7,
  "7": 7,
  "8": 7,
  "9": 7,
  "10": 7,
  "11": 7,
  "12": 7,
  "13": 7,
  "14": 7,
  "15": 7,
  "16": 7,
  "17": 7,
  "18": 7,
  "19": 7,
  "20": 7
 },
 {
  "0": 40,
  "1": 8,
  "2": 8,
  "3": 8,
  "4": 8,
  "5": 8,
  "6": 8,
  "7": 8,
  "8": 8,
  "9": 8,
  "10": 8,
  "11": 8,
  "12": 8,
  "13": 8,
  "14": 8,
  "15": 8,
  "16": 8,
  "17": 8,
  "18": 8,
  "19": 8,
  "20": 8
 },
 {
  "0": 50,
  "1": 9,
  "2": 9,
  "3": 9,
  "4": 9,
  "5": 9,
  "6": 9,
  "7": 9,
  "8": 9,
  "9": 9,
  "10": 9,
  "11": 9,
  "12": 9,
  "13": 9,
  "14": 9,
  "15": 9,
  "16": 9,
  "17": 9,
  "18": 9,
  "19": 9,
  "20": 9
 },
 {
  "0": 60,
  "1": "10",
  "2": "10",
  "3": "10",
  "4": "10",
  "5": "10",
  "6": "10",
  "7": "10",
  "8": "10",
  "9": "10",
  "10": "10",
  "11": "10",
  "12": "10",
  "13": "10",
  "14": "10",
  "15": "10",
  "16": "10",
  "17": "10",
  "18": "10",
  "19": "10",
  "20": "10"
 },
 {
  "0": 61,
  "1": 13,
  "2": "11",
  "3": 12,
  "4": "10",
  "5": 11,
  "6": "10",
  "7": 11,
  "8": "10",
  "9": 11,
  "10": "10",
  "11": "10",
  "12": "10",
  "13": "10",
  "14": "10",
  "15": "10",
  "16": "10",
  "17": "10",
  "18": "10",
  "19": "10",
  "20": "10"
 },
 {
  "0": 62,
  "1": 16,
  "2": "12",
  "3": 13,
  "4": "11",
  "5": 12,
  "6": "10",
  "7": 12,
  "8": "10",
  "9": 11,
  "10": "10",
  "11": 11,
  "12": "10",
  "13": "10",
  "14": "10",
  "15": "10",
  "16": "10",
  "17": "10",
  "18": "10",
  "19": "10",
  "20": "10"
 },
 {
  "0": 63,
  "1": 17,
  "2": "12",
  "3": 14,
  "4": "11",
  "5": 14,
  "6": "11",
  "7": 13,
  "8": "10",
  "9": 12,
  "10": "10",
  "11": 11,
  "12": "10",
  "13": 11,
  "14": "10",
  "15": "10",
  "16": "10",
  "17": "10",
  "18": "10",
  "19": "10",
  "20": "10"
 },
 {
  "0": 64,
  "1": 20,
  "2": "13",
  "3": 16,
  "4": "11",
  "5": 16,
  "6": "11",
  "7": 14,
  "8": "11",
  "9": 13,
  "10": "10",
  "11": 12,
  "12": "10",
  "13": 11,
  "14": "10",
  "15": 11,
  "16": "10",
  "17": "10",
  "18": "10",
  "19": "10",
  "20": "10"
 },
 {
  "0": 65,
  "1": 22,
  "2": "14",
  "3": 17,
  "4": "12",
  "5": 18,
  "6": "12",
  "7": 16,
  "8": "11",
  "9": 14,
  "10": "10",
  "11": 13,
  "12": "10",
  "13": 11,
  "14": "10",
  "15": 11,
  "16": "10",
  "17": 11,
  "18": "10",
  "19": "10",
  "20": "10"
 },
 {
  "0": 66,
  "1": 23,
  "2": "15",
  "3": 20,
  "4": "12",
  "5": 20,
  "6": "12",
  "7": 17,
  "8": "12",
  "9": 15,
  "10": "10",
  "11": 14,
  "12": "10",
  "13": 12,
  "14": "10",
  "15": 11,
  "16": "10",
  "17": 11,
  "18": "10",
  "19": "10",
  "20": "10"
 },
 {
  "0": 67,
  "1": 24,
  "2": "15",
  "3": 20,
  "4": "12",
  "5": 20,
  "6": "12",
  "7": 19,
  "8": "12",
  "9": 16,
  "10": "11",
  "11": 15,
  "12": "11",
  "13": 12,
  "14": "11",
  "15": 12,
  "16": "11",
  "17": 11,
  "18": "11",
  "19": "10",
  "20": "10"
 },
 {
  "0": 68,
  "1": 25,
  "2": "16",
  "3": 21,
  "4": "13",
  "5": 20,
  "6": "13",
  "7": 20,
  "8": "12",
  "9": 17,
  "10": "11",
  "11": 16,
  "12": "11",
  "13": 13,
  "14": "11",
  "15": 12,
  "16": "11",
  "17": 12,
  "18": "11",
  "19": 11,
  "20": "10"
 },
 {
  "0": 69,
  "1": 27,
  "2": "17",
  "3": 22,
  "4": "14",
  "5": 21,
  "6": "13",
  "7": 21,
  "8": "12",
  "9": 19,
  "10": "11",
  "11": 17,
  "12": "11",
  "13": 14,
  "14": "11",
  "15": 13,
  "16": "11",
  "17": 12,
  "18": "11",
  "19": 11,
  "20": "11"
 },
 {
  "0": 70,
  "1": 28,
  "2": "18",
  "3": 25,
  "4": "15",
  "5": 23,
  "6": "14",
  "7": 22,
  "8": "13",
  "9": 20,
  "10": "11",
  "11": 18,
  "12": "11",
  "13": 15,
  "14": "11",
  "15": 13,
  "16": "11",
  "17": 12,
  "18": "11",
  "19": 11,
  "20": "11"
 },
 {
  "0": 71,
  "1": 29,
  "2": "19",
  "3": 26,
  "4": "16",
  "5": 26,
  "6": "14",
  "7": 23,
  "8": "13",
  "9": 21,
  "10": "11",
  "11": 19,
  "12": "11",
  "13": 16,
  "14": "11",
  "15": 14,
  "16": "11",
  "17": 13,
  "18": "11",
  "19": 12,
  "20": "11"
 },
 {
  "0": 72,
  "1": 30,
  "2": "20",
  "3": 27,
  "4": "17",
  "5": 27,
  "6": "15",
  "7": 24,
  "8": "14",
  "9": 22,
  "10": "12",
  "11": 19,
  "12": "11",
  "13": 17,
  "14": "11",
  "15": 15,
  "16": "11",
  "17": 13,
  "18": "11",
  "19": 12,
  "20": "11"
 },
 {
  "0": 73,
  "1": 31,
  "2": "20",
  "3": 30,
  "4": "17",
  "5": 29,
  "6": "15",
  "7": 26,
  "8": "15",
  "9": 23,
  "10": "12",
  "11": 20,
  "12": "12",
  "13": 18,
  "14": "11",
  "15": 15,
  "16": "11",
  "17": 14,
  "18": "11",
  "19": 12,
  "20": "11"
 },
 {
  "0": 74,
  "1": 31,
  "2": "21",
  "3": 30,
  "4": "18",
  "5": 30,
  "6": "16",
  "7": 28,
  "8": "16",
  "9": 24,
  "10": "12",
  "11": 21,
  "12": "12",
  "13": 19,
  "14": "12",
  "15": 16,
  "16": "12",
  "17": 14,
  "18": "11",
  "19": 13,
  "20": "11"
 },
 {
  "0": 75,
  "1": 32,
  "2": "22",
  "3": 30,
  "4": "18",
  "5": 31,
  "6": "16",
  "7": 29,
  "8": "17",
  "9": 25,
  "10": "12",
  "11": 22,
  "12": "12",
  "13": 20,
  "14": "12",
  "15": 16,
  "16": "12",
  "17": 15,
  "18": "11",
  "19": 13,
  "20": "11"
 },
 {
  "0": 76,
  "1": 33,
  "2": "23",
  "3": 31,
  "4": "19",
  "5": 31,
  "6": "17",
  "7": 30,
  "8": "17",
  "9": 26,
  "10": "13",
  "11": 23,
  "12": "12",
  "13": 20,
  "14": "12",
  "15": 17,
  "16": "12",
  "17": 16,
  "18": "11",
  "19": 14,
  "20": "11"
 },
 {
  "0": 77,
  "1": 34,
  "2": "24",
  "3": 32,
  "4": "21",
  "5": 32,
  "6": "18",
  "7": 31,
  "8": "17",
  "9": 27,
  "10": "13",
  "11": 25,
  "12": "12",
  "13": 21,
  "14": "12",
  "15": 18,
  "16": "12",
  "17": 16,
  "18": "12",
  "19": 15,
  "20": "11"
 },
 {
  "0": 78,
  "1": 35,
  "2": "25",
  "3": 33,
  "4": "22",
  "5": 33,
  "6": "19",
  "7": 31,
  "8": "18",
  "9": 28,
  "10": "14",
  "11": 26,
  "12": "12",
  "13": 21,
  "14": "12",
  "15": 19,
  "16": "12",
  "17": 17,
  "18": "12",
  "19": 15,
  "20": "11"
 },
 {
  "0": 79,
  "1": 36,
  "2": "26",
  "3": 34,
  "4": "23",
  "5": 34,
  "6": "20",
  "7": 32,
  "8": "18",
  "9": 29,
  "10": "15",
  "11": 27,
  "12": "13",
  "13": 22,
  "14": "12",
  "15": 20,
  "16": "12",
  "17": 17,
  "18": "12",
  "19": 16,
  "20": "12"
 },
 {
  "0": 80,
  "1": 37,
  "2": 27,
  "3": 35,
  "4": "23",
  "5": 35,
  "6": "21",
  "7": 32,
  "8": "19",
  "9": 30,
  "10": "16",
  "11": 28,
  "12": "13",
  "13": 23,
  "14": "12",
  "15": 21,
  "16": "12",
  "17": 18,
  "18": "12",
  "19": 17,
  "20": "12"
 },
 {
  "0": 81,
  "1": 37,
  "2": 27,
  "3": 35,
  "4": "23",
  "5": 36,
  "6": "21",
  "7": 33,
  "8": "22",
  "9": 31,
  "10": "16",
  "11": 29,
  "12": "14",
  "13": 25,
  "14": "13",
  "15": 22,
  "16": "12",
  "17": 19,
  "18": "12",
  "19": 18,
  "20": "12"
 },
 {
  "0": 82,
  "1": 38,
  "2": "28",
  "3": 37,
  "4": "24",
  "5": 37,
  "6": "22",
  "7": 34,
  "8": "22",
  "9": 31,
  "10": "17",
  "11": 30,
  "12": "14",
  "13": 27,
  "14": "13",
  "15": 22,
  "16": "13",
  "17": 20,
  "18": "12",
  "19": 19,
  "20": "12"
 },
 {
  "0": 83,
  "1": 39,
  "2": "29",
  "3": 38,
  "4": "25",
  "5": 38,
  "6": "23",
  "7": 36,
  "8": "23",
  "9": 32,
  "10": "18",
  "11": 31,
  "12": "15",
  "13": 28,
  "14": "14",
  "15": 23,
  "16": "13",
  "17": 21,
  "18": "12",
  "19": 20,
  "20": "12"
 },
 {
  "0": 84,
  "1": 40,
  "2": "30",
  "3": 40,
  "4": "26",
  "5": 40,
  "6": "24",
  "7": 38,
  "8": "23",
  "9": 33,
  "10": "19",
  "11": 31,
  "12": "15",
  "13": 29,
  "14": "15",
  "15": 24,
  "16": "13",
  "17": 22,
  "18": "13",
  "19": 21,
  "20": "12"
 },
 {
  "0": 85,
  "1": 40,
  "2": "31",
  "3": 41,
  "4": "27",
  "5": 41,
  "6": "24",
  "7": 39,
  "8": "24",
  "9": 34,
  "10": "19",
  "11": 32,
  "12": "16",
  "13": 30,
  "14": "16",
  "15": 25,
  "16": "14",
  "17": 22,
  "18": "13",
  "19": 22,
  "20": "12"
 },
 {
  "0": 86,
  "1": 41,
  "2": "32",
  "3": 42,
  "4": "28",
  "5": 42,
  "6": "25",
  "7": 40,
  "8": "24",
  "9": 37,
  "10": "20",
  "11": 33,
  "12": "17",
  "13": 31,
  "14": "17",
  "15": 26,
  "16": "14",
  "17": 23,
  "18": "13",
  "19": 22,
  "20": "13"
 },
 {
  "0": 87,
  "1": 42,
  "2": "33",
  "3": 43,
  "4": "30",
  "5": 43,
  "6": "26",
  "7": 41,
  "8": "25",
  "9": 38,
  "10": "20",
  "11": 34,
  "12": "18",
  "13": 32,
  "14": "17",
  "15": 27,
  "16": "15",
  "17": 24,
  "18": "13",
  "19": 23,
  "20": "13"
 },
 {
  "0": 88,
  "1": 43,
  "2": "34",
  "3": 44,
  "4": "31",
  "5": 44,
  "6": "27",
  "7": 42,
  "8": "26",
  "9": 39,
  "10": "21",
  "11": 36,
  "12": "19",
  "13": 32,
  "14": "18",
  "15": 28,
  "16": "16",
  "17": 25,
  "18": "13",
  "19": 23,
  "20": "13"
 },
 {
  "0": 89,
  "1": 44,
  "2": "35",
  "3": 45,
  "4": "32",
  "5": 45,
  "6": "28",
  "7": 43,
  "8": "28",
  "9": 41,
  "10": "21",
  "11": 37,
  "12": "19",
  "13": 33,
  "14": "19",
  "15": 30,
  "16": "16",
  "17": 26,
  "18": "14",
  "19": 24,
  "20": "14"
 },
 {
  "0": 90,
  "1": 45,
  "2": "36",
  "3": 46,
  "4": "33",
  "5": 46,
  "6": "30",
  "7": 44,
  "8": "30",
  "9": 42,
  "10": "22",
  "11": 38,
  "12": "20",
  "13": 34,
  "14": "20",
  "15": 31,
  "16": "17",
  "17": 29,
  "18": "15",
  "19": 26,
  "20": 15
 },
 {
  "0": 91,
  "1": 46,
  "2": "37",
  "3": 47,
  "4": "34",
  "5": 47,
  "6": "32",
  "7": 45,
  "8": "31",
  "9": 43,
  "10": "23",
  "11": 41,
  "12": "21",
  "13": 36,
  "14": "21",
  "15": 32,
  "16": "18",
  "17": 30,
  "18": "16",
  "19": 29,
  "20": "16"
 },
 {
  "0": 92,
  "1": 47,
  "2": 38,
  "3": 48,
  "4": "35",
  "5": 48,
  "6": "33",
  "7": 47,
  "8": "32",
  "9": 44,
  "10": "26",
  "11": 42,
  "12": "22",
  "13": 38,
  "14": "22",
  "15": 33,
  "16": "19",
  "17": 31,
  "18": "17",
  "19": 30,
  "20": "17"
 },
 {
  "0": 93,
  "1": 48,
  "2": 38,
  "3": 49,
  "4": "36",
  "5": 49,
  "6": "34",
  "7": 48,
  "8": "34",
  "9": 45,
  "10": "27",
  "11": 43,
  "12": "23",
  "13": 39,
  "14": "23",
  "15": 34,
  "16": "20",
  "17": 33,
  "18": "17",
  "19": 31,
  "20": "17"
 },
 {
  "0": 94,
  "1": 49,
  "2": "39",
  "3": 50,
  "4": "37",
  "5": 51,
  "6": "35",
  "7": 50,
  "8": "35",
  "9": 46,
  "10": "28",
  "11": 44,
  "12": "24",
  "13": 41,
  "14": "24",
  "15": 35,
  "16": "22",
  "17": 34,
  "18": "18",
  "19": 33,
  "20": "18"
 },
 {
  "0": 95,
  "1": 50,
  "2": "42",
  "3": 52,
  "4": "39",
  "5": 52,
  "6": "36",
  "7": 52,
  "8": "36",
  "9": 48,
  "10": "31",
  "11": 46,
  "12": "26",
  "13": 42,
  "14": "25",
  "15": 38,
  "16": "23",
  "17": 35,
  "18": "19",
  "19": 34,
  "20": "19"
 },
 {
  "0": 96,
  "1": 51,
  "2": "43",
  "3": 53,
  "4": "42",
  "5": 53,
  "6": "38",
  "7": 53,
  "8": "37",
  "9": 51,
  "10": "33",
  "11": 48,
  "12": "28",
  "13": 44,
  "14": "27",
  "15": 40,
  "16": "24",
  "17": 37,
  "18": "20",
  "19": 35,
  "20": "20"
 },
 {
  "0": 97,
  "1": 53,
  "2": "45",
  "3": 54,
  "4": "43",
  "5": 55,
  "6": "39",
  "7": 54,
  "8": "40",
  "9": 53,
  "10": "34",
  "11": 50,
  "12": "30",
  "13": 47,
  "14": "30",
  "15": 43,
  "16": "25",
  "17": 38,
  "18": "21",
  "19": 37,
  "20": "21"
 },
 {
  "0": 98,
  "1": 54,
  "2": "47",
  "3": 57,
  "4": "46",
  "5": 56,
  "6": "42",
  "7": 56,
  "8": "43",
  "9": 54,
  "10": "36",
  "11": 53,
  "12": "31",
  "13": 50,
  "14": "31",
  "15": 45,
  "16": "26",
  "17": 40,
  "18": "22",
  "19": 39,
  "20": "22"
 },
 {
  "0": 99,
  "1": 56,
  "2": "50",
  "3": 60,
  "4": "49",
  "5": 59,
  "6": "45",
  "7": 59,
  "8": "46",
  "9": 57,
  "10": "39",
  "11": 55,
  "12": "34",
  "13": 53,
  "14": "34",
  "15": 48,
  "16": "28",
  "17": 43,
  "18": "23",
  "19": 41,
  "20": "23"
 },
 {
  "0": 100,
  "1": 57,
  "2": 53,
  "3": 61,
  "4": 50,
  "5": 62,
  "6": 48,
  "7": 60,
  "8": 47,
  "9": 59,
  "10": 41,
  "11": 56,
  "12": 36,
  "13": 55,
  "14": 35,
  "15": 51,
  "16": 30,
  "17": 46,
  "18": 24,
  "19": 43,
  "20": 24
 },
 {
  "0": "Points",
  "1": "MF",
  "3": "MF",
  "5": "MF",
  "7": "MF",
  "9": "MF",
  "11": "MF",
  "13": "MF",
  "15": "MF",
  "17": "MF",
  "19": "MF"
 },
 {
  "1": "Hand-releasePush-up(HRP)(numberofcorrectlyperformedrepetitionsin2minutes)"
 },
 {
  "1": "17-21",
  "3": "22-26",
  "5": "27-31",
  "7": "32-36",
  "9": "37-41",
  "11": "42-46",
  "13": "47-51",
  "15": "52-56",
  "17": "57-61",
  "19": "Over62"
 }
],
"Table4":[
 {
  "0": 0,
  "1": "03:28",
  "2": "04:15",
  "3": "03:31",
  "4": "04:15",
  "5": "03:32",
  "6": "04:15",
  "7": "03:36",
  "8": "04:22",
  "9": "03:41",
  "10": "04:27",
  "11": "03:45",
  "12": "04:42",
  "13": "03:53",
  "14": "04:51",
  "15": "04:00",
  "16": "05:03",
  "17": "04:12",
  "18": "05:48",
  "19": "04:16",
  "20": "05:48"
 },
 {
  "0": 1,
  "1": "03:27",
  "2": "04:14",
  "3": "03:30",
  "4": "04:14",
  "5": "03:31",
  "6": "04:14",
  "7": "03:35",
  "8": "04:21",
  "9": "03:40",
  "10": "04:26",
  "11": "03:44",
  "12": "04:41",
  "13": "03:52",
  "14": "04:50",
  "15": "03:59",
  "16": "05:02",
  "17": "04:11",
  "18": "05:47",
  "19": "04:15",
  "20": "05:47"
 },
 {
  "0": 2,
  "1": "03:26",
  "2": "04:13",
  "3": "03:29",
  "4": "04:13",
  "5": "03:30",
  "6": "04:13",
  "7": "03:34",
  "8": "04:20",
  "9": "03:39",
  "10": "04:25",
  "11": "03:43",
  "12": "04:40",
  "13": "03:51",
  "14": "04:49",
  "15": "03:58",
  "16": "05:01",
  "17": "04:10",
  "18": "05:46",
  "19": "04:14",
  "20": "05:46"
 },
 {
  "0": 3,
  "1": "03:25",
  "2": "04:12",
  "3": "03:28",
  "4": "04:12",
  "5": "03:29",
  "6": "04:12",
  "7": "03:33",
  "8": "04:19",
  "9": "03:38",
  "10": "04:24",
  "11": "03:42",
  "12": "04:39",
  "13": "03:50",
  "14": "04:48",
  "15": "03:57",
  "16": "05:00",
  "17": "04:09",
  "18": "05:45",
  "19": "04:13",
  "20": "05:45"
 },
 {
  "0": 4,
  "1": "03:24",
  "2": "04:11",
  "3": "03:27",
  "4": "04:11",
  "5": "03:28",
  "6": "04:11",
  "7": "03:32",
  "8": "04:18",
  "9": "03:37",
  "10": "04:23",
  "11": "03:41",
  "12": "04:38",
  "13": "03:49",
  "14": "04:47",
  "15": "03:56",
  "16": "04:59",
  "17": "04:08",
  "18": "05:44",
  "19": "04:12",
  "20": "05:44"
 },
 {
  "0": 5,
  "1": "03:23",
  "2": "04:10",
  "3": "03:26",
  "4": "04:10",
  "5": "03:27",
  "6": "04:10",
  "7": "03:31",
  "8": "04:17",
  "9": "03:36",
  "10": "04:22",
  "11": "03:40",
  "12": "04:37",
  "13": "03:48",
  "14": "04:46",
  "15": "03:55",
  "16": "04:58",
  "17": "04:07",
  "18": "05:43",
  "19": "04:11",
  "20": "05:43"
 },
 {
  "0": 6,
  "1": "03:22",
  "2": "04:09",
  "3": "03:25",
  "4": "04:09",
  "5": "03:26",
  "6": "04:09",
  "7": "03:30",
  "8": "04:16",
  "9": "03:35",
  "10": "04:21",
  "11": "03:39",
  "12": "04:36",
  "13": "03:47",
  "14": "04:45",
  "15": "03:54",
  "16": "04:57",
  "17": "04:06",
  "18": "05:42",
  "19": "04:10",
  "20": "05:42"
 },
 {
  "0": 7,
  "1": "03:21",
  "2": "04:08",
  "3": "03:24",
  "4": "04:08",
  "5": "03:25",
  "6": "04:08",
  "7": "03:29",
  "8": "04:15",
  "9": "03:34",
  "10": "04:20",
  "11": "03:38",
  "12": "04:35",
  "13": "03:46",
  "14": "04:44",
  "15": "03:53",
  "16": "04:56",
  "17": "04:05",
  "18": "05:41",
  "19": "04:09",
  "20": "05:41"
 },
 {
  "0": 8,
  "1": "03:20",
  "2": "04:07",
  "3": "03:23",
  "4": "04:07",
  "5": "03:24",
  "6": "04:07",
  "7": "03:28",
  "8": "04:14",
  "9": "03:33",
  "10": "04:19",
  "11": "03:37",
  "12": "04:34",
  "13": "03:45",
  "14": "04:43",
  "15": "03:52",
  "16": "04:55",
  "17": "04:04",
  "18": "05:40",
  "19": "04:08",
  "20": "05:40"
 },
 {
  "0": 9,
  "1": "03:19",
  "2": "04:06",
  "3": "03:22",
  "4": "04:06",
  "5": "03:23",
  "6": "04:06",
  "7": "03:27",
  "8": "04:13",
  "9": "03:32",
  "10": "04:18",
  "11": "03:36",
  "12": "04:33",
  "13": "03:44",
  "14": "04:42",
  "15": "03:51",
  "16": "04:54",
  "17": "04:03",
  "18": "05:39",
  "19": "04:07",
  "20": "05:39"
 },
 {
  "0": 10,
  "1": "03:18",
  "2": "04:05",
  "3": "03:21",
  "4": "04:05",
  "5": "03:22",
  "6": "04:05",
  "7": "03:26",
  "8": "04:12",
  "9": "03:31",
  "10": "04:17",
  "11": "03:35",
  "12": "04:32",
  "13": "03:43",
  "14": "04:41",
  "15": "03:50",
  "16": "04:53",
  "17": "04:02",
  "18": "05:38",
  "19": "04:06",
  "20": "05:38"
 },
 {
  "0": 11,
  "1": "03:17",
  "2": "04:04",
  "3": "03:20",
  "4": "04:04",
  "5": "03:21",
  "6": "04:04",
  "7": "03:25",
  "8": "04:11",
  "9": "03:30",
  "10": "04:16",
  "11": "03:34",
  "12": "04:31",
  "13": "03:42",
  "14": "04:40",
  "15": "03:49",
  "16": "04:52",
  "17": "04:01",
  "18": "05:37",
  "19": "04:05",
  "20": "05:37"
 },
 {
  "0": 12,
  "1": "03:16",
  "2": "04:03",
  "3": "03:19",
  "4": "04:03",
  "5": "03:20",
  "6": "04:03",
  "7": "03:24",
  "8": "04:10",
  "9": "03:29",
  "10": "04:15",
  "11": "03:33",
  "12": "04:30",
  "13": "03:41",
  "14": "04:39",
  "15": "03:48",
  "16": "04:51",
  "17": "04:00",
  "18": "05:36",
  "19": "04:04",
  "20": "05:36"
 },
 {
  "0": 13,
  "1": "03:15",
  "2": "04:02",
  "3": "03:18",
  "4": "04:02",
  "5": "03:19",
  "6": "04:02",
  "7": "03:23",
  "8": "04:09",
  "9": "03:28",
  "10": "04:14",
  "11": "03:32",
  "12": "04:29",
  "13": "03:40",
  "14": "04:38",
  "15": "03:47",
  "16": "04:50",
  "17": "03:59",
  "18": "05:35",
  "19": "04:03",
  "20": "05:35"
 },
 {
  "0": 14,
  "1": "03:14",
  "2": "04:01",
  "3": "03:17",
  "4": "04:01",
  "5": "03:18",
  "6": "04:01",
  "7": "03:22",
  "8": "04:08",
  "9": "03:27",
  "10": "04:13",
  "11": "03:31",
  "12": "04:28",
  "13": "03:39",
  "14": "04:37",
  "15": "03:46",
  "16": "04:49",
  "17": "03:58",
  "18": "05:34",
  "19": "04:02",
  "20": "05:34"
 },
 {
  "0": 15,
  "1": "03:13",
  "2": "04:00",
  "3": "03:16",
  "4": "04:00",
  "5": "03:17",
  "6": "04:00",
  "7": "03:21",
  "8": "04:07",
  "9": "03:26",
  "10": "04:12",
  "11": "03:30",
  "12": "04:27",
  "13": "03:38",
  "14": "04:36",
  "15": "03:45",
  "16": "04:48",
  "17": "03:57",
  "18": "05:33",
  "19": "04:01",
  "20": "05:33"
 },
 {
  "0": 16,
  "1": "03:12",
  "2": "03:59",
  "3": "03:15",
  "4": "03:59",
  "5": "03:16",
  "6": "03:59",
  "7": "03:20",
  "8": "04:06",
  "9": "03:25",
  "10": "04:11",
  "11": "03:29",
  "12": "04:26",
  "13": "03:37",
  "14": "04:35",
  "15": "03:44",
  "16": "04:47",
  "17": "03:56",
  "18": "05:32",
  "19": "04:00",
  "20": "05:32"
 },
 {
  "0": 17,
  "1": "03:11",
  "2": "03:58",
  "3": "03:14",
  "4": "03:58",
  "5": "03:15",
  "6": "03:58",
  "7": "03:19",
  "8": "04:05",
  "9": "03:24",
  "10": "04:10",
  "11": "03:28",
  "12": "04:25",
  "13": "03:36",
  "14": "04:34",
  "15": "03:43",
  "16": "04:46",
  "17": "03:55",
  "18": "05:31",
  "19": "03:59",
  "20": "05:31"
 },
 {
  "0": 18,
  "1": "03:10",
  "2": "03:57",
  "3": "03:13",
  "4": "03:57",
  "5": "03:14",
  "6": "03:57",
  "7": "03:18",
  "8": "04:04",
  "9": "03:23",
  "10": "04:09",
  "11": "03:27",
  "12": "04:24",
  "13": "03:35",
  "14": "04:33",
  "15": "03:42",
  "16": "04:45",
  "17": "03:54",
  "18": "05:30",
  "19": "03:58",
  "20": "05:30"
 },
 {
  "0": 19,
  "1": "03:09",
  "2": "03:56",
  "3": "03:12",
  "4": "03:56",
  "5": "03:13",
  "6": "03:56",
  "7": "03:17",
  "8": "04:03",
  "9": "03:22",
  "10": "04:08",
  "11": "03:26",
  "12": "04:23",
  "13": "03:34",
  "14": "04:32",
  "15": "03:41",
  "16": "04:44",
  "17": "03:53",
  "18": "05:29",
  "19": "03:57",
  "20": "05:29"
 },
 {
  "0": 20,
  "1": "03:08",
  "2": "03:55",
  "3": "03:11",
  "4": "03:55",
  "5": "03:12",
  "6": "03:55",
  "7": "03:16",
  "8": "04:02",
  "9": "03:21",
  "10": "04:07",
  "11": "03:25",
  "12": "04:22",
  "13": "03:33",
  "14": "04:31",
  "15": "03:40",
  "16": "04:43",
  "17": "03:52",
  "18": "05:28",
  "19": "03:56",
  "20": "05:28"
 },
 {
  "0": 21,
  "1": "03:07",
  "2": "03:54",
  "3": "03:10",
  "4": "03:54",
  "5": "03:11",
  "6": "03:54",
  "7": "03:15",
  "8": "04:01",
  "9": "03:20",
  "10": "04:06",
  "11": "03:24",
  "12": "04:21",
  "13": "03:32",
  "14": "04:30",
  "15": "03:39",
  "16": "04:42",
  "17": "03:51",
  "18": "05:27",
  "19": "03:55",
  "20": "05:27"
 },
 {
  "0": 22,
  "1": "03:06",
  "2": "03:53",
  "3": "03:09",
  "4": "03:53",
  "5": "03:10",
  "6": "03:53",
  "7": "03:14",
  "8": "04:00",
  "9": "03:19",
  "10": "04:05",
  "11": "03:23",
  "12": "04:20",
  "13": "03:31",
  "14": "04:29",
  "15": "03:38",
  "16": "04:41",
  "17": "03:50",
  "18": "05:26",
  "19": "03:54",
  "20": "05:26"
 },
 {
  "0": 23,
  "1": "03:05",
  "2": "03:52",
  "3": "03:08",
  "4": "03:52",
  "5": "03:09",
  "6": "03:52",
  "7": "03:13",
  "8": "03:59",
  "9": "03:18",
  "10": "04:04",
  "11": "03:22",
  "12": "04:19",
  "13": "03:30",
  "14": "04:28",
  "15": "03:37",
  "16": "04:40",
  "17": "03:49",
  "18": "05:25",
  "19": "03:53",
  "20": "05:25"
 },
 {
  "0": 24,
  "1": "03:04",
  "2": "03:51",
  "3": "03:07",
  "4": "03:51",
  "5": "03:08",
  "6": "03:51",
  "7": "03:12",
  "8": "03:58",
  "9": "03:17",
  "10": "04:03",
  "11": "03:21",
  "12": "04:18",
  "13": "03:29",
  "14": "04:27",
  "15": "03:36",
  "16": "04:39",
  "17": "03:48",
  "18": "05:24",
  "19": "03:52",
  "20": "05:24"
 },
 {
  "0": 25,
  "1": "03:03",
  "2": "03:50",
  "3": "03:06",
  "4": "03:50",
  "5": "03:07",
  "6": "03:50",
  "7": "03:11",
  "8": "03:57",
  "9": "03:16",
  "10": "04:02",
  "11": "03:20",
  "12": "04:17",
  "13": "03:28",
  "14": "04:26",
  "15": "03:35",
  "16": "04:38",
  "17": "03:47",
  "18": "05:23",
  "19": "03:51",
  "20": "05:23"
 },
 {
  "0": 26,
  "1": "03:02",
  "2": "03:49",
  "3": "03:05",
  "4": "03:49",
  "5": "03:06",
  "6": "03:49",
  "7": "03:10",
  "8": "03:56",
  "9": "03:15",
  "10": "04:01",
  "11": "03:19",
  "12": "04:16",
  "13": "03:27",
  "14": "04:25",
  "15": "03:34",
  "16": "04:37",
  "17": "03:46",
  "18": "05:22",
  "19": "03:50",
  "20": "05:22"
 },
 {
  "0": 27,
  "1": "03:01",
  "2": "03:48",
  "3": "03:04",
  "4": "03:48",
  "5": "03:05",
  "6": "03:48",
  "7": "03:09",
  "8": "03:55",
  "9": "03:14",
  "10": "04:00",
  "11": "03:18",
  "12": "04:15",
  "13": "03:26",
  "14": "04:24",
  "15": "03:33",
  "16": "04:36",
  "17": "03:45",
  "18": "05:21",
  "19": "03:49",
  "20": "05:21"
 },
 {
  "0": 28,
  "1": "03:00",
  "2": "03:47",
  "3": "03:03",
  "4": "03:47",
  "5": "03:04",
  "6": "03:47",
  "7": "03:08",
  "8": "03:54",
  "9": "03:13",
  "10": "03:59",
  "11": "03:17",
  "12": "04:14",
  "13": "03:25",
  "14": "04:23",
  "15": "03:32",
  "16": "04:35",
  "17": "03:44",
  "18": "05:20",
  "19": "03:48",
  "20": "05:20"
 },
 {
  "0": 29,
  "1": "02:59",
  "2": "03:46",
  "3": "03:02",
  "4": "03:46",
  "5": "03:03",
  "6": "03:46",
  "7": "03:07",
  "8": "03:53",
  "9": "03:12",
  "10": "03:58",
  "11": "03:16",
  "12": "04:13",
  "13": "03:24",
  "14": "04:22",
  "15": "03:31",
  "16": "04:34",
  "17": "03:43",
  "18": "05:19",
  "19": "03:47",
  "20": "05:19"
 },
 {
  "0": 30,
  "1": "02:58",
  "2": "03:45",
  "3": "03:01",
  "4": "03:45",
  "5": "03:02",
  "6": "03:45",
  "7": "03:06",
  "8": "03:52",
  "9": "03:11",
  "10": "03:57",
  "11": "03:15",
  "12": "04:12",
  "13": "03:23",
  "14": "04:21",
  "15": "03:30",
  "16": "04:33",
  "17": "03:42",
  "18": "05:18",
  "19": "03:46",
  "20": "05:18"
 },
 {
  "0": 31,
  "1": "02:57",
  "2": "03:44",
  "3": "03:00",
  "4": "03:44",
  "5": "03:01",
  "6": "03:44",
  "7": "03:05",
  "8": "03:51",
  "9": "03:10",
  "10": "03:56",
  "11": "03:14",
  "12": "04:11",
  "13": "03:22",
  "14": "04:20",
  "15": "03:29",
  "16": "04:32",
  "17": "03:41",
  "18": "05:17",
  "19": "03:45",
  "20": "05:17"
 },
 {
  "0": 32,
  "1": "02:56",
  "2": "03:43",
  "3": "02:59",
  "4": "03:43",
  "5": "03:00",
  "6": "03:43",
  "7": "03:04",
  "8": "03:50",
  "9": "03:09",
  "10": "03:55",
  "11": "03:13",
  "12": "04:10",
  "13": "03:21",
  "14": "04:19",
  "15": "03:28",
  "16": "04:31",
  "17": "03:40",
  "18": "05:16",
  "19": "03:44",
  "20": "05:16"
 },
 {
  "0": 33,
  "1": "02:55",
  "2": "03:42",
  "3": "02:58",
  "4": "03:42",
  "5": "02:59",
  "6": "03:42",
  "7": "03:03",
  "8": "03:49",
  "9": "03:08",
  "10": "03:54",
  "11": "03:12",
  "12": "04:09",
  "13": "03:20",
  "14": "04:18",
  "15": "03:27",
  "16": "04:30",
  "17": "03:39",
  "18": "05:15",
  "19": "03:43",
  "20": "05:15"
 },
 {
  "0": 34,
  "1": "02:54",
  "2": "03:41",
  "3": "02:57",
  "4": "03:41",
  "5": "02:58",
  "6": "03:41",
  "7": "03:02",
  "8": "03:48",
  "9": "03:07",
  "10": "03:53",
  "11": "03:11",
  "12": "04:08",
  "13": "03:19",
  "14": "04:17",
  "15": "03:26",
  "16": "04:29",
  "17": "03:38",
  "18": "05:14",
  "19": "03:42",
  "20": "05:14"
 },
 {
  "0": 35,
  "1": "02:53",
  "2": "03:40",
  "3": "02:56",
  "4": "03:40",
  "5": "02:57",
  "6": "03:40",
  "7": "03:01",
  "8": "03:47",
  "9": "03:06",
  "10": "03:52",
  "11": "03:10",
  "12": "04:07",
  "13": "03:18",
  "14": "04:16",
  "15": "03:25",
  "16": "04:28",
  "17": "03:37",
  "18": "05:13",
  "19": "03:41",
  "20": "05:13"
 },
 {
  "0": 36,
  "1": "02:52",
  "2": "03:39",
  "3": "02:55",
  "4": "03:39",
  "5": "02:56",
  "6": "03:39",
  "7": "03:00",
  "8": "03:46",
  "9": "03:05",
  "10": "03:51",
  "11": "03:09",
  "12": "04:06",
  "13": "03:17",
  "14": "04:15",
  "15": "03:24",
  "16": "04:27",
  "17": "03:36",
  "18": "05:12",
  "19": "03:40",
  "20": "05:12"
 },
 {
  "0": 37,
  "1": "02:51",
  "2": "03:38",
  "3": "02:54",
  "4": "03:38",
  "5": "02:55",
  "6": "03:38",
  "7": "02:59",
  "8": "03:45",
  "9": "03:04",
  "10": "03:50",
  "11": "03:08",
  "12": "04:05",
  "13": "03:16",
  "14": "04:14",
  "15": "03:23",
  "16": "04:26",
  "17": "03:35",
  "18": "05:11",
  "19": "03:39",
  "20": "05:11"
 },
 {
  "0": 38,
  "1": "02:50",
  "2": "03:37",
  "3": "02:53",
  "4": "03:37",
  "5": "02:54",
  "6": "03:37",
  "7": "02:58",
  "8": "03:44",
  "9": "03:03",
  "10": "03:49",
  "11": "03:07",
  "12": "04:04",
  "13": "03:15",
  "14": "04:13",
  "15": "03:22",
  "16": "04:25",
  "17": "03:34",
  "18": "05:10",
  "19": "03:38",
  "20": "05:10"
 },
 {
  "0": 39,
  "1": "02:49",
  "2": "03:36",
  "3": "02:52",
  "4": "03:36",
  "5": "02:53",
  "6": "03:36",
  "7": "02:57",
  "8": "03:43",
  "9": "03:02",
  "10": "03:48",
  "11": "03:06",
  "12": "04:03",
  "13": "03:14",
  "14": "04:12",
  "15": "03:21",
  "16": "04:24",
  "17": "03:33",
  "18": "05:09",
  "19": "03:37",
  "20": "05:09"
 },
 {
  "0": 40,
  "1": "02:48",
  "2": "03:35",
  "3": "02:51",
  "4": "03:35",
  "5": "02:52",
  "6": "03:35",
  "7": "02:56",
  "8": "03:42",
  "9": "03:01",
  "10": "03:47",
  "11": "03:05",
  "12": "04:02",
  "13": "03:13",
  "14": "04:11",
  "15": "03:20",
  "16": "04:23",
  "17": "03:32",
  "18": "05:08",
  "19": "03:36",
  "20": "05:08"
 },
 {
  "0": 41,
  "1": "02:47",
  "2": "03:34",
  "3": "02:50",
  "4": "03:34",
  "5": "02:51",
  "6": "03:34",
  "7": "02:55",
  "8": "03:41",
  "9": "03:00",
  "10": "03:46",
  "11": "03:04",
  "12": "04:01",
  "13": "03:12",
  "14": "04:10",
  "15": "03:19",
  "16": "04:22",
  "17": "03:31",
  "18": "05:07",
  "19": "03:35",
  "20": "05:07"
 },
 {
  "0": 42,
  "1": "02:46",
  "2": "03:33",
  "3": "02:49",
  "4": "03:33",
  "5": "02:50",
  "6": "03:33",
  "7": "02:54",
  "8": "03:40",
  "9": "02:59",
  "10": "03:45",
  "11": "03:03",
  "12": "04:00",
  "13": "03:11",
  "14": "04:09",
  "15": "03:18",
  "16": "04:21",
  "17": "03:30",
  "18": "05:06",
  "19": "03:34",
  "20": "05:06"
 },
 {
  "0": 43,
  "1": "02:45",
  "2": "03:32",
  "3": "02:48",
  "4": "03:32",
  "5": "02:49",
  "6": "03:32",
  "7": "02:53",
  "8": "03:39",
  "9": "02:58",
  "10": "03:44",
  "11": "03:02",
  "12": "03:59",
  "13": "03:10",
  "14": "04:08",
  "15": "03:17",
  "16": "04:20",
  "17": "03:29",
  "18": "05:05",
  "19": "03:33",
  "20": "05:05"
 },
 {
  "0": 44,
  "1": "02:44",
  "2": "03:31",
  "3": "02:47",
  "4": "03:31",
  "5": "02:48",
  "6": "03:31",
  "7": "02:52",
  "8": "03:38",
  "9": "02:57",
  "10": "03:43",
  "11": "03:01",
  "12": "03:58",
  "13": "03:09",
  "14": "04:07",
  "15": "03:16",
  "16": "04:19",
  "17": "03:28",
  "18": "05:04",
  "19": "03:32",
  "20": "05:04"
 },
 {
  "0": 45,
  "1": "02:43",
  "2": "03:30",
  "3": "02:46",
  "4": "03:30",
  "5": "02:47",
  "6": "03:30",
  "7": "02:51",
  "8": "03:37",
  "9": "02:56",
  "10": "03:42",
  "11": "03:00",
  "12": "03:57",
  "13": "03:08",
  "14": "04:06",
  "15": "03:15",
  "16": "04:18",
  "17": "03:27",
  "18": "05:03",
  "19": "03:31",
  "20": "05:03"
 },
 {
  "0": 46,
  "1": "02:42",
  "2": "03:29",
  "3": "02:45",
  "4": "03:29",
  "5": "02:46",
  "6": "03:29",
  "7": "02:50",
  "8": "03:36",
  "9": "02:55",
  "10": "03:41",
  "11": "02:59",
  "12": "03:56",
  "13": "03:07",
  "14": "04:05",
  "15": "03:14",
  "16": "04:17",
  "17": "03:26",
  "18": "05:02",
  "19": "03:30",
  "20": "05:02"
 },
 {
  "0": 47,
  "1": "02:41",
  "2": "03:28",
  "3": "02:44",
  "4": "03:28",
  "5": "02:45",
  "6": "03:28",
  "7": "02:49",
  "8": "03:35",
  "9": "02:54",
  "10": "03:40",
  "11": "02:58",
  "12": "03:55",
  "13": "03:06",
  "14": "04:04",
  "15": "03:13",
  "16": "04:16",
  "17": "03:25",
  "18": "05:01",
  "19": "03:29",
  "20": "05:01"
 },
 {
  "0": 48,
  "1": "02:40",
  "2": "03:27",
  "3": "02:43",
  "4": "03:27",
  "5": "02:44",
  "6": "03:27",
  "7": "02:48",
  "8": "03:34",
  "9": "02:53",
  "10": "03:39",
  "11": "02:57",
  "12": "03:54",
  "13": "03:05",
  "14": "04:03",
  "15": "03:12",
  "16": "04:15",
  "17": "03:24",
  "18": "05:00",
  "19": "03:28",
  "20": "05:00"
 },
 {
  "0": 49,
  "1": "02:39",
  "2": "03:26",
  "3": "02:42",
  "4": "03:26",
  "5": "02:43",
  "6": "03:26",
  "7": "02:47",
  "8": "03:33",
  "9": "02:52",
  "10": "03:38",
  "11": "02:56",
  "12": "03:53",
  "13": "03:04",
  "14": "04:02",
  "15": "03:11",
  "16": "04:14",
  "17": "03:23",
  "18": "04:59",
  "19": "03:27",
  "20": "04:59"
 },
 {
  "0": 50,
  "1": "02:38",
  "2": "03:25",
  "3": "02:41",
  "4": "03:25",
  "5": "02:42",
  "6": "03:25",
  "7": "02:46",
  "8": "03:32",
  "9": "02:51",
  "10": "03:37",
  "11": "02:55",
  "12": "03:52",
  "13": "03:03",
  "14": "04:01",
  "15": "03:10",
  "16": "04:13",
  "17": "03:22",
  "18": "04:58",
  "19": "03:26",
  "20": "04:58"
 },
 {
  "0": 51,
  "1": "02:37",
  "2": "03:24",
  "3": "02:40",
  "4": "03:24",
  "5": "02:41",
  "6": "03:24",
  "7": "02:45",
  "8": "03:31",
  "9": "02:50",
  "10": "03:36",
  "11": "02:54",
  "12": "03:51",
  "13": "03:02",
  "14": "04:00",
  "15": "03:09",
  "16": "04:12",
  "17": "03:21",
  "18": "04:57",
  "19": "03:25",
  "20": "04:57"
 },
 {
  "0": 52,
  "1": "02:36",
  "2": "03:23",
  "3": "02:39",
  "4": "03:23",
  "5": "02:40",
  "6": "03:23",
  "7": "02:44",
  "8": "03:30",
  "9": "02:49",
  "10": "03:35",
  "11": "02:53",
  "12": "03:50",
  "13": "03:01",
  "14": "03:59",
  "15": "03:08",
  "16": "04:11",
  "17": "03:20",
  "18": "04:56",
  "19": "03:24",
  "20": "04:56"
 },
 {
  "0": 53,
  "1": "02:35",
  "2": "03:22",
  "3": "02:38",
  "4": "03:22",
  "5": "02:39",
  "6": "03:22",
  "7": "02:43",
  "8": "03:29",
  "9": "02:48",
  "10": "03:34",
  "11": "02:52",
  "12": "03:49",
  "13": "03:00",
  "14": "03:58",
  "15": "03:07",
  "16": "04:10",
  "17": "03:19",
  "18": "04:55",
  "19": "03:23",
  "20": "04:55"
 },
 {
  "0": 54,
  "1": "02:34",
  "2": "03:21",
  "3": "02:37",
  "4": "03:21",
  "5": "02:38",
  "6": "03:21",
  "7": "02:42",
  "8": "03:28",
  "9": "02:47",
  "10": "03:33",
  "11": "02:51",
  "12": "03:48",
  "13": "02:59",
  "14": "03:57",
  "15": "03:06",
  "16": "04:09",
  "17": "03:18",
  "18": "04:54",
  "19": "03:22",
  "20": "04:54"
 },
 {
  "0": 55,
  "1": "02:33",
  "2": "03:20",
  "3": "02:36",
  "4": "03:20",
  "5": "02:37",
  "6": "03:20",
  "7": "02:41",
  "8": "03:27",
  "9": "02:46",
  "10": "03:32",
  "11": "02:50",
  "12": "03:47",
  "13": "02:58",
  "14": "03:56",
  "15": "03:05",
  "16": "04:08",
  "17": "03:17",
  "18": "04:53",
  "19": "03:21",
  "20": "04:53"
 },
 {
  "0": 56,
  "1": "02:32",
  "2": "03:19",
  "3": "02:35",
  "4": "03:19",
  "5": "02:36",
  "6": "03:19",
  "7": "02:40",
  "8": "03:26",
  "9": "02:45",
  "10": "03:31",
  "11": "02:49",
  "12": "03:46",
  "13": "02:57",
  "14": "03:55",
  "15": "03:04",
  "16": "04:07",
  "17": "03:16",
  "18": "04:52",
  "19": "03:20",
  "20": "04:52"
 },
 {
  "0": 57,
  "1": "02:31",
  "2": "03:18",
  "3": "02:34",
  "4": "03:18",
  "5": "02:35",
  "6": "03:18",
  "7": "02:39",
  "8": "03:25",
  "9": "02:44",
  "10": "03:30",
  "11": "02:48",
  "12": "03:45",
  "13": "02:56",
  "14": "03:54",
  "15": "03:03",
  "16": "04:06",
  "17": "03:15",
  "18": "04:51",
  "19": "03:19",
  "20": "04:51"
 },
 {
  "0": 58,
  "1": "02:30",
  "2": "03:17",
  "3": "02:33",
  "4": "03:17",
  "5": "02:34",
  "6": "03:17",
  "7": "02:38",
  "8": "03:24",
  "9": "02:43",
  "10": "03:29",
  "11": "02:47",
  "12": "03:44",
  "13": "02:55",
  "14": "03:53",
  "15": "03:02",
  "16": "04:05",
  "17": "03:14",
  "18": "04:50",
  "19": "03:18",
  "20": "04:50"
 },
 {
  "0": 59,
  "1": "02:29",
  "2": "03:16",
  "3": "02:32",
  "4": "03:16",
  "5": "02:33",
  "6": "03:16",
  "7": "02:37",
  "8": "03:23",
  "9": "02:42",
  "10": "03:28",
  "11": "02:46",
  "12": "03:43",
  "13": "02:54",
  "14": "03:52",
  "15": "03:01",
  "16": "04:04",
  "17": "03:13",
  "18": "04:49",
  "19": "03:17",
  "20": "04:49"
 },
 {
  "0": 60,
  "1": "02:28",
  "2": "03:15",
  "3": "02:31",
  "4": "03:15",
  "5": "02:32",
  "6": "03:15",
  "7": "02:36",
  "8": "03:22",
  "9": "02:41",
  "10": "03:27",
  "11": "02:45",
  "12": "03:42",
  "13": "02:53",
  "14": "03:51",
  "15": "03:00",
  "16": "04:03",
  "17": "03:12",
  "18": "04:48",
  "19": "03:16",
  "20": "04:48"
 },
 {
  "0": 61,
  "1": "02:22",
  "2": "03:08",
  "3": "02:26",
  "4": "03:09",
  "5": "02:28",
  "6": "03:07",
  "7": "02:31",
  "8": "03:15",
  "9": "02:36",
  "10": "03:21",
  "11": "02:41",
  "12": "03:31",
  "13": "02:48",
  "14": "03:42",
  "15": "02:57",
  "16": "03:58",
  "17": "03:04",
  "18": "04:21",
  "19": "03:14",
  "20": "04:21"
 },
 {
  "0": 62,
  "1": "02:17",
  "2": "03:00",
  "3": "02:21",
  "4": "03:01",
  "5": "02:22",
  "6": "03:00",
  "7": "02:26",
  "8": "03:06",
  "9": "02:31",
  "10": "03:13",
  "11": "02:36",
  "12": "03:21",
  "13": "02:44",
  "14": "03:32",
  "15": "02:50",
  "16": "03:50",
  "17": "02:59",
  "18": "04:16",
  "19": "03:12",
  "20": "04:16"
 },
 {
  "0": 63,
  "1": "02:15",
  "2": "02:58",
  "3": "02:18",
  "4": "02:59",
  "5": "02:20",
  "6": "02:59",
  "7": "02:24",
  "8": "03:02",
  "9": "02:28",
  "10": "03:09",
  "11": "02:33",
  "12": "03:17",
  "13": "02:41",
  "14": "03:29",
  "15": "02:48",
  "16": "03:45",
  "17": "02:57",
  "18": "04:08",
  "19": "03:11",
  "20": "04:08"
 },
 {
  "0": 64,
  "1": "02:13",
  "2": "02:55",
  "3": "02:16",
  "4": "02:57",
  "5": "02:17",
  "6": "02:56",
  "7": "02:21",
  "8": "03:00",
  "9": "02:26",
  "10": "03:05",
  "11": "02:31",
  "12": "03:12",
  "13": "02:39",
  "14": "03:24",
  "15": "02:46",
  "16": "03:42",
  "17": "02:55",
  "18": "04:00",
  "19": "03:09",
  "20": "04:00"
 },
 {
  "0": 65,
  "1": "02:11",
  "2": "02:53",
  "3": "02:14",
  "4": "02:54",
  "5": "02:15",
  "6": "02:54",
  "7": "02:19",
  "8": "02:58",
  "9": "02:24",
  "10": "03:02",
  "11": "02:29",
  "12": "03:10",
  "13": "02:37",
  "14": "03:21",
  "15": "02:44",
  "16": "03:38",
  "17": "02:53",
  "18": "03:54",
  "19": "03:03",
  "20": "03:54"
 },
 {
  "0": 66,
  "1": "02:08",
  "2": "02:49",
  "3": "02:11",
  "4": "02:51",
  "5": "02:13",
  "6": "02:51",
  "7": "02:16",
  "8": "02:55",
  "9": "02:21",
  "10": "03:00",
  "11": "02:26",
  "12": "03:06",
  "13": "02:34",
  "14": "03:16",
  "15": "02:41",
  "16": "03:33",
  "17": "02:50",
  "18": "03:46",
  "19": "03:00",
  "20": "03:46"
 },
 {
  "0": 67,
  "1": "02:07",
  "2": "02:47",
  "3": "02:10",
  "4": "02:49",
  "5": "02:11",
  "6": "02:49",
  "7": "02:15",
  "8": "02:53",
  "9": "02:20",
  "10": "02:58",
  "11": "02:25",
  "12": "03:02",
  "13": "02:32",
  "14": "03:14",
  "15": "02:40",
  "16": "03:29",
  "17": "02:48",
  "18": "03:43",
  "19": "02:57",
  "20": "03:43"
 },
 {
  "0": 68,
  "1": "02:06",
  "2": "02:45",
  "3": "02:08",
  "4": "02:47",
  "5": "02:10",
  "6": "02:47",
  "7": "02:13",
  "8": "02:51",
  "9": "02:18",
  "10": "02:56",
  "11": "02:23",
  "12": "03:01",
  "13": "02:30",
  "14": "03:10",
  "15": "02:38",
  "16": "03:27",
  "17": "02:47",
  "18": "03:41",
  "19": "02:56",
  "20": "03:41"
 },
 {
  "0": 69,
  "1": "02:04",
  "2": "02:44 ",
  "3": "02:07",
  "4": "02:45",
  "5": "02:08",
  "6": "02:45",
  "7": "02:11",
  "8": "02:50",
  "9": "02:16",
  "10": "02:55",
  "11": "02:22",
  "12": "03:00",
  "13": "02:29",
  "14": "03:08",
  "15": "02:37",
  "16": "03:25",
  "17": "02:45",
  "18": "03:40",
  "19": "02:52",
  "20": "03:40"
 },
 {
  "0": 70,
  "1": "02:03",
  "2": "02:41",
  "3": "02:05",
  "4": "02:43",
  "5": "02:06",
  "6": "02:43",
  "7": "02:10",
  "8": "02:47",
  "9": "02:14",
  "10": "02:52",
  "11": "02:20",
  "12": "02:58",
  "13": "02:27",
  "14": "03:05",
  "15": "02:35",
  "16": "03:19",
  "17": "02:43",
  "18": "03:36",
  "19": "02:49",
  "20": "03:36"
 },
 {
  "0": 71,
  "1": "02:02",
  "2": "02:40",
  "3": "02:03",
  "4": "02:42",
  "5": "02:05",
  "6": "02:41",
  "7": "02:08",
  "8": "02:46",
  "9": "02:13",
  "10": "02:50",
  "11": "02:18",
  "12": "02:56",
  "13": "02:26",
  "14": "03:02",
  "15": "02:34",
  "16": "03:16",
  "17": "02:42",
  "18": "03:35",
  "19": "02:47",
  "20": "03:35"
 },
 {
  "0": 72,
  "1": "02:01",
  "2": "02:39",
  "3": "02:02",
  "4": "02:40",
  "5": "02:04",
  "6": "02:40",
  "7": "02:07",
  "8": "02:45",
  "9": "02:12",
  "10": "02:49",
  "11": "02:17",
  "12": "02:55",
  "13": "02:25",
  "14": "03:00",
  "15": "02:32",
  "16": "03:13",
  "17": "02:40",
  "18": "03:34",
  "19": "02:46",
  "20": "03:34"
 },
 {
  "0": 73,
  "1": "02:00",
  "2": "02:37",
  "3": "02:01",
  "4": "02:38",
  "5": "02:02",
  "6": "02:38",
  "7": "02:05",
  "8": "02:43",
  "9": "02:10",
  "10": "02:47",
  "11": "02:15",
  "12": "02:53",
  "13": "02:23",
  "14": "02:59",
  "15": "02:31",
  "16": "03:10",
  "17": "02:38",
  "18": "03:32",
  "19": "02:44",
  "20": "03:32"
 },
 {
  "0": 74,
  "1": "01:59",
  "2": "02:35",
  "3": "02:00",
  "4": "02:37",
  "5": "02:01",
  "6": "02:37",
  "7": "02:04",
  "8": "02:41",
  "9": "02:09",
  "10": "02:46",
  "11": "02:14",
  "12": "02:52",
  "13": "02:21",
  "14": "02:58",
  "15": "02:30",
  "16": "03:09",
  "17": "02:37",
  "18": "03:25",
  "19": "02:43",
  "20": "03:25"
 },
 {
  "0": 75,
  "1": "01:58",
  "2": "02:34",
  "3": "01:59",
  "4": "02:35",
  "5": "02:00",
  "6": "02:36",
  "7": "02:03",
  "8": "02:40",
  "9": "02:08",
  "10": "02:45",
  "11": "02:13",
  "12": "02:50",
  "13": "02:20",
  "14": "02:57",
  "15": "02:29",
  "16": "03:07",
  "17": "02:36",
  "18": "03:21",
  "19": "02:41",
  "20": "03:21"
 },
 {
  "0": 76,
  "1": "01:57",
  "2": "02:33",
  "3": "01:58",
  "4": "02:34",
  "5": "01:59",
  "6": "02:34",
  "7": "02:02",
  "8": "02:39",
  "9": "02:07",
  "10": "02:43",
  "11": "02:12",
  "12": "02:49",
  "13": "02:19",
  "14": "02:56",
  "15": "02:28",
  "16": "03:05",
  "17": "02:35",
  "18": "03:17",
  "19": "02:38",
  "20": "03:17"
 },
 {
  "0": 77,
  "1": "01:56",
  "2": "02:31",
  "3": "01:56",
  "4": "02:32",
  "5": "01:58",
  "6": "02:32",
  "7": "02:01",
  "8": "02:37",
  "9": "02:05",
  "10": "02:42",
  "11": "02:10",
  "12": "02:47",
  "13": "02:17",
  "14": "02:54",
  "15": "02:26",
  "16": "03:02",
  "17": "02:33",
  "18": "03:11",
  "19": "02:36",
  "20": "03:11"
 },
 {
  "0": 78,
  "1": "01:55",
  "2": "02:30",
  "3": "01:55",
  "4": "02:31",
  "5": "01:57",
  "6": "02:31",
  "7": "02:00",
  "8": "02:36",
  "9": "02:04",
  "10": "02:40",
  "11": "02:09",
  "12": "02:46",
  "13": "02:16",
  "14": "02:52",
  "15": "02:25",
  "16": "03:00",
  "17": "02:31",
  "18": "03:09",
  "19": "02:35",
  "20": "03:09"
 },
 {
  "0": 79,
  "1": "01:54",
  "2": "02:29",
  "3": "01:54",
  "4": "02:30",
  "5": "01:56",
  "6": "02:30",
  "7": "01:59",
  "8": "02:35",
  "9": "02:03",
  "10": "02:39",
  "11": "02:08",
  "12": "02:45",
  "13": "02:15",
  "14": "02:51",
  "15": "02:23",
  "16": "02:59",
  "17": "02:30",
  "18": "03:08",
  "19": "02:33",
  "20": "03:08"
 },
 {
  "0": 80,
  "1": "01:53",
  "2": "02:28",
  "3": "01:53",
  "4": "02:29",
  "5": "01:55",
  "6": "02:29",
  "7": "01:58",
  "8": "02:34",
  "9": "02:02",
  "10": "02:38",
  "11": "02:07",
  "12": "02:44",
  "13": "02:14",
  "14": "02:50",
  "15": "02:23",
  "16": "02:58",
  "17": "02:29",
  "18": "03:07",
  "19": "02:32",
  "20": "03:07"
 },
 {
  "0": 81,
  "1": "01:52",
  "2": "02:26",
  "3": "01:52",
  "4": "02:27",
  "5": "01:54",
  "6": "02:28",
  "7": "01:57",
  "8": "02:32",
  "9": "02:01",
  "10": "02:36",
  "11": "02:06",
  "12": "02:42",
  "13": "02:13",
  "14": "02:48",
  "15": "02:21",
  "16": "02:57",
  "17": "02:28",
  "18": "03:04",
  "19": "02:27",
  "20": "03:04"
 },
 {
  "0": 82,
  "1": "01:51",
  "2": "02:25",
  "3": "01:51",
  "4": "02:26",
  "5": "01:53",
  "6": "02:27",
  "7": "01:56",
  "8": "02:31",
  "9": "02:00",
  "10": "02:35",
  "11": "02:05",
  "12": "02:41",
  "13": "02:12",
  "14": "02:47",
  "15": "02:20",
  "16": "02:55",
  "17": "02:27",
  "18": "03:03",
  "19": "02:24",
  "20": "03:03"
 },
 {
  "0": 83,
  "1": "01:50",
  "2": "02:24",
  "3": "01:50",
  "4": "02:25",
  "5": "01:52",
  "6": "02:26",
  "7": "01:55",
  "8": "02:30",
  "9": "01:59",
  "10": "02:34",
  "11": "02:04",
  "12": "02:40",
  "13": "02:10",
  "14": "02:46",
  "15": "02:19",
  "16": "02:54",
  "17": "02:26",
  "18": "03:02",
  "19": "02:23",
  "20": "03:02"
 },
 {
  "0": 84,
  "1": "01:49",
  "2": "02:23",
  "3": "01:49",
  "4": "02:23",
  "5": "01:51",
  "6": "02:24",
  "7": "01:54",
  "8": "02:28",
  "9": "01:58",
  "10": "02:32",
  "11": "02:02",
  "12": "02:38",
  "13": "02:09",
  "14": "02:45",
  "15": "02:17",
  "16": "02:52",
  "17": "02:24",
  "18": "03:01",
  "19": "02:22",
  "20": "03:01"
 },
 {
  "0": 85,
  "1": "01:48",
  "2": "02:22",
  "3": "01:48",
  "4": "02:22",
  "5": "01:50",
  "6": "02:23",
  "7": "01:53",
  "8": "02:27",
  "9": "01:57",
  "10": "02:31",
  "11": "02:01",
  "12": "02:37",
  "13": "02:08",
  "14": "02:44",
  "15": "02:16",
  "16": "02:51",
  "17": "02:23",
  "18": "03:00",
  "19": "02:21",
  "20": "03:00"
 },
 {
  "0": 86,
  "1": "01:47",
  "2": "02:21",
  "3": "01:47",
  "4": "02:21",
  "5": "01:49",
  "6": "02:22",
  "7": "01:52",
  "8": "02:26",
  "9": "01:56",
  "10": "02:30",
  "11": "02:00",
  "12": "02:36",
  "13": "02:07",
  "14": "02:42",
  "15": "02:15",
  "16": "02:50",
  "17": "02:22",
  "18": "02:59",
  "19": "02:20",
  "20": "02:59"
 },
 {
  "0": 87,
  "1": "01:46",
  "2": "02:20",
  "3": "01:46",
  "4": "02:20",
  "5": "01:48",
  "6": "02:20",
  "7": "01:51",
  "8": "02:24",
  "9": "01:55",
  "10": "02:29",
  "11": "01:59",
  "12": "02:35",
  "13": "02:06",
  "14": "02:41",
  "15": "02:14",
  "16": "02:48",
  "17": "02:21",
  "18": "02:58",
  "19": "02:19",
  "20": "02:58"
 },
 {
  "0": 88,
  "1": "01:45",
  "2": "02:18",
  "3": "01:45",
  "4": "02:18",
  "5": "01:47",
  "6": "02:19",
  "7": "01:50",
  "8": "02:23",
  "9": "01:54",
  "10": "02:27",
  "11": "01:58",
  "12": "02:33",
  "13": "02:05",
  "14": "02:40",
  "15": "02:13",
  "16": "02:46",
  "17": "02:20",
  "18": "02:57",
  "19": "02:18",
  "20": "02:57"
 },
 {
  "0": 89,
  "1": "01:44",
  "2": "02:17",
  "3": "01:44",
  "4": "02:16",
  "5": "01:46",
  "6": "02:18",
  "7": "01:49",
  "8": "02:21",
  "9": "01:53",
  "10": "02:26",
  "11": "01:57",
  "12": "02:31",
  "13": "02:03",
  "14": "02:38",
  "15": "02:11",
  "16": "02:45",
  "17": "02:19",
  "18": "02:55",
  "19": "02:17",
  "20": "02:55"
 },
 {
  "0": 90,
  "1": "01:43",
  "2": "02:16",
  "3": "01:43",
  "4": "02:15",
  "5": "01:45",
  "6": "02:16",
  "7": "01:48",
  "8": "02:20",
  "9": "01:52",
  "10": "02:25",
  "11": "01:56",
  "12": "02:30",
  "13": "02:02",
  "14": "02:37",
  "15": "02:10",
  "16": "02:44",
  "17": "02:17",
  "18": "02:54",
  "19": "02:17",
  "20": "02:54"
 },
 {
  "0": 91,
  "1": "01:42",
  "2": "02:14",
  "3": "01:42",
  "4": "02:13",
  "5": "01:43",
  "6": "02:15",
  "7": "01:46",
  "8": "02:18",
  "9": "01:50",
  "10": "02:23",
  "11": "01:54",
  "12": "02:28",
  "13": "02:01",
  "14": "02:35",
  "15": "02:09",
  "16": "02:42",
  "17": "02:16",
  "18": "02:52",
  "19": "02:17",
  "20": "02:52"
 },
 {
  "0": 92,
  "1": "01:41",
  "2": "02:13",
  "3": "01:41",
  "4": "02:12",
  "5": "01:42",
  "6": "02:13",
  "7": "01:45",
  "8": "02:17",
  "9": "01:49",
  "10": "02:21",
  "11": "01:53",
  "12": "02:27",
  "13": "02:00",
  "14": "02:33",
  "15": "02:07",
  "16": "02:41",
  "17": "02:15",
  "18": "02:48",
  "19": "02:17",
  "20": "02:48"
 },
 {
  "0": 93,
  "1": "01:40",
  "2": "02:12",
  "3": "01:40",
  "4": "02:10",
  "5": "01:41",
  "6": "02:12",
  "7": "01:44",
  "8": "02:15",
  "9": "01:48",
  "10": "02:20",
  "11": "01:52",
  "12": "02:25",
  "13": "01:59",
  "14": "02:31",
  "15": "02:06",
  "16": "02:40",
  "17": "02:13",
  "18": "02:46",
  "19": "02:16",
  "20": "02:46"
 },
 {
  "0": 94,
  "1": "01:39",
  "2": "02:10",
  "3": "01:39",
  "4": "02:09",
  "5": "01:40",
  "6": "02:10",
  "7": "01:43",
  "8": "02:14",
  "9": "01:47",
  "10": "02:18",
  "11": "01:51",
  "12": "02:23",
  "13": "01:57",
  "14": "02:30",
  "15": "02:05",
  "16": "02:38",
  "17": "02:11",
  "18": "02:45",
  "19": "02:15",
  "20": "02:45"
 },
 {
  "0": 95,
  "1": "01:37",
  "2": "02:08",
  "3": "01:37",
  "4": "02:06",
  "5": "01:38",
  "6": "02:08",
  "7": "01:41",
  "8": "02:11",
  "9": "01:45",
  "10": "02:15",
  "11": "01:49",
  "12": "02:20",
  "13": "01:55",
  "14": "02:28",
  "15": "02:03",
  "16": "02:35",
  "17": "02:09",
  "18": "02:44",
  "19": "02:14",
  "20": "02:44"
 },
 {
  "0": 96,
  "1": "01:36",
  "2": "02:06",
  "3": "01:36",
  "4": "02:05",
  "5": "01:37",
  "6": "02:06",
  "7": "01:40",
  "8": "02:10",
  "9": "01:43",
  "10": "02:14",
  "11": "01:48",
  "12": "02:18",
  "13": "01:54",
  "14": "02:26",
  "15": "02:01",
  "16": "02:32",
  "17": "02:08",
  "18": "02:41",
  "19": "02:14",
  "20": "02:41"
 },
 {
  "0": 97,
  "1": "01:35",
  "2": "02:05",
  "3": "01:34",
  "4": "02:02",
  "5": "01:35",
  "6": "02:04",
  "7": "01:38",
  "8": "02:08",
  "9": "01:42",
  "10": "02:11",
  "11": "01:46",
  "12": "02:17",
  "13": "01:52",
  "14": "02:24",
  "15": "02:00",
  "16": "02:30",
  "17": "02:06",
  "18": "02:39",
  "19": "02:13",
  "20": "02:39"
 },
 {
  "0": 98,
  "1": "01:34",
  "2": "02:02",
  "3": "01:33",
  "4": "02:00",
  "5": "01:34",
  "6": "02:01",
  "7": "01:37",
  "8": "02:05",
  "9": "01:40",
  "10": "02:10",
  "11": "01:44",
  "12": "02:15",
  "13": "01:50",
  "14": "02:22",
  "15": "01:57",
  "16": "02:28",
  "17": "02:03",
  "18": "02:34",
  "19": "02:13",
  "20": "02:34"
 },
 {
  "0": 99,
  "1": "01:31",
  "2": "01:59",
  "3": "01:32",
  "4": "01:56",
  "5": "01:31",
  "6": "01:57",
  "7": "01:34",
  "8": "02:01 ",
  "9": "01:37",
  "10": "02:04 ",
  "11": "01:42",
  "12": "02:10 ",
  "13": "01:46",
  "14": "02:13",
  "15": "01:55",
  "16": "02:21",
  "17": "02:02",
  "18": "02:28",
  "19": "02:12",
  "20": "02:28"
 },
 {
  "0": 100,
  "1": "01:29",
  "2": "01:55",
  "3": "01:30",
  "4": "01:55",
  "5": "01:30",
  "6": "01:55",
  "7": "01:33",
  "8": "01:59",
  "9": "01:36",
  "10": "02:02",
  "11": "01:40",
  "12": "02:09",
  "13": "01:45",
  "14": "02:11",
  "15": "01:52",
  "16": "02:18",
  "17": "01:58",
  "18": "02:26",
  "19": "02:09",
  "20": "02:26"
 },
 {
  "0": "Points",
  "1": "MF",
  "3": "MF",
  "5": "MF",
  "7": "MF",
  "9": "MF",
  "11": "MF",
  "13": "MF",
  "15": "MF",
  "17": "MF",
  "19": "MF"
 },
 {
  "1": "Sprint /Drag /Carry(SDC)(OverallTime:minutesandseconds)"
 },
 {
  "1": "17-21",
  "3": "22-26",
  "5": "27-31",
  "7": "32-36",
  "9": "37-41",
  "11": "42-46",
  "13": "47-51",
  "15": "52-56",
  "17": "57-61",
  "19": "Over62"
 }
],
"Table5":[
 {
  "0": 0,
  "1": "01:00",
  "2": "01:00",
  "3": "00:55",
  "4": "00:55",
  "5": "00:50",
  "6": "00:50",
  "7": "00:45",
  "8": "00:45",
  "9": "00:40",
  "10": "00:40",
  "11": "00:40",
  "12": "00:40",
  "13": "00:40",
  "14": "00:40",
  "15": "00:40",
  "16": "00:40",
  "17": "00:40",
  "18": "00:40",
  "19": "00:40",
  "20": "00:40"
 },
 {
  "0": 1,
  "1": "01:00",
  "2": "01:00",
  "3": "00:55",
  "4": "00:55",
  "5": "00:50",
  "6": "00:50",
  "7": "00:45",
  "8": "00:45",
  "9": "00:40",
  "10": "00:40",
  "11": "00:40",
  "12": "00:40",
  "13": "00:40",
  "14": "00:40",
  "15": "00:40",
  "16": "00:40",
  "17": "00:40",
  "18": "00:40",
  "19": "00:40",
  "20": "00:40"
 },
 {
  "0": 2,
  "1": "01:01",
  "2": "01:01",
  "3": "00:56",
  "4": "00:56",
  "5": "00:51",
  "6": "00:51",
  "7": "00:46",
  "8": "00:46",
  "9": "00:41",
  "10": "00:41",
  "11": "00:41",
  "12": "00:41",
  "13": "00:41",
  "14": "00:41",
  "15": "00:41",
  "16": "00:41",
  "17": "00:41",
  "18": "00:41",
  "19": "00:41",
  "20": "00:41"
 },
 {
  "0": 3,
  "1": "01:01",
  "2": "01:01",
  "3": "00:56",
  "4": "00:56",
  "5": "00:51",
  "6": "00:51",
  "7": "00:46",
  "8": "00:46",
  "9": "00:41",
  "10": "00:41",
  "11": "00:41",
  "12": "00:41",
  "13": "00:41",
  "14": "00:41",
  "15": "00:41",
  "16": "00:41",
  "17": "00:41",
  "18": "00:41",
  "19": "00:41",
  "20": "00:41"
 },
 {
  "0": 4,
  "1": "01:02",
  "2": "01:02",
  "3": "00:57",
  "4": "00:57",
  "5": "00:52",
  "6": "00:52",
  "7": "00:47",
  "8": "00:47",
  "9": "00:42",
  "10": "00:42",
  "11": "00:42",
  "12": "00:42",
  "13": "00:42",
  "14": "00:42",
  "15": "00:42",
  "16": "00:42",
  "17": "00:42",
  "18": "00:42",
  "19": "00:42",
  "20": "00:42"
 },
 {
  "0": 5,
  "1": "01:02",
  "2": "01:02",
  "3": "00:57",
  "4": "00:57",
  "5": "00:52",
  "6": "00:52",
  "7": "00:47",
  "8": "00:47",
  "9": "00:42",
  "10": "00:42",
  "11": "00:42",
  "12": "00:42",
  "13": "00:42",
  "14": "00:42",
  "15": "00:42",
  "16": "00:42",
  "17": "00:42",
  "18": "00:42",
  "19": "00:42",
  "20": "00:42"
 },
 {
  "0": 6,
  "1": "01:03",
  "2": "01:03",
  "3": "00:58",
  "4": "00:58",
  "5": "00:53",
  "6": "00:53",
  "7": "00:48",
  "8": "00:48",
  "9": "00:43",
  "10": "00:43",
  "11": "00:43",
  "12": "00:43",
  "13": "00:43",
  "14": "00:43",
  "15": "00:43",
  "16": "00:43",
  "17": "00:43",
  "18": "00:43",
  "19": "00:43",
  "20": "00:43"
 },
 {
  "0": 7,
  "1": "01:03",
  "2": "01:03",
  "3": "00:58",
  "4": "00:58",
  "5": "00:53",
  "6": "00:53",
  "7": "00:48",
  "8": "00:48",
  "9": "00:43",
  "10": "00:43",
  "11": "00:43",
  "12": "00:43",
  "13": "00:43",
  "14": "00:43",
  "15": "00:43",
  "16": "00:43",
  "17": "00:43",
  "18": "00:43",
  "19": "00:43",
  "20": "00:43"
 },
 {
  "0": 8,
  "1": "01:04",
  "2": "01:04",
  "3": "00:59",
  "4": "00:59",
  "5": "00:54",
  "6": "00:54",
  "7": "00:49",
  "8": "00:49",
  "9": "00:44",
  "10": "00:44",
  "11": "00:44",
  "12": "00:44",
  "13": "00:44",
  "14": "00:44",
  "15": "00:44",
  "16": "00:44",
  "17": "00:44",
  "18": "00:44",
  "19": "00:44",
  "20": "00:44"
 },
 {
  "0": 9,
  "1": "01:04",
  "2": "01:04",
  "3": "00:59",
  "4": "00:59",
  "5": "00:54",
  "6": "00:54",
  "7": "00:49",
  "8": "00:49",
  "9": "00:44",
  "10": "00:44",
  "11": "00:44",
  "12": "00:44",
  "13": "00:44",
  "14": "00:44",
  "15": "00:44",
  "16": "00:44",
  "17": "00:44",
  "18": "00:44",
  "19": "00:44",
  "20": "00:44"
 },
 {
  "0": 10,
  "1": "01:05",
  "2": "01:05",
  "3": "01:00",
  "4": "01:00",
  "5": "00:55",
  "6": "00:55",
  "7": "00:50",
  "8": "00:50",
  "9": "00:45",
  "10": "00:45",
  "11": "00:45",
  "12": "00:45",
  "13": "00:45",
  "14": "00:45",
  "15": "00:45",
  "16": "00:45",
  "17": "00:45",
  "18": "00:45",
  "19": "00:45",
  "20": "00:45"
 },
 {
  "0": 11,
  "1": "01:05",
  "2": "01:05",
  "3": "01:00",
  "4": "01:00",
  "5": "00:55",
  "6": "00:55",
  "7": "00:50",
  "8": "00:50",
  "9": "00:45",
  "10": "00:45",
  "11": "00:45",
  "12": "00:45",
  "13": "00:45",
  "14": "00:45",
  "15": "00:45",
  "16": "00:45",
  "17": "00:45",
  "18": "00:45",
  "19": "00:45",
  "20": "00:45"
 },
 {
  "0": 12,
  "1": "01:06",
  "2": "01:06",
  "3": "01:01",
  "4": "01:01",
  "5": "00:56",
  "6": "00:56",
  "7": "00:51",
  "8": "00:51",
  "9": "00:46",
  "10": "00:46",
  "11": "00:46",
  "12": "00:46",
  "13": "00:46",
  "14": "00:46",
  "15": "00:46",
  "16": "00:46",
  "17": "00:46",
  "18": "00:46",
  "19": "00:46",
  "20": "00:46"
 },
 {
  "0": 13,
  "1": "01:06",
  "2": "01:06",
  "3": "01:01",
  "4": "01:01",
  "5": "00:56",
  "6": "00:56",
  "7": "00:51",
  "8": "00:51",
  "9": "00:46",
  "10": "00:46",
  "11": "00:46",
  "12": "00:46",
  "13": "00:46",
  "14": "00:46",
  "15": "00:46",
  "16": "00:46",
  "17": "00:46",
  "18": "00:46",
  "19": "00:46",
  "20": "00:46"
 },
 {
  "0": 14,
  "1": "01:07",
  "2": "01:07",
  "3": "01:02",
  "4": "01:02",
  "5": "00:57",
  "6": "00:57",
  "7": "00:52",
  "8": "00:52",
  "9": "00:47",
  "10": "00:47",
  "11": "00:47",
  "12": "00:47",
  "13": "00:47",
  "14": "00:47",
  "15": "00:47",
  "16": "00:47",
  "17": "00:47",
  "18": "00:47",
  "19": "00:47",
  "20": "00:47"
 },
 {
  "0": 15,
  "1": "01:07",
  "2": "01:07",
  "3": "01:02",
  "4": "01:02",
  "5": "00:57",
  "6": "00:57",
  "7": "00:52",
  "8": "00:52",
  "9": "00:47",
  "10": "00:47",
  "11": "00:47",
  "12": "00:47",
  "13": "00:47",
  "14": "00:47",
  "15": "00:47",
  "16": "00:47",
  "17": "00:47",
  "18": "00:47",
  "19": "00:47",
  "20": "00:47"
 },
 {
  "0": 16,
  "1": "01:08",
  "2": "01:08",
  "3": "01:03",
  "4": "01:03",
  "5": "00:58",
  "6": "00:58",
  "7": "00:53",
  "8": "00:53",
  "9": "00:48",
  "10": "00:48",
  "11": "00:48",
  "12": "00:48",
  "13": "00:48",
  "14": "00:48",
  "15": "00:48",
  "16": "00:48",
  "17": "00:48",
  "18": "00:48",
  "19": "00:48",
  "20": "00:48"
 },
 {
  "0": 17,
  "1": "01:08",
  "2": "01:08",
  "3": "01:03",
  "4": "01:03",
  "5": "00:58",
  "6": "00:58",
  "7": "00:53",
  "8": "00:53",
  "9": "00:48",
  "10": "00:48",
  "11": "00:48",
  "12": "00:48",
  "13": "00:48",
  "14": "00:48",
  "15": "00:48",
  "16": "00:48",
  "17": "00:48",
  "18": "00:48",
  "19": "00:48",
  "20": "00:48"
 },
 {
  "0": 18,
  "1": "01:09",
  "2": "01:09",
  "3": "01:04",
  "4": "01:04",
  "5": "00:59",
  "6": "00:59",
  "7": "00:54",
  "8": "00:54",
  "9": "00:49",
  "10": "00:49",
  "11": "00:49",
  "12": "00:49",
  "13": "00:49",
  "14": "00:49",
  "15": "00:49",
  "16": "00:49",
  "17": "00:49",
  "18": "00:49",
  "19": "00:49",
  "20": "00:49"
 },
 {
  "0": 19,
  "1": "01:09",
  "2": "01:09",
  "3": "01:04",
  "4": "01:04",
  "5": "00:59",
  "6": "00:59",
  "7": "00:54",
  "8": "00:54",
  "9": "00:49",
  "10": "00:49",
  "11": "00:49",
  "12": "00:49",
  "13": "00:49",
  "14": "00:49",
  "15": "00:49",
  "16": "00:49",
  "17": "00:49",
  "18": "00:49",
  "19": "00:49",
  "20": "00:49"
 },
 {
  "0": 20,
  "1": "01:10",
  "2": "01:10",
  "3": "01:05",
  "4": "01:05",
  "5": "01:00",
  "6": "01:00",
  "7": "00:55",
  "8": "00:55",
  "9": "00:50",
  "10": "00:50",
  "11": "00:50",
  "12": "00:50",
  "13": "00:50",
  "14": "00:50",
  "15": "00:50",
  "16": "00:50",
  "17": "00:50",
  "18": "00:50",
  "19": "00:50",
  "20": "00:50"
 },
 {
  "0": 21,
  "1": "01:10",
  "2": "01:10",
  "3": "01:05",
  "4": "01:05",
  "5": "01:00",
  "6": "01:00",
  "7": "00:55",
  "8": "00:55",
  "9": "00:50",
  "10": "00:50",
  "11": "00:50",
  "12": "00:50",
  "13": "00:50",
  "14": "00:50",
  "15": "00:50",
  "16": "00:50",
  "17": "00:50",
  "18": "00:50",
  "19": "00:50",
  "20": "00:50"
 },
 {
  "0": 22,
  "1": "01:11",
  "2": "01:11",
  "3": "01:06",
  "4": "01:06",
  "5": "01:01",
  "6": "01:01",
  "7": "00:56",
  "8": "00:56",
  "9": "00:51",
  "10": "00:51",
  "11": "00:51",
  "12": "00:51",
  "13": "00:51",
  "14": "00:51",
  "15": "00:51",
  "16": "00:51",
  "17": "00:51",
  "18": "00:51",
  "19": "00:51",
  "20": "00:51"
 },
 {
  "0": 23,
  "1": "01:11",
  "2": "01:11",
  "3": "01:06",
  "4": "01:06",
  "5": "01:01",
  "6": "01:01",
  "7": "00:56",
  "8": "00:56",
  "9": "00:51",
  "10": "00:51",
  "11": "00:51",
  "12": "00:51",
  "13": "00:51",
  "14": "00:51",
  "15": "00:51",
  "16": "00:51",
  "17": "00:51",
  "18": "00:51",
  "19": "00:51",
  "20": "00:51"
 },
 {
  "0": 24,
  "1": "01:12",
  "2": "01:12",
  "3": "01:07",
  "4": "01:07",
  "5": "01:02",
  "6": "01:02",
  "7": "00:57",
  "8": "00:57",
  "9": "00:52",
  "10": "00:52",
  "11": "00:52",
  "12": "00:52",
  "13": "00:52",
  "14": "00:52",
  "15": "00:52",
  "16": "00:52",
  "17": "00:52",
  "18": "00:52",
  "19": "00:52",
  "20": "00:52"
 },
 {
  "0": 25,
  "1": "01:12",
  "2": "01:12",
  "3": "01:07",
  "4": "01:07",
  "5": "01:02",
  "6": "01:02",
  "7": "00:57",
  "8": "00:57",
  "9": "00:52",
  "10": "00:52",
  "11": "00:52",
  "12": "00:52",
  "13": "00:52",
  "14": "00:52",
  "15": "00:52",
  "16": "00:52",
  "17": "00:52",
  "18": "00:52",
  "19": "00:52",
  "20": "00:52"
 },
 {
  "0": 26,
  "1": "01:13",
  "2": "01:13",
  "3": "01:08",
  "4": "01:08",
  "5": "01:03",
  "6": "01:03",
  "7": "00:58",
  "8": "00:58",
  "9": "00:53",
  "10": "00:53",
  "11": "00:53",
  "12": "00:53",
  "13": "00:53",
  "14": "00:53",
  "15": "00:53",
  "16": "00:53",
  "17": "00:53",
  "18": "00:53",
  "19": "00:53",
  "20": "00:53"
 },
 {
  "0": 27,
  "1": "01:13",
  "2": "01:13",
  "3": "01:08",
  "4": "01:08",
  "5": "01:03",
  "6": "01:03",
  "7": "00:58",
  "8": "00:58",
  "9": "00:53",
  "10": "00:53",
  "11": "00:53",
  "12": "00:53",
  "13": "00:53",
  "14": "00:53",
  "15": "00:53",
  "16": "00:53",
  "17": "00:53",
  "18": "00:53",
  "19": "00:53",
  "20": "00:53"
 },
 {
  "0": 28,
  "1": "01:14",
  "2": "01:14",
  "3": "01:09",
  "4": "01:09",
  "5": "01:04",
  "6": "01:04",
  "7": "00:59",
  "8": "00:59",
  "9": "00:54",
  "10": "00:54",
  "11": "00:54",
  "12": "00:54",
  "13": "00:54",
  "14": "00:54",
  "15": "00:54",
  "16": "00:54",
  "17": "00:54",
  "18": "00:54",
  "19": "00:54",
  "20": "00:54"
 },
 {
  "0": 29,
  "1": "01:14",
  "2": "01:14",
  "3": "01:09",
  "4": "01:09",
  "5": "01:04",
  "6": "01:04",
  "7": "00:59",
  "8": "00:59",
  "9": "00:54",
  "10": "00:54",
  "11": "00:54",
  "12": "00:54",
  "13": "00:54",
  "14": "00:54",
  "15": "00:54",
  "16": "00:54",
  "17": "00:54",
  "18": "00:54",
  "19": "00:54",
  "20": "00:54"
 },
 {
  "0": 30,
  "1": "01:15",
  "2": "01:15",
  "3": "01:10",
  "4": "01:10",
  "5": "01:05",
  "6": "01:05",
  "7": "01:00",
  "8": "01:00",
  "9": "00:55",
  "10": "00:55",
  "11": "00:55",
  "12": "00:55",
  "13": "00:55",
  "14": "00:55",
  "15": "00:55",
  "16": "00:55",
  "17": "00:55",
  "18": "00:55",
  "19": "00:55",
  "20": "00:55"
 },
 {
  "0": 31,
  "1": "01:15",
  "2": "01:15",
  "3": "01:10",
  "4": "01:10",
  "5": "01:05",
  "6": "01:05",
  "7": "01:00",
  "8": "01:00",
  "9": "00:55",
  "10": "00:55",
  "11": "00:55",
  "12": "00:55",
  "13": "00:55",
  "14": "00:55",
  "15": "00:55",
  "16": "00:55",
  "17": "00:55",
  "18": "00:55",
  "19": "00:55",
  "20": "00:55"
 },
 {
  "0": 32,
  "1": "01:16",
  "2": "01:16",
  "3": "01:11",
  "4": "01:11",
  "5": "01:06",
  "6": "01:06",
  "7": "01:01",
  "8": "01:01",
  "9": "00:56",
  "10": "00:56",
  "11": "00:56",
  "12": "00:56",
  "13": "00:56",
  "14": "00:56",
  "15": "00:56",
  "16": "00:56",
  "17": "00:56",
  "18": "00:56",
  "19": "00:56",
  "20": "00:56"
 },
 {
  "0": 33,
  "1": "01:16",
  "2": "01:16",
  "3": "01:11",
  "4": "01:11",
  "5": "01:06",
  "6": "01:06",
  "7": "01:01",
  "8": "01:01",
  "9": "00:56",
  "10": "00:56",
  "11": "00:56",
  "12": "00:56",
  "13": "00:56",
  "14": "00:56",
  "15": "00:56",
  "16": "00:56",
  "17": "00:56",
  "18": "00:56",
  "19": "00:56",
  "20": "00:56"
 },
 {
  "0": 34,
  "1": "01:17",
  "2": "01:17",
  "3": "01:12",
  "4": "01:12",
  "5": "01:07",
  "6": "01:07",
  "7": "01:02",
  "8": "01:02",
  "9": "00:57",
  "10": "00:57",
  "11": "00:57",
  "12": "00:57",
  "13": "00:57",
  "14": "00:57",
  "15": "00:57",
  "16": "00:57",
  "17": "00:57",
  "18": "00:57",
  "19": "00:57",
  "20": "00:57"
 },
 {
  "0": 35,
  "1": "01:17",
  "2": "01:17",
  "3": "01:12",
  "4": "01:12",
  "5": "01:07",
  "6": "01:07",
  "7": "01:02",
  "8": "01:02",
  "9": "00:57",
  "10": "00:57",
  "11": "00:57",
  "12": "00:57",
  "13": "00:57",
  "14": "00:57",
  "15": "00:57",
  "16": "00:57",
  "17": "00:57",
  "18": "00:57",
  "19": "00:57",
  "20": "00:57"
 },
 {
  "0": 36,
  "1": "01:18",
  "2": "01:18",
  "3": "01:13",
  "4": "01:13",
  "5": "01:08",
  "6": "01:08",
  "7": "01:03",
  "8": "01:03",
  "9": "00:58",
  "10": "00:58",
  "11": "00:58",
  "12": "00:58",
  "13": "00:58",
  "14": "00:58",
  "15": "00:58",
  "16": "00:58",
  "17": "00:58",
  "18": "00:58",
  "19": "00:58",
  "20": "00:58"
 },
 {
  "0": 37,
  "1": "01:18",
  "2": "01:18",
  "3": "01:13",
  "4": "01:13",
  "5": "01:08",
  "6": "01:08",
  "7": "01:03",
  "8": "01:03",
  "9": "00:58",
  "10": "00:58",
  "11": "00:58",
  "12": "00:58",
  "13": "00:58",
  "14": "00:58",
  "15": "00:58",
  "16": "00:58",
  "17": "00:58",
  "18": "00:58",
  "19": "00:58",
  "20": "00:58"
 },
 {
  "0": 38,
  "1": "01:19",
  "2": "01:19",
  "3": "01:14",
  "4": "01:14",
  "5": "01:09",
  "6": "01:09",
  "7": "01:04",
  "8": "01:04",
  "9": "00:59",
  "10": "00:59",
  "11": "00:59",
  "12": "00:59",
  "13": "00:59",
  "14": "00:59",
  "15": "00:59",
  "16": "00:59",
  "17": "00:59",
  "18": "00:59",
  "19": "00:59",
  "20": "00:59"
 },
 {
  "0": 39,
  "1": "01:19",
  "2": "01:19",
  "3": "01:14",
  "4": "01:14",
  "5": "01:09",
  "6": "01:09",
  "7": "01:04",
  "8": "01:04",
  "9": "00:59",
  "10": "00:59",
  "11": "00:59",
  "12": "00:59",
  "13": "00:59",
  "14": "00:59",
  "15": "00:59",
  "16": "00:59",
  "17": "00:59",
  "18": "00:59",
  "19": "00:59",
  "20": "00:59"
 },
 {
  "0": 40,
  "1": "01:20",
  "2": "01:20",
  "3": "01:15",
  "4": "01:15",
  "5": "01:10",
  "6": "01:10",
  "7": "01:05",
  "8": "01:05",
  "9": "01:00",
  "10": "01:00",
  "11": "01:00",
  "12": "01:00",
  "13": "01:00",
  "14": "01:00",
  "15": "01:00",
  "16": "01:00",
  "17": "01:00",
  "18": "01:00",
  "19": "01:00",
  "20": "01:00"
 },
 {
  "0": 41,
  "1": "01:20",
  "2": "01:20",
  "3": "01:15",
  "4": "01:15",
  "5": "01:10",
  "6": "01:10",
  "7": "01:05",
  "8": "01:05",
  "9": "01:00",
  "10": "01:00",
  "11": "01:00",
  "12": "01:00",
  "13": "01:00",
  "14": "01:00",
  "15": "01:00",
  "16": "01:00",
  "17": "01:00",
  "18": "01:00",
  "19": "01:00",
  "20": "01:00"
 },
 {
  "0": 42,
  "1": "01:21",
  "2": "01:21",
  "3": "01:16",
  "4": "01:16",
  "5": "01:11",
  "6": "01:11",
  "7": "01:06",
  "8": "01:06",
  "9": "01:01",
  "10": "01:01",
  "11": "01:01",
  "12": "01:01",
  "13": "01:01",
  "14": "01:01",
  "15": "01:01",
  "16": "01:01",
  "17": "01:01",
  "18": "01:01",
  "19": "01:01",
  "20": "01:01"
 },
 {
  "0": 43,
  "1": "01:21",
  "2": "01:21",
  "3": "01:16",
  "4": "01:16",
  "5": "01:11",
  "6": "01:11",
  "7": "01:06",
  "8": "01:06",
  "9": "01:01",
  "10": "01:01",
  "11": "01:01",
  "12": "01:01",
  "13": "01:01",
  "14": "01:01",
  "15": "01:01",
  "16": "01:01",
  "17": "01:01",
  "18": "01:01",
  "19": "01:01",
  "20": "01:01"
 },
 {
  "0": 44,
  "1": "01:22",
  "2": "01:22",
  "3": "01:17",
  "4": "01:17",
  "5": "01:12",
  "6": "01:12",
  "7": "01:07",
  "8": "01:07",
  "9": "01:02",
  "10": "01:02",
  "11": "01:02",
  "12": "01:02",
  "13": "01:02",
  "14": "01:02",
  "15": "01:02",
  "16": "01:02",
  "17": "01:02",
  "18": "01:02",
  "19": "01:02",
  "20": "01:02"
 },
 {
  "0": 45,
  "1": "01:22",
  "2": "01:22",
  "3": "01:17",
  "4": "01:17",
  "5": "01:12",
  "6": "01:12",
  "7": "01:07",
  "8": "01:07",
  "9": "01:02",
  "10": "01:02",
  "11": "01:02",
  "12": "01:02",
  "13": "01:02",
  "14": "01:02",
  "15": "01:02",
  "16": "01:02",
  "17": "01:02",
  "18": "01:02",
  "19": "01:02",
  "20": "01:02"
 },
 {
  "0": 46,
  "1": "01:23",
  "2": "01:23",
  "3": "01:18",
  "4": "01:18",
  "5": "01:13",
  "6": "01:13",
  "7": "01:08",
  "8": "01:08",
  "9": "01:03",
  "10": "01:03",
  "11": "01:03",
  "12": "01:03",
  "13": "01:03",
  "14": "01:03",
  "15": "01:03",
  "16": "01:03",
  "17": "01:03",
  "18": "01:03",
  "19": "01:03",
  "20": "01:03"
 },
 {
  "0": 47,
  "1": "01:23",
  "2": "01:23",
  "3": "01:18",
  "4": "01:18",
  "5": "01:13",
  "6": "01:13",
  "7": "01:08",
  "8": "01:08",
  "9": "01:03",
  "10": "01:03",
  "11": "01:03",
  "12": "01:03",
  "13": "01:03",
  "14": "01:03",
  "15": "01:03",
  "16": "01:03",
  "17": "01:03",
  "18": "01:03",
  "19": "01:03",
  "20": "01:03"
 },
 {
  "0": 48,
  "1": "01:24",
  "2": "01:24",
  "3": "01:19",
  "4": "01:19",
  "5": "01:14",
  "6": "01:14",
  "7": "01:09",
  "8": "01:09",
  "9": "01:04",
  "10": "01:04",
  "11": "01:04",
  "12": "01:04",
  "13": "01:04",
  "14": "01:04",
  "15": "01:04",
  "16": "01:04",
  "17": "01:04",
  "18": "01:04",
  "19": "01:04",
  "20": "01:04"
 },
 {
  "0": 49,
  "1": "01:24",
  "2": "01:24",
  "3": "01:19",
  "4": "01:19",
  "5": "01:14",
  "6": "01:14",
  "7": "01:09",
  "8": "01:09",
  "9": "01:04",
  "10": "01:04",
  "11": "01:04",
  "12": "01:04",
  "13": "01:04",
  "14": "01:04",
  "15": "01:04",
  "16": "01:04",
  "17": "01:04",
  "18": "01:04",
  "19": "01:04",
  "20": "01:04"
 },
 {
  "0": 50,
  "1": "01:25",
  "2": "01:25",
  "3": "01:20",
  "4": "01:20",
  "5": "01:15",
  "6": "01:15",
  "7": "01:10",
  "8": "01:10",
  "9": "01:05",
  "10": "01:05",
  "11": "01:05",
  "12": "01:05",
  "13": "01:05",
  "14": "01:05",
  "15": "01:05",
  "16": "01:05",
  "17": "01:05",
  "18": "01:05",
  "19": "01:05",
  "20": "01:05"
 },
 {
  "0": 51,
  "1": "01:25",
  "2": "01:25",
  "3": "01:20",
  "4": "01:20",
  "5": "01:15",
  "6": "01:15",
  "7": "01:10",
  "8": "01:10",
  "9": "01:05",
  "10": "01:05",
  "11": "01:05",
  "12": "01:05",
  "13": "01:05",
  "14": "01:05",
  "15": "01:05",
  "16": "01:05",
  "17": "01:05",
  "18": "01:05",
  "19": "01:05",
  "20": "01:05"
 },
 {
  "0": 52,
  "1": "01:26",
  "2": "01:26",
  "3": "01:21",
  "4": "01:21",
  "5": "01:16",
  "6": "01:16",
  "7": "01:11",
  "8": "01:11",
  "9": "01:06",
  "10": "01:06",
  "11": "01:06",
  "12": "01:06",
  "13": "01:06",
  "14": "01:06",
  "15": "01:06",
  "16": "01:06",
  "17": "01:06",
  "18": "01:06",
  "19": "01:06",
  "20": "01:06"
 },
 {
  "0": 53,
  "1": "01:26",
  "2": "01:26",
  "3": "01:21",
  "4": "01:21",
  "5": "01:16",
  "6": "01:16",
  "7": "01:11",
  "8": "01:11",
  "9": "01:06",
  "10": "01:06",
  "11": "01:06",
  "12": "01:06",
  "13": "01:06",
  "14": "01:06",
  "15": "01:06",
  "16": "01:06",
  "17": "01:06",
  "18": "01:06",
  "19": "01:06",
  "20": "01:06"
 },
 {
  "0": 54,
  "1": "01:27",
  "2": "01:27",
  "3": "01:22",
  "4": "01:22",
  "5": "01:17",
  "6": "01:17",
  "7": "01:12",
  "8": "01:12",
  "9": "01:07",
  "10": "01:07",
  "11": "01:07",
  "12": "01:07",
  "13": "01:07",
  "14": "01:07",
  "15": "01:07",
  "16": "01:07",
  "17": "01:07",
  "18": "01:07",
  "19": "01:07",
  "20": "01:07"
 },
 {
  "0": 55,
  "1": "01:27",
  "2": "01:27",
  "3": "01:22",
  "4": "01:22",
  "5": "01:17",
  "6": "01:17",
  "7": "01:12",
  "8": "01:12",
  "9": "01:07",
  "10": "01:07",
  "11": "01:07",
  "12": "01:07",
  "13": "01:07",
  "14": "01:07",
  "15": "01:07",
  "16": "01:07",
  "17": "01:07",
  "18": "01:07",
  "19": "01:07",
  "20": "01:07"
 },
 {
  "0": 56,
  "1": "01:28",
  "2": "01:28",
  "3": "01:23",
  "4": "01:23",
  "5": "01:18",
  "6": "01:18",
  "7": "01:13",
  "8": "01:13",
  "9": "01:08",
  "10": "01:08",
  "11": "01:08",
  "12": "01:08",
  "13": "01:08",
  "14": "01:08",
  "15": "01:08",
  "16": "01:08",
  "17": "01:08",
  "18": "01:08",
  "19": "01:08",
  "20": "01:08"
 },
 {
  "0": 57,
  "1": "01:28",
  "2": "01:28",
  "3": "01:23",
  "4": "01:23",
  "5": "01:18",
  "6": "01:18",
  "7": "01:13",
  "8": "01:13",
  "9": "01:08",
  "10": "01:08",
  "11": "01:08",
  "12": "01:08",
  "13": "01:08",
  "14": "01:08",
  "15": "01:08",
  "16": "01:08",
  "17": "01:08",
  "18": "01:08",
  "19": "01:08",
  "20": "01:08"
 },
 {
  "0": 58,
  "1": "01:29",
  "2": "01:29",
  "3": "01:24",
  "4": "01:24",
  "5": "01:19",
  "6": "01:19",
  "7": "01:14",
  "8": "01:14",
  "9": "01:09",
  "10": "01:09",
  "11": "01:09",
  "12": "01:09",
  "13": "01:09",
  "14": "01:09",
  "15": "01:09",
  "16": "01:09",
  "17": "01:09",
  "18": "01:09",
  "19": "01:09",
  "20": "01:09"
 },
 {
  "0": 59,
  "1": "01:29",
  "2": "01:29",
  "3": "01:24",
  "4": "01:24",
  "5": "01:19",
  "6": "01:19",
  "7": "01:14",
  "8": "01:14",
  "9": "01:09",
  "10": "01:09",
  "11": "01:09",
  "12": "01:09",
  "13": "01:09",
  "14": "01:09",
  "15": "01:09",
  "16": "01:09",
  "17": "01:09",
  "18": "01:09",
  "19": "01:09",
  "20": "01:09"
 },
 {
  "0": 60,
  "1": "01:30",
  "2": "01:30",
  "3": "01:25",
  "4": "01:25",
  "5": "01:20",
  "6": "01:20",
  "7": "01:15",
  "8": "01:15",
  "9": "01:10",
  "10": "01:10",
  "11": "01:10",
  "12": "01:10",
  "13": "01:10",
  "14": "01:10",
  "15": "01:10",
  "16": "01:10",
  "17": "01:10",
  "18": "01:10",
  "19": "01:10",
  "20": "01:10"
 },
 {
  "0": 61,
  "1": "01:33",
  "2": "01:33",
  "3": "01:28",
  "4": "01:28",
  "5": "01:23",
  "6": "01:23",
  "7": "01:18",
  "8": "01:18",
  "9": "01:13",
  "10": "01:13",
  "11": "01:13",
  "12": "01:13",
  "13": "01:13",
  "14": "01:13",
  "15": "01:13",
  "16": "01:13",
  "17": "01:13",
  "18": "01:13",
  "19": "01:13",
  "20": "01:13"
 },
 {
  "0": 62,
  "1": "01:37",
  "2": "01:37",
  "3": "01:32",
  "4": "01:32",
  "5": "01:26",
  "6": "01:26",
  "7": "01:22",
  "8": "01:22",
  "9": "01:16",
  "10": "01:16",
  "11": "01:16",
  "12": "01:16",
  "13": "01:16",
  "14": "01:16",
  "15": "01:16",
  "16": "01:16",
  "17": "01:16",
  "18": "01:16",
  "19": "01:16",
  "20": "01:16"
 },
 {
  "0": 63,
  "1": "01:40",
  "2": "01:40",
  "3": "01:35",
  "4": "01:35",
  "5": "01:30",
  "6": "01:30",
  "7": "01:25",
  "8": "01:25",
  "9": "01:20",
  "10": "01:20",
  "11": "01:20",
  "12": "01:20",
  "13": "01:20",
  "14": "01:20",
  "15": "01:20",
  "16": "01:20",
  "17": "01:20",
  "18": "01:20",
  "19": "01:20",
  "20": "01:20"
 },
 {
  "0": 64,
  "1": "01:43",
  "2": "01:43",
  "3": "01:38",
  "4": "01:38",
  "5": "01:33",
  "6": "01:33",
  "7": "01:28",
  "8": "01:28",
  "9": "01:23",
  "10": "01:23",
  "11": "01:23",
  "12": "01:23",
  "13": "01:23",
  "14": "01:23",
  "15": "01:23",
  "16": "01:23",
  "17": "01:23",
  "18": "01:23",
  "19": "01:23",
  "20": "01:23"
 },
 {
  "0": 65,
  "1": "01:46",
  "2": "01:46",
  "3": "01:41",
  "4": "01:41",
  "5": "01:36",
  "6": "01:36",
  "7": "01:31",
  "8": "01:31",
  "9": "01:26",
  "10": "01:26",
  "11": "01:26",
  "12": "01:26",
  "13": "01:26",
  "14": "01:26",
  "15": "01:26",
  "16": "01:26",
  "17": "01:26",
  "18": "01:26",
  "19": "01:26",
  "20": "01:26"
 },
 {
  "0": 66,
  "1": "01:49",
  "2": "01:49",
  "3": "01:45",
  "4": "01:45",
  "5": "01:39",
  "6": "01:39",
  "7": "01:35",
  "8": "01:35",
  "9": "01:30",
  "10": "01:30",
  "11": "01:30",
  "12": "01:30",
  "13": "01:30",
  "14": "01:30",
  "15": "01:30",
  "16": "01:30",
  "17": "01:30",
  "18": "01:30",
  "19": "01:30",
  "20": "01:30"
 },
 {
  "0": 67,
  "1": "01:53",
  "2": "01:53",
  "3": "01:48",
  "4": "01:48",
  "5": "01:43",
  "6": "01:43",
  "7": "01:38",
  "8": "01:38",
  "9": "01:33",
  "10": "01:33",
  "11": "01:33",
  "12": "01:33",
  "13": "01:33",
  "14": "01:33",
  "15": "01:33",
  "16": "01:33",
  "17": "01:33",
  "18": "01:33",
  "19": "01:33",
  "20": "01:33"
 },
 {
  "0": 68,
  "1": "01:56",
  "2": "01:56",
  "3": "01:51",
  "4": "01:51",
  "5": "01:46",
  "6": "01:46",
  "7": "01:41",
  "8": "01:41",
  "9": "01:36",
  "10": "01:36",
  "11": "01:36",
  "12": "01:36",
  "13": "01:36",
  "14": "01:36",
  "15": "01:36",
  "16": "01:36",
  "17": "01:36",
  "18": "01:36",
  "19": "01:36",
  "20": "01:36"
 },
 {
  "0": 69,
  "1": "01:59",
  "2": "01:59",
  "3": "01:54",
  "4": "01:54",
  "5": "01:49",
  "6": "01:49",
  "7": "01:44",
  "8": "01:44",
  "9": "01:39",
  "10": "01:39",
  "11": "01:39",
  "12": "01:39",
  "13": "01:39",
  "14": "01:39",
  "15": "01:39",
  "16": "01:39",
  "17": "01:39",
  "18": "01:39",
  "19": "01:39",
  "20": "01:39"
 },
 {
  "0": 70,
  "1": "02:02",
  "2": "02:02",
  "3": "01:58",
  "4": "01:58",
  "5": "01:52",
  "6": "01:52",
  "7": "01:47",
  "8": "01:47",
  "9": "01:42",
  "10": "01:42",
  "11": "01:42",
  "12": "01:42",
  "13": "01:42",
  "14": "01:42",
  "15": "01:42",
  "16": "01:42",
  "17": "01:42",
  "18": "01:42",
  "19": "01:42",
  "20": "01:42"
 },
 {
  "0": 71,
  "1": "02:06",
  "2": "02:06",
  "3": "02:01",
  "4": "02:01",
  "5": "01:56",
  "6": "01:56",
  "7": "01:51",
  "8": "01:51",
  "9": "01:46",
  "10": "01:46",
  "11": "01:46",
  "12": "01:46",
  "13": "01:46",
  "14": "01:46",
  "15": "01:46",
  "16": "01:46",
  "17": "01:46",
  "18": "01:46",
  "19": "01:46",
  "20": "01:46"
 },
 {
  "0": 72,
  "1": "02:09",
  "2": "02:09",
  "3": "02:04",
  "4": "02:04",
  "5": "01:59",
  "6": "01:59",
  "7": "01:54",
  "8": "01:54",
  "9": "01:49",
  "10": "01:49",
  "11": "01:49",
  "12": "01:49",
  "13": "01:49",
  "14": "01:49",
  "15": "01:49",
  "16": "01:49",
  "17": "01:49",
  "18": "01:49",
  "19": "01:49",
  "20": "01:49"
 },
 {
  "0": 73,
  "1": "02:12",
  "2": "02:12",
  "3": "02:07",
  "4": "02:07",
  "5": "02:02",
  "6": "02:02",
  "7": "01:57",
  "8": "01:57",
  "9": "01:52",
  "10": "01:52",
  "11": "01:52",
  "12": "01:52",
  "13": "01:52",
  "14": "01:52",
  "15": "01:52",
  "16": "01:52",
  "17": "01:52",
  "18": "01:52",
  "19": "01:52",
  "20": "01:52"
 },
 {
  "0": 74,
  "1": "02:15",
  "2": "02:15",
  "3": "02:10",
  "4": "02:10",
  "5": "02:06",
  "6": "02:06",
  "7": "02:00",
  "8": "02:00",
  "9": "01:56",
  "10": "01:56",
  "11": "01:56",
  "12": "01:56",
  "13": "01:56",
  "14": "01:56",
  "15": "01:56",
  "16": "01:56",
  "17": "01:56",
  "18": "01:56",
  "19": "01:56",
  "20": "01:56"
 },
 {
  "0": 75,
  "1": "02:19",
  "2": "02:19",
  "3": "02:14",
  "4": "02:14",
  "5": "02:09",
  "6": "02:09",
  "7": "02:04",
  "8": "02:04",
  "9": "01:59",
  "10": "01:59",
  "11": "01:59",
  "12": "01:59",
  "13": "01:59",
  "14": "01:59",
  "15": "01:59",
  "16": "01:59",
  "17": "01:59",
  "18": "01:59",
  "19": "01:59",
  "20": "01:59"
 },
 {
  "0": 76,
  "1": "02:22",
  "2": "02:22",
  "3": "02:17",
  "4": "02:17",
  "5": "02:12",
  "6": "02:12",
  "7": "02:07",
  "8": "02:07",
  "9": "02:02",
  "10": "02:02",
  "11": "02:02",
  "12": "02:02",
  "13": "02:02",
  "14": "02:02",
  "15": "02:02",
  "16": "02:02",
  "17": "02:02",
  "18": "02:02",
  "19": "02:02",
  "20": "02:02"
 },
 {
  "0": 77,
  "1": "02:25",
  "2": "02:25",
  "3": "02:20",
  "4": "02:20",
  "5": "02:15",
  "6": "02:15",
  "7": "02:10",
  "8": "02:10",
  "9": "02:05",
  "10": "02:05",
  "11": "02:05",
  "12": "02:05",
  "13": "02:05",
  "14": "02:05",
  "15": "02:05",
  "16": "02:05",
  "17": "02:05",
  "18": "02:05",
  "19": "02:05",
  "20": "02:05"
 },
 {
  "0": 78,
  "1": "02:29",
  "2": "02:29",
  "3": "02:23",
  "4": "02:23",
  "5": "02:18",
  "6": "02:18",
  "7": "02:13",
  "8": "02:13",
  "9": "02:08",
  "10": "02:08",
  "11": "02:08",
  "12": "02:08",
  "13": "02:08",
  "14": "02:08",
  "15": "02:08",
  "16": "02:08",
  "17": "02:08",
  "18": "02:08",
  "19": "02:08",
  "20": "02:08"
 },
 {
  "0": 79,
  "1": "02:32",
  "2": "02:32",
  "3": "02:27",
  "4": "02:27",
  "5": "02:22",
  "6": "02:22",
  "7": "02:17",
  "8": "02:17",
  "9": "02:12",
  "10": "02:12",
  "11": "02:12",
  "12": "02:12",
  "13": "02:12",
  "14": "02:12",
  "15": "02:12",
  "16": "02:12",
  "17": "02:12",
  "18": "02:12",
  "19": "02:12",
  "20": "02:12"
 },
 {
  "0": 80,
  "1": "02:35",
  "2": "02:35",
  "3": "02:30",
  "4": "02:30",
  "5": "02:25",
  "6": "02:25",
  "7": "02:20",
  "8": "02:20",
  "9": "02:15",
  "10": "02:15",
  "11": "02:15",
  "12": "02:15",
  "13": "02:15",
  "14": "02:15",
  "15": "02:15",
  "16": "02:15",
  "17": "02:15",
  "18": "02:15",
  "19": "02:15",
  "20": "02:15"
 },
 {
  "0": 81,
  "1": "02:38",
  "2": "02:38",
  "3": "02:33",
  "4": "02:33",
  "5": "02:28",
  "6": "02:28",
  "7": "02:23",
  "8": "02:23",
  "9": "02:18",
  "10": "02:18",
  "11": "02:18",
  "12": "02:18",
  "13": "02:18",
  "14": "02:18",
  "15": "02:18",
  "16": "02:18",
  "17": "02:18",
  "18": "02:18",
  "19": "02:18",
  "20": "02:18"
 },
 {
  "0": 82,
  "1": "02:41",
  "2": "02:41",
  "3": "02:37",
  "4": "02:37",
  "5": "02:31",
  "6": "02:31",
  "7": "02:27",
  "8": "02:27",
  "9": "02:22",
  "10": "02:22",
  "11": "02:22",
  "12": "02:22",
  "13": "02:22",
  "14": "02:22",
  "15": "02:22",
  "16": "02:22",
  "17": "02:22",
  "18": "02:22",
  "19": "02:22",
  "20": "02:22"
 },
 {
  "0": 83,
  "1": "02:45",
  "2": "02:45",
  "3": "02:40",
  "4": "02:40",
  "5": "02:35",
  "6": "02:35",
  "7": "02:30",
  "8": "02:30",
  "9": "02:25",
  "10": "02:25",
  "11": "02:25",
  "12": "02:25",
  "13": "02:25",
  "14": "02:25",
  "15": "02:25",
  "16": "02:25",
  "17": "02:25",
  "18": "02:25",
  "19": "02:25",
  "20": "02:25"
 },
 {
  "0": 84,
  "1": "02:48",
  "2": "02:48",
  "3": "02:43",
  "4": "02:43",
  "5": "02:38",
  "6": "02:38",
  "7": "02:33",
  "8": "02:33",
  "9": "02:28",
  "10": "02:28",
  "11": "02:28",
  "12": "02:28",
  "13": "02:28",
  "14": "02:28",
  "15": "02:28",
  "16": "02:28",
  "17": "02:28",
  "18": "02:28",
  "19": "02:28",
  "20": "02:28"
 },
 {
  "0": 85,
  "1": "02:51",
  "2": "02:51",
  "3": "02:46",
  "4": "02:46",
  "5": "02:41",
  "6": "02:41",
  "7": "02:36",
  "8": "02:36",
  "9": "02:31",
  "10": "02:31",
  "11": "02:31",
  "12": "02:31",
  "13": "02:31",
  "14": "02:31",
  "15": "02:31",
  "16": "02:31",
  "17": "02:31",
  "18": "02:31",
  "19": "02:31",
  "20": "02:31"
 },
 {
  "0": 86,
  "1": "02:55",
  "2": "02:55",
  "3": "02:50",
  "4": "02:50",
  "5": "02:45",
  "6": "02:45",
  "7": "02:40",
  "8": "02:40",
  "9": "02:35",
  "10": "02:35",
  "11": "02:35",
  "12": "02:35",
  "13": "02:35",
  "14": "02:35",
  "15": "02:35",
  "16": "02:35",
  "17": "02:35",
  "18": "02:35",
  "19": "02:35",
  "20": "02:35"
 },
 {
  "0": 87,
  "1": "02:58",
  "2": "02:58",
  "3": "02:53",
  "4": "02:53",
  "5": "02:48",
  "6": "02:48",
  "7": "02:43",
  "8": "02:43",
  "9": "02:38",
  "10": "02:38",
  "11": "02:38",
  "12": "02:38",
  "13": "02:38",
  "14": "02:38",
  "15": "02:38",
  "16": "02:38",
  "17": "02:38",
  "18": "02:38",
  "19": "02:38",
  "20": "02:38"
 },
 {
  "0": 88,
  "1": "03:01",
  "2": "03:01",
  "3": "02:56",
  "4": "02:56",
  "5": "02:51",
  "6": "02:51",
  "7": "02:46",
  "8": "02:46",
  "9": "02:41",
  "10": "02:41",
  "11": "02:41",
  "12": "02:41",
  "13": "02:41",
  "14": "02:41",
  "15": "02:41",
  "16": "02:41",
  "17": "02:41",
  "18": "02:41",
  "19": "02:41",
  "20": "02:41"
 },
 {
  "0": 89,
  "1": "03:04",
  "2": "03:04",
  "3": "02:59",
  "4": "02:59",
  "5": "02:54",
  "6": "02:54",
  "7": "02:49",
  "8": "02:49",
  "9": "02:44",
  "10": "02:44",
  "11": "02:44",
  "12": "02:44",
  "13": "02:44",
  "14": "02:44",
  "15": "02:44",
  "16": "02:44",
  "17": "02:44",
  "18": "02:44",
  "19": "02:44",
  "20": "02:44"
 },
 {
  "0": 90,
  "1": "03:08",
  "2": "03:08",
  "3": "03:03",
  "4": "03:03",
  "5": "02:58",
  "6": "02:58",
  "7": "02:53",
  "8": "02:53",
  "9": "02:47",
  "10": "02:47",
  "11": "02:47",
  "12": "02:47",
  "13": "02:47",
  "14": "02:47",
  "15": "02:47",
  "16": "02:47",
  "17": "02:47",
  "18": "02:47",
  "19": "02:47",
  "20": "02:47"
 },
 {
  "0": 91,
  "1": "03:11",
  "2": "03:11",
  "3": "03:06",
  "4": "03:06",
  "5": "03:01",
  "6": "03:01",
  "7": "02:56",
  "8": "02:56",
  "9": "02:51",
  "10": "02:51",
  "11": "02:51",
  "12": "02:51",
  "13": "02:51",
  "14": "02:51",
  "15": "02:51",
  "16": "02:51",
  "17": "02:51",
  "18": "02:51",
  "19": "02:51",
  "20": "02:51"
 },
 {
  "0": 92,
  "1": "03:14",
  "2": "03:14",
  "3": "03:09",
  "4": "03:09",
  "5": "03:04",
  "6": "03:04",
  "7": "02:59",
  "8": "02:59",
  "9": "02:54",
  "10": "02:54",
  "11": "02:54",
  "12": "02:54",
  "13": "02:54",
  "14": "02:54",
  "15": "02:54",
  "16": "02:54",
  "17": "02:54",
  "18": "02:54",
  "19": "02:54",
  "20": "02:54"
 },
 {
  "0": 93,
  "1": "03:17",
  "2": "03:17",
  "3": "03:12",
  "4": "03:12",
  "5": "03:07",
  "6": "03:07",
  "7": "03:02",
  "8": "03:02",
  "9": "02:57",
  "10": "02:57",
  "11": "02:57",
  "12": "02:57",
  "13": "02:57",
  "14": "02:57",
  "15": "02:57",
  "16": "02:57",
  "17": "02:57",
  "18": "02:57",
  "19": "02:57",
  "20": "02:57"
 },
 {
  "0": 94,
  "1": "03:21",
  "2": "03:21",
  "3": "03:16",
  "4": "03:16",
  "5": "03:11",
  "6": "03:11",
  "7": "03:06",
  "8": "03:06",
  "9": "03:01",
  "10": "03:01",
  "11": "03:01",
  "12": "03:01",
  "13": "03:01",
  "14": "03:01",
  "15": "03:01",
  "16": "03:01",
  "17": "03:01",
  "18": "03:01",
  "19": "03:01",
  "20": "03:01"
 },
 {
  "0": 95,
  "1": "03:24",
  "2": "03:24",
  "3": "03:19",
  "4": "03:19",
  "5": "03:14",
  "6": "03:14",
  "7": "03:09",
  "8": "03:09",
  "9": "03:04",
  "10": "03:04",
  "11": "03:04",
  "12": "03:04",
  "13": "03:04",
  "14": "03:04",
  "15": "03:04",
  "16": "03:04",
  "17": "03:04",
  "18": "03:04",
  "19": "03:04",
  "20": "03:04"
 },
 {
  "0": 96,
  "1": "03:27",
  "2": "03:27",
  "3": "03:22",
  "4": "03:22",
  "5": "03:17",
  "6": "03:17",
  "7": "03:12",
  "8": "03:12",
  "9": "03:07",
  "10": "03:07",
  "11": "03:07",
  "12": "03:07",
  "13": "03:07",
  "14": "03:07",
  "15": "03:07",
  "16": "03:07",
  "17": "03:07",
  "18": "03:07",
  "19": "03:07",
  "20": "03:07"
 },
 {
  "0": 97,
  "1": "03:30",
  "2": "03:30",
  "3": "03:25",
  "4": "03:25",
  "5": "03:20",
  "6": "03:20",
  "7": "03:15",
  "8": "03:15",
  "9": "03:10",
  "10": "03:10",
  "11": "03:10",
  "12": "03:10",
  "13": "03:10",
  "14": "03:10",
  "15": "03:10",
  "16": "03:10",
  "17": "03:10",
  "18": "03:10",
  "19": "03:10",
  "20": "03:10"
 },
 {
  "0": 98,
  "1": "03:34",
  "2": "03:34",
  "3": "03:29",
  "4": "03:29",
  "5": "03:24",
  "6": "03:24",
  "7": "03:19",
  "8": "03:19",
  "9": "03:14",
  "10": "03:14",
  "11": "03:14",
  "12": "03:14",
  "13": "03:14",
  "14": "03:14",
  "15": "03:14",
  "16": "03:14",
  "17": "03:14",
  "18": "03:14",
  "19": "03:14",
  "20": "03:14"
 },
 {
  "0": 99,
  "1": "03:37",
  "2": "03:37",
  "3": "03:32",
  "4": "03:32",
  "5": "03:27",
  "6": "03:27",
  "7": "03:22",
  "8": "03:22",
  "9": "03:17",
  "10": "03:17",
  "11": "03:17",
  "12": "03:17",
  "13": "03:17",
  "14": "03:17",
  "15": "03:17",
  "16": "03:17",
  "17": "03:17",
  "18": "03:17",
  "19": "03:17",
  "20": "03:17"
 },
 {
  "0": 100,
  "1": "03:40",
  "2": "03:40",
  "3": "03:35",
  "4": "03:35",
  "5": "03:30",
  "6": "03:30",
  "7": "03:25",
  "8": "03:25",
  "9": "03:20",
  "10": "03:20",
  "11": "03:20",
  "12": "03:20",
  "13": "03:20",
  "14": "03:20",
  "15": "03:20",
  "16": "03:20",
  "17": "03:20",
  "18": "03:20",
  "19": "03:20",
  "20": "03:20"
 },
 {
  "0": "Points",
  "1": "MF",
  "3": "MF",
  "5": "MF",
  "7": "MF",
  "9": "MF",
  "11": "MF",
  "13": "MF",
  "15": "MF",
  "17": "MF",
  "19": "MF"
 },
 {
  "1": "Plank(PLK)(maintainproperstraightlineposition(Time:minutesandseconds))"
 },
 {
  "1": "17-21",
  "3": "22-26",
  "5": "27-31",
  "7": "32-36",
  "9": "37-41",
  "11": "42-46",
  "13": "47-51",
  "15": "52-56",
  "17": "57-61",
  "19": "Over62"
 }
],
"Table6":[
 {
  "0": 0,
  "1": "24:00",
  "2": "25:22",
  "3": "24:00",
  "4": "25:15",
  "5": "24:00",
  "6": "25:13",
  "7": "24:00",
  "8": "25:19",
  "9": "24:11",
  "10": "25:23",
  "11": "24:32",
  "12": "25:42",
  "13": "24:55",
  "14": "26:00",
  "15": "25:20",
  "16": "26:24",
  "17": "25:36",
  "18": "26:48",
  "19": "25:36",
  "20": "27:00"
 },
 {
  "0": 1,
  "1": "23:58",
  "2": "25:20",
  "3": "23:58",
  "4": "25:13",
  "5": "23:58",
  "6": "25:11",
  "7": "23:58",
  "8": "25:17",
  "9": "24:09",
  "10": "25:21",
  "11": "24:30",
  "12": "25:40",
  "13": "24:53",
  "14": "25:58",
  "15": "25:18",
  "16": "26:22",
  "17": "25:34",
  "18": "26:46",
  "19": "25:34",
  "20": "26:58"
 },
 {
  "0": 2,
  "1": "23:56",
  "2": "25:18",
  "3": "23:56",
  "4": "25:11",
  "5": "23:56",
  "6": "25:09",
  "7": "23:56",
  "8": "25:15",
  "9": "24:07",
  "10": "25:19",
  "11": "24:28",
  "12": "25:38",
  "13": "24:51",
  "14": "25:56",
  "15": "25:16",
  "16": "26:20",
  "17": "25:32",
  "18": "26:44",
  "19": "25:32",
  "20": "26:56"
 },
 {
  "0": 3,
  "1": "23:54",
  "2": "25:16",
  "3": "23:54",
  "4": "25:09",
  "5": "23:54",
  "6": "25:07",
  "7": "23:54",
  "8": "25:13",
  "9": "24:05",
  "10": "25:17",
  "11": "24:26",
  "12": "25:36",
  "13": "24:49",
  "14": "25:54",
  "15": "25:14",
  "16": "26:18",
  "17": "25:30",
  "18": "26:42",
  "19": "25:30",
  "20": "26:54"
 },
 {
  "0": 4,
  "1": "23:52",
  "2": "25:14",
  "3": "23:52",
  "4": "25:07",
  "5": "23:52",
  "6": "25:05",
  "7": "23:52",
  "8": "25:11",
  "9": "24:03",
  "10": "25:15",
  "11": "24:24",
  "12": "25:34",
  "13": "24:47",
  "14": "25:52",
  "15": "25:12",
  "16": "26:16",
  "17": "25:28",
  "18": "26:40",
  "19": "25:28",
  "20": "26:52"
 },
 {
  "0": 5,
  "1": "23:50",
  "2": "25:12",
  "3": "23:50",
  "4": "25:05",
  "5": "23:50",
  "6": "25:03",
  "7": "23:50",
  "8": "25:09",
  "9": "24:01",
  "10": "25:13",
  "11": "24:22",
  "12": "25:32",
  "13": "24:45",
  "14": "25:50",
  "15": "25:10",
  "16": "26:14",
  "17": "25:26",
  "18": "26:38",
  "19": "25:26",
  "20": "26:50"
 },
 {
  "0": 6,
  "1": "23:48",
  "2": "25:10",
  "3": "23:48",
  "4": "25:03",
  "5": "23:48",
  "6": "25:01",
  "7": "23:48",
  "8": "25:07",
  "9": "23:59",
  "10": "25:11",
  "11": "24:20",
  "12": "25:30",
  "13": "24:43",
  "14": "25:48",
  "15": "25:08",
  "16": "26:12",
  "17": "25:24",
  "18": "26:36",
  "19": "25:24",
  "20": "26:48"
 },
 {
  "0": 7,
  "1": "23:46",
  "2": "25:08",
  "3": "23:46",
  "4": "25:01",
  "5": "23:46",
  "6": "24:59",
  "7": "23:46",
  "8": "25:05",
  "9": "23:57",
  "10": "25:09",
  "11": "24:18",
  "12": "25:28",
  "13": "24:41",
  "14": "25:46",
  "15": "25:06",
  "16": "26:10",
  "17": "25:22",
  "18": "26:34",
  "19": "25:22",
  "20": "26:46"
 },
 {
  "0": 8,
  "1": "23:44",
  "2": "25:06",
  "3": "23:44",
  "4": "24:59",
  "5": "23:44",
  "6": "24:57",
  "7": "23:44",
  "8": "25:03",
  "9": "23:55",
  "10": "25:07",
  "11": "24:16",
  "12": "25:26",
  "13": "24:39",
  "14": "25:44",
  "15": "25:04",
  "16": "26:08",
  "17": "25:20",
  "18": "26:32",
  "19": "25:20",
  "20": "26:44"
 },
 {
  "0": 9,
  "1": "23:42",
  "2": "25:04",
  "3": "23:42",
  "4": "24:57",
  "5": "23:42",
  "6": "24:55",
  "7": "23:42",
  "8": "25:01",
  "9": "23:53",
  "10": "25:05",
  "11": "24:14",
  "12": "25:24",
  "13": "24:37",
  "14": "25:42",
  "15": "25:02",
  "16": "26:06",
  "17": "25:18",
  "18": "26:30",
  "19": "25:18",
  "20": "26:42"
 },
 {
  "0": 10,
  "1": "23:40",
  "2": "25:02",
  "3": "23:40",
  "4": "24:55",
  "5": "23:40",
  "6": "24:53",
  "7": "23:40",
  "8": "24:59",
  "9": "23:51",
  "10": "25:03",
  "11": "24:12",
  "12": "25:22",
  "13": "24:35",
  "14": "25:40",
  "15": "25:00",
  "16": "26:04",
  "17": "25:16",
  "18": "26:28",
  "19": "25:16",
  "20": "26:40"
 },
 {
  "0": 11,
  "1": "23:38",
  "2": "25:00",
  "3": "23:38",
  "4": "24:53",
  "5": "23:38",
  "6": "24:51",
  "7": "23:38",
  "8": "24:57",
  "9": "23:49",
  "10": "25:01",
  "11": "24:10",
  "12": "25:20",
  "13": "24:33",
  "14": "25:38",
  "15": "24:58",
  "16": "26:02",
  "17": "25:14",
  "18": "26:26",
  "19": "25:14",
  "20": "26:38"
 },
 {
  "0": 12,
  "1": "23:36",
  "2": "24:58",
  "3": "23:36",
  "4": "24:51",
  "5": "23:36",
  "6": "24:49",
  "7": "23:36",
  "8": "24:55",
  "9": "23:47",
  "10": "24:59",
  "11": "24:08",
  "12": "25:18",
  "13": "24:31",
  "14": "25:36",
  "15": "24:56",
  "16": "26:00",
  "17": "25:12",
  "18": "26:24",
  "19": "25:12",
  "20": "26:36"
 },
 {
  "0": 13,
  "1": "23:34",
  "2": "24:56",
  "3": "23:34",
  "4": "24:49",
  "5": "23:34",
  "6": "24:47",
  "7": "23:34",
  "8": "24:53",
  "9": "23:45",
  "10": "24:57",
  "11": "24:06",
  "12": "25:16",
  "13": "24:29",
  "14": "25:34",
  "15": "24:54",
  "16": "25:58",
  "17": "25:10",
  "18": "26:22",
  "19": "25:10",
  "20": "26:34"
 },
 {
  "0": 14,
  "1": "23:32",
  "2": "24:54",
  "3": "23:32",
  "4": "24:47",
  "5": "23:32",
  "6": "24:45",
  "7": "23:32",
  "8": "24:51",
  "9": "23:43",
  "10": "24:55",
  "11": "24:04",
  "12": "25:14",
  "13": "24:27",
  "14": "25:32",
  "15": "24:52",
  "16": "25:56",
  "17": "25:08",
  "18": "26:20",
  "19": "25:08",
  "20": "26:32"
 },
 {
  "0": 15,
  "1": "23:30",
  "2": "24:52",
  "3": "23:30",
  "4": "24:45",
  "5": "23:30",
  "6": "24:43",
  "7": "23:30",
  "8": "24:49",
  "9": "23:41",
  "10": "24:53",
  "11": "24:02",
  "12": "25:12",
  "13": "24:25",
  "14": "25:30",
  "15": "24:50",
  "16": "25:54",
  "17": "25:06",
  "18": "26:18",
  "19": "25:06",
  "20": "26:30"
 },
 {
  "0": 16,
  "1": "23:28",
  "2": "24:50",
  "3": "23:28",
  "4": "24:43",
  "5": "23:28",
  "6": "24:41",
  "7": "23:28",
  "8": "24:47",
  "9": "23:39",
  "10": "24:51",
  "11": "24:00",
  "12": "25:10",
  "13": "24:23",
  "14": "25:28",
  "15": "24:48",
  "16": "25:52",
  "17": "25:04",
  "18": "26:16",
  "19": "25:04",
  "20": "26:28"
 },
 {
  "0": 17,
  "1": "23:26",
  "2": "24:48",
  "3": "23:26",
  "4": "24:41",
  "5": "23:26",
  "6": "24:39",
  "7": "23:26",
  "8": "24:45",
  "9": "23:37",
  "10": "24:49",
  "11": "23:58",
  "12": "25:08",
  "13": "24:21",
  "14": "25:26",
  "15": "24:46",
  "16": "25:50",
  "17": "25:02",
  "18": "26:14",
  "19": "25:02",
  "20": "26:26"
 },
 {
  "0": 18,
  "1": "23:24",
  "2": "24:46",
  "3": "23:24",
  "4": "24:39",
  "5": "23:24",
  "6": "24:37",
  "7": "23:24",
  "8": "24:43",
  "9": "23:35",
  "10": "24:47",
  "11": "23:56",
  "12": "25:06",
  "13": "24:19",
  "14": "25:24",
  "15": "24:44",
  "16": "25:48",
  "17": "25:00",
  "18": "26:12",
  "19": "25:00",
  "20": "26:24"
 },
 {
  "0": 19,
  "1": "23:22",
  "2": "24:44",
  "3": "23:22",
  "4": "24:37",
  "5": "23:22",
  "6": "24:35",
  "7": "23:22",
  "8": "24:41",
  "9": "23:33",
  "10": "24:45",
  "11": "23:54",
  "12": "25:04",
  "13": "24:17",
  "14": "25:22",
  "15": "24:42",
  "16": "25:46",
  "17": "24:58",
  "18": "26:10",
  "19": "24:58",
  "20": "26:22"
 },
 {
  "0": 20,
  "1": "23:20",
  "2": "24:42",
  "3": "23:20",
  "4": "24:35",
  "5": "23:20",
  "6": "24:33",
  "7": "23:20",
  "8": "24:39",
  "9": "23:31",
  "10": "24:43",
  "11": "23:52",
  "12": "25:02",
  "13": "24:15",
  "14": "25:20",
  "15": "24:40",
  "16": "25:44",
  "17": "24:56",
  "18": "26:08",
  "19": "24:56",
  "20": "26:20"
 },
 {
  "0": 21,
  "1": "23:18",
  "2": "24:40",
  "3": "23:18",
  "4": "24:33",
  "5": "23:18",
  "6": "24:31",
  "7": "23:18",
  "8": "24:37",
  "9": "23:29",
  "10": "24:41",
  "11": "23:50",
  "12": "25:00",
  "13": "24:13",
  "14": "25:18",
  "15": "24:38",
  "16": "25:42",
  "17": "24:54",
  "18": "26:06",
  "19": "24:54",
  "20": "26:18"
 },
 {
  "0": 22,
  "1": "23:16",
  "2": "24:38",
  "3": "23:16",
  "4": "24:31",
  "5": "23:16",
  "6": "24:29",
  "7": "23:16",
  "8": "24:35",
  "9": "23:27",
  "10": "24:39",
  "11": "23:48",
  "12": "24:58",
  "13": "24:11",
  "14": "25:16",
  "15": "24:36",
  "16": "25:40",
  "17": "24:52",
  "18": "26:04",
  "19": "24:52",
  "20": "26:16"
 },
 {
  "0": 23,
  "1": "23:14",
  "2": "24:36",
  "3": "23:14",
  "4": "24:29",
  "5": "23:14",
  "6": "24:27",
  "7": "23:14",
  "8": "24:33",
  "9": "23:25",
  "10": "24:37",
  "11": "23:46",
  "12": "24:56",
  "13": "24:09",
  "14": "25:14",
  "15": "24:34",
  "16": "25:38",
  "17": "24:50",
  "18": "26:02",
  "19": "24:50",
  "20": "26:14"
 },
 {
  "0": 24,
  "1": "23:12",
  "2": "24:34",
  "3": "23:12",
  "4": "24:27",
  "5": "23:12",
  "6": "24:25",
  "7": "23:12",
  "8": "24:31",
  "9": "23:23",
  "10": "24:35",
  "11": "23:44",
  "12": "24:54",
  "13": "24:07",
  "14": "25:12",
  "15": "24:32",
  "16": "25:36",
  "17": "24:48",
  "18": "26:00",
  "19": "24:48",
  "20": "26:12"
 },
 {
  "0": 25,
  "1": "23:10",
  "2": "24:32",
  "3": "23:10",
  "4": "24:25",
  "5": "23:10",
  "6": "24:23",
  "7": "23:10",
  "8": "24:29",
  "9": "23:21",
  "10": "24:33",
  "11": "23:42",
  "12": "24:52",
  "13": "24:05",
  "14": "25:10",
  "15": "24:30",
  "16": "25:34",
  "17": "24:46",
  "18": "25:58",
  "19": "24:46",
  "20": "26:10"
 },
 {
  "0": 26,
  "1": "23:08",
  "2": "24:30",
  "3": "23:08",
  "4": "24:23",
  "5": "23:08",
  "6": "24:21",
  "7": "23:08",
  "8": "24:27",
  "9": "23:19",
  "10": "24:31",
  "11": "23:40",
  "12": "24:50",
  "13": "24:03",
  "14": "25:08",
  "15": "24:28",
  "16": "25:32",
  "17": "24:44",
  "18": "25:56",
  "19": "24:44",
  "20": "26:08"
 },
 {
  "0": 27,
  "1": "23:06",
  "2": "24:28",
  "3": "23:06",
  "4": "24:21",
  "5": "23:06",
  "6": "24:19",
  "7": "23:06",
  "8": "24:25",
  "9": "23:17",
  "10": "24:29",
  "11": "23:38",
  "12": "24:48",
  "13": "24:01",
  "14": "25:06",
  "15": "24:26",
  "16": "25:30",
  "17": "24:42",
  "18": "25:54",
  "19": "24:42",
  "20": "26:06"
 },
 {
  "0": 28,
  "1": "23:04",
  "2": "24:26",
  "3": "23:04",
  "4": "24:19",
  "5": "23:04",
  "6": "24:17",
  "7": "23:04",
  "8": "24:23",
  "9": "23:15",
  "10": "24:27",
  "11": "23:36",
  "12": "24:46",
  "13": "23:59",
  "14": "25:04",
  "15": "24:24",
  "16": "25:28",
  "17": "24:40",
  "18": "25:52",
  "19": "24:40",
  "20": "26:04"
 },
 {
  "0": 29,
  "1": "23:02",
  "2": "24:24",
  "3": "23:02",
  "4": "24:17",
  "5": "23:02",
  "6": "24:15",
  "7": "23:02",
  "8": "24:21",
  "9": "23:13",
  "10": "24:25",
  "11": "23:34",
  "12": "24:44",
  "13": "23:57",
  "14": "25:02",
  "15": "24:22",
  "16": "25:26",
  "17": "24:38",
  "18": "25:50",
  "19": "24:38",
  "20": "26:02"
 },
 {
  "0": 30,
  "1": "22:59",
  "2": "24:21",
  "3": "22:59",
  "4": "24:14",
  "5": "22:59",
  "6": "24:12",
  "7": "22:59",
  "8": "24:18",
  "9": "23:10",
  "10": "24:22",
  "11": "23:31",
  "12": "24:41",
  "13": "23:54",
  "14": "24:59",
  "15": "24:19",
  "16": "25:23",
  "17": "24:35",
  "18": "25:47",
  "19": "24:35",
  "20": "25:59"
 },
 {
  "0": 31,
  "1": "22:57",
  "2": "24:19",
  "3": "22:57",
  "4": "24:12",
  "5": "22:57",
  "6": "24:10",
  "7": "22:57",
  "8": "24:16",
  "9": "23:08",
  "10": "24:20",
  "11": "23:29",
  "12": "24:39",
  "13": "23:52",
  "14": "24:57",
  "15": "24:17",
  "16": "25:21",
  "17": "24:33",
  "18": "25:45",
  "19": "24:33",
  "20": "25:57"
 },
 {
  "0": 32,
  "1": "22:55",
  "2": "24:17",
  "3": "22:55",
  "4": "24:10",
  "5": "22:55",
  "6": "24:08",
  "7": "22:55",
  "8": "24:14",
  "9": "23:06",
  "10": "24:18",
  "11": "23:27",
  "12": "24:37",
  "13": "23:50",
  "14": "24:55",
  "15": "24:15",
  "16": "25:19",
  "17": "24:31",
  "18": "25:43",
  "19": "24:31",
  "20": "25:55"
 },
 {
  "0": 33,
  "1": "22:53",
  "2": "24:15",
  "3": "22:53",
  "4": "24:08",
  "5": "22:53",
  "6": "24:06",
  "7": "22:53",
  "8": "24:12",
  "9": "23:04",
  "10": "24:16",
  "11": "23:25",
  "12": "24:35",
  "13": "23:48",
  "14": "24:53",
  "15": "24:13",
  "16": "25:17",
  "17": "24:29",
  "18": "25:41",
  "19": "24:29",
  "20": "25:53"
 },
 {
  "0": 34,
  "1": "22:51",
  "2": "24:13",
  "3": "22:51",
  "4": "24:06",
  "5": "22:51",
  "6": "24:04",
  "7": "22:51",
  "8": "24:10",
  "9": "23:02",
  "10": "24:14",
  "11": "23:23",
  "12": "24:33",
  "13": "23:46",
  "14": "24:51",
  "15": "24:11",
  "16": "25:15",
  "17": "24:27",
  "18": "25:39",
  "19": "24:27",
  "20": "25:51"
 },
 {
  "0": 35,
  "1": "22:49",
  "2": "24:11",
  "3": "22:49",
  "4": "24:04",
  "5": "22:49",
  "6": "24:02",
  "7": "22:49",
  "8": "24:08",
  "9": "23:00",
  "10": "24:12",
  "11": "23:21",
  "12": "24:31",
  "13": "23:44",
  "14": "24:49",
  "15": "24:09",
  "16": "25:13",
  "17": "24:25",
  "18": "25:37",
  "19": "24:25",
  "20": "25:49"
 },
 {
  "0": 36,
  "1": "22:47",
  "2": "24:09",
  "3": "22:47",
  "4": "24:02",
  "5": "22:47",
  "6": "24:00",
  "7": "22:47",
  "8": "24:06",
  "9": "22:58",
  "10": "24:10",
  "11": "23:19",
  "12": "24:29",
  "13": "23:42",
  "14": "24:47",
  "15": "24:07",
  "16": "25:11",
  "17": "24:23",
  "18": "25:35",
  "19": "24:23",
  "20": "25:47"
 },
 {
  "0": 37,
  "1": "22:45",
  "2": "24:07",
  "3": "22:45",
  "4": "24:00",
  "5": "22:45",
  "6": "23:58",
  "7": "22:45",
  "8": "24:04",
  "9": "22:56",
  "10": "24:08",
  "11": "23:17",
  "12": "24:27",
  "13": "23:40",
  "14": "24:45",
  "15": "24:05",
  "16": "25:09",
  "17": "24:21",
  "18": "25:33",
  "19": "24:21",
  "20": "25:45"
 },
 {
  "0": 38,
  "1": "22:43",
  "2": "24:05",
  "3": "22:43",
  "4": "23:58",
  "5": "22:43",
  "6": "23:56",
  "7": "22:43",
  "8": "24:02",
  "9": "22:54",
  "10": "24:06",
  "11": "23:15",
  "12": "24:25",
  "13": "23:38",
  "14": "24:43",
  "15": "24:03",
  "16": "25:07",
  "17": "24:19",
  "18": "25:31",
  "19": "24:19",
  "20": "25:43"
 },
 {
  "0": 39,
  "1": "22:41",
  "2": "24:03",
  "3": "22:41",
  "4": "23:56",
  "5": "22:41",
  "6": "23:54",
  "7": "22:41",
  "8": "24:00",
  "9": "22:52",
  "10": "24:04",
  "11": "23:13",
  "12": "24:23",
  "13": "23:36",
  "14": "24:41",
  "15": "24:01",
  "16": "25:05",
  "17": "24:17",
  "18": "25:29",
  "19": "24:17",
  "20": "25:41"
 },
 {
  "0": 40,
  "1": "22:39",
  "2": "24:01",
  "3": "22:39",
  "4": "23:54",
  "5": "22:39",
  "6": "23:52",
  "7": "22:39",
  "8": "23:58",
  "9": "22:50",
  "10": "24:02",
  "11": "23:11",
  "12": "24:21",
  "13": "23:34",
  "14": "24:39",
  "15": "23:59",
  "16": "25:03",
  "17": "24:15",
  "18": "25:27",
  "19": "24:15",
  "20": "25:39"
 },
 {
  "0": 41,
  "1": "22:37",
  "2": "23:59",
  "3": "22:37",
  "4": "23:52",
  "5": "22:37",
  "6": "23:50",
  "7": "22:37",
  "8": "23:56",
  "9": "22:48",
  "10": "24:00",
  "11": "23:09",
  "12": "24:19",
  "13": "23:32",
  "14": "24:37",
  "15": "23:57",
  "16": "25:01",
  "17": "24:13",
  "18": "25:25",
  "19": "24:13",
  "20": "25:37"
 },
 {
  "0": 42,
  "1": "22:35",
  "2": "23:57",
  "3": "22:35",
  "4": "23:50",
  "5": "22:35",
  "6": "23:48",
  "7": "22:35",
  "8": "23:54",
  "9": "22:46",
  "10": "23:58",
  "11": "23:07",
  "12": "24:17",
  "13": "23:30",
  "14": "24:35",
  "15": "23:55",
  "16": "24:59",
  "17": "24:11",
  "18": "25:23",
  "19": "24:11",
  "20": "25:35"
 },
 {
  "0": 43,
  "1": "22:33",
  "2": "23:55",
  "3": "22:33",
  "4": "23:48",
  "5": "22:33",
  "6": "23:46",
  "7": "22:33",
  "8": "23:52",
  "9": "22:44",
  "10": "23:56",
  "11": "23:05",
  "12": "24:15",
  "13": "23:28",
  "14": "24:33",
  "15": "23:53",
  "16": "24:57",
  "17": "24:09",
  "18": "25:21",
  "19": "24:09",
  "20": "25:33"
 },
 {
  "0": 44,
  "1": "22:31",
  "2": "23:53",
  "3": "22:31",
  "4": "23:46",
  "5": "22:31",
  "6": "23:44",
  "7": "22:31",
  "8": "23:50",
  "9": "22:42",
  "10": "23:54",
  "11": "23:03",
  "12": "24:13",
  "13": "23:26",
  "14": "24:31",
  "15": "23:51",
  "16": "24:55",
  "17": "24:07",
  "18": "25:19",
  "19": "24:07",
  "20": "25:31"
 },
 {
  "0": 45,
  "1": "22:29",
  "2": "23:51",
  "3": "22:29",
  "4": "23:44",
  "5": "22:29",
  "6": "23:42",
  "7": "22:29",
  "8": "23:48",
  "9": "22:40",
  "10": "23:52",
  "11": "23:01",
  "12": "24:11",
  "13": "23:24",
  "14": "24:29",
  "15": "23:49",
  "16": "24:53",
  "17": "24:05",
  "18": "25:17",
  "19": "24:05",
  "20": "25:29"
 },
 {
  "0": 46,
  "1": "22:27",
  "2": "23:49",
  "3": "22:27",
  "4": "23:42",
  "5": "22:27",
  "6": "23:40",
  "7": "22:27",
  "8": "23:46",
  "9": "22:38",
  "10": "23:50",
  "11": "22:59",
  "12": "24:09",
  "13": "23:22",
  "14": "24:27",
  "15": "23:47",
  "16": "24:51",
  "17": "24:03",
  "18": "25:15",
  "19": "24:03",
  "20": "25:27"
 },
 {
  "0": 47,
  "1": "22:25",
  "2": "23:47",
  "3": "22:25",
  "4": "23:40",
  "5": "22:25",
  "6": "23:38",
  "7": "22:25",
  "8": "23:44",
  "9": "22:36",
  "10": "23:48",
  "11": "22:57",
  "12": "24:07",
  "13": "23:20",
  "14": "24:25",
  "15": "23:45",
  "16": "24:49",
  "17": "24:01",
  "18": "25:13",
  "19": "24:01",
  "20": "25:25"
 },
 {
  "0": 48,
  "1": "22:23",
  "2": "23:45",
  "3": "22:23",
  "4": "23:38",
  "5": "22:23",
  "6": "23:36",
  "7": "22:23",
  "8": "23:42",
  "9": "22:34",
  "10": "23:46",
  "11": "22:55",
  "12": "24:05",
  "13": "23:18",
  "14": "24:23",
  "15": "23:43",
  "16": "24:47",
  "17": "23:59",
  "18": "25:11",
  "19": "23:59",
  "20": "25:23"
 },
 {
  "0": 49,
  "1": "22:21",
  "2": "23:43",
  "3": "22:21",
  "4": "23:36",
  "5": "22:21",
  "6": "23:34",
  "7": "22:21",
  "8": "23:40",
  "9": "22:32",
  "10": "23:44",
  "11": "22:53",
  "12": "24:03",
  "13": "23:16",
  "14": "24:21",
  "15": "23:41",
  "16": "24:45",
  "17": "23:57",
  "18": "25:09",
  "19": "23:57",
  "20": "25:21"
 },
 {
  "0": 50,
  "1": "22:19",
  "2": "23:41",
  "3": "22:19",
  "4": "23:34",
  "5": "22:19",
  "6": "23:32",
  "7": "22:19",
  "8": "23:38",
  "9": "22:30",
  "10": "23:42",
  "11": "22:51",
  "12": "24:01",
  "13": "23:14",
  "14": "24:19",
  "15": "23:39",
  "16": "24:43",
  "17": "23:55",
  "18": "25:07",
  "19": "23:55",
  "20": "25:19"
 },
 {
  "0": 51,
  "1": "22:17",
  "2": "23:39",
  "3": "22:17",
  "4": "23:32",
  "5": "22:17",
  "6": "23:30",
  "7": "22:17",
  "8": "23:36",
  "9": "22:28",
  "10": "23:40",
  "11": "22:49",
  "12": "23:59",
  "13": "23:12",
  "14": "24:17",
  "15": "23:37",
  "16": "24:41",
  "17": "23:53",
  "18": "25:05",
  "19": "23:53",
  "20": "25:17"
 },
 {
  "0": 52,
  "1": "22:15",
  "2": "23:37",
  "3": "22:15",
  "4": "23:30",
  "5": "22:15",
  "6": "23:28",
  "7": "22:15",
  "8": "23:34",
  "9": "22:26",
  "10": "23:38",
  "11": "22:47",
  "12": "23:57",
  "13": "23:10",
  "14": "24:15",
  "15": "23:35",
  "16": "24:39",
  "17": "23:51",
  "18": "25:03",
  "19": "23:51",
  "20": "25:15"
 },
 {
  "0": 53,
  "1": "22:13",
  "2": "23:35",
  "3": "22:13",
  "4": "23:28",
  "5": "22:13",
  "6": "23:26",
  "7": "22:13",
  "8": "23:32",
  "9": "22:24",
  "10": "23:36",
  "11": "22:45",
  "12": "23:55",
  "13": "23:08",
  "14": "24:13",
  "15": "23:33",
  "16": "24:37",
  "17": "23:49",
  "18": "25:01",
  "19": "23:49",
  "20": "25:13"
 },
 {
  "0": 54,
  "1": "22:11",
  "2": "23:33",
  "3": "22:11",
  "4": "23:26",
  "5": "22:11",
  "6": "23:24",
  "7": "22:11",
  "8": "23:30",
  "9": "22:22",
  "10": "23:34",
  "11": "22:43",
  "12": "23:53",
  "13": "23:06",
  "14": "24:11",
  "15": "23:31",
  "16": "24:35",
  "17": "23:47",
  "18": "24:59",
  "19": "23:47",
  "20": "25:11"
 },
 {
  "0": 55,
  "1": "22:09",
  "2": "23:31",
  "3": "22:09",
  "4": "23:24",
  "5": "22:09",
  "6": "23:22",
  "7": "22:09",
  "8": "23:28",
  "9": "22:20",
  "10": "23:32",
  "11": "22:41",
  "12": "23:51",
  "13": "23:04",
  "14": "24:09",
  "15": "23:29",
  "16": "24:33",
  "17": "23:45",
  "18": "24:57",
  "19": "23:45",
  "20": "25:09"
 },
 {
  "0": 56,
  "1": "22:07",
  "2": "23:29",
  "3": "22:07",
  "4": "23:22",
  "5": "22:07",
  "6": "23:20",
  "7": "22:07",
  "8": "23:26",
  "9": "22:18",
  "10": "23:30",
  "11": "22:39",
  "12": "23:49",
  "13": "23:02",
  "14": "24:07",
  "15": "23:27",
  "16": "24:31",
  "17": "23:43",
  "18": "24:55",
  "19": "23:43",
  "20": "25:07"
 },
 {
  "0": 57,
  "1": "22:05",
  "2": "23:27",
  "3": "22:05",
  "4": "23:20",
  "5": "22:05",
  "6": "23:18",
  "7": "22:05",
  "8": "23:24",
  "9": "22:16",
  "10": "23:28",
  "11": "22:37",
  "12": "23:47",
  "13": "23:00",
  "14": "24:05",
  "15": "23:25",
  "16": "24:29",
  "17": "23:41",
  "18": "24:53",
  "19": "23:41",
  "20": "25:05"
 },
 {
  "0": 58,
  "1": "22:03",
  "2": "23:25",
  "3": "22:03",
  "4": "23:18",
  "5": "22:03",
  "6": "23:16",
  "7": "22:03",
  "8": "23:22",
  "9": "22:14",
  "10": "23:26",
  "11": "22:35",
  "12": "23:45",
  "13": "22:58",
  "14": "24:03",
  "15": "23:23",
  "16": "24:27",
  "17": "23:39",
  "18": "24:51",
  "19": "23:39",
  "20": "25:03"
 },
 {
  "0": 59,
  "1": "22:01",
  "2": "23:23",
  "3": "22:01",
  "4": "23:16",
  "5": "22:01",
  "6": "23:14",
  "7": "22:01",
  "8": "23:20",
  "9": "22:12",
  "10": "23:24",
  "11": "22:33",
  "12": "23:43",
  "13": "22:56",
  "14": "24:01",
  "15": "23:21",
  "16": "24:25",
  "17": "23:37",
  "18": "24:49",
  "19": "23:37",
  "20": "25:01"
 },
 {
  "0": 60,
  "1": "22:00",
  "2": "23:22",
  "3": "22:00",
  "4": "23:15",
  "5": "22:00",
  "6": "23:13",
  "7": "22:00",
  "8": "23:19",
  "9": "22:11",
  "10": "23:23",
  "11": "22:32",
  "12": "23:42",
  "13": "22:55",
  "14": "24:00",
  "15": "23:20",
  "16": "24:24",
  "17": "23:36",
  "18": "24:48",
  "19": "23:36",
  "20": "25:00"
 },
 {
  "0": 61,
  "1": "21:03",
  "2": "22:58",
  "3": "21:14",
  "4": "22:50",
  "5": "21:00",
  "6": "22:46",
  "7": "21:01",
  "8": "22:55",
  "9": "21:17",
  "10": "23:00",
  "11": "21:44",
  "12": "23:10",
  "13": "22:17",
  "14": "23:25",
  "15": "22:53",
  "16": "23:44",
  "17": "23:12",
  "18": "24:05",
  "19": "23:12",
  "20": "24:11"
 },
 {
  "0": 62,
  "1": "20:42",
  "2": "22:35",
  "3": "20:52",
  "4": "22:24",
  "5": "20:46",
  "6": "22:18",
  "7": "20:49",
  "8": "22:30",
  "9": "21:00",
  "10": "22:34",
  "11": "21:00",
  "12": "22:53",
  "13": "21:43",
  "14": "22:50",
  "15": "22:24",
  "16": "23:04",
  "17": "22:58",
  "18": "23:22",
  "19": "22:58",
  "20": "23:22"
 },
 {
  "0": 63,
  "1": "20:19",
  "2": "22:11",
  "3": "20:30",
  "4": "22:03",
  "5": "20:25",
  "6": "21:56",
  "7": "20:29",
  "8": "22:06",
  "9": "20:41",
  "10": "22:11",
  "11": "20:58",
  "12": "22:36",
  "13": "21:13",
  "14": "22:35",
  "15": "22:00",
  "16": "22:52",
  "17": "22:39",
  "18": "23:01",
  "19": "22:39",
  "20": "23:04"
 },
 {
  "0": 64,
  "1": "20:00",
  "2": "21:54",
  "3": "20:12",
  "4": "21:44",
  "5": "20:06",
  "6": "21:34",
  "7": "20:11",
  "8": "21:48",
  "9": "20:22",
  "10": "21:56",
  "11": "20:40",
  "12": "22:20",
  "13": "21:00",
  "14": "22:28",
  "15": "21:39",
  "16": "22:43",
  "17": "22:21",
  "18": "22:43",
  "19": "22:21",
  "20": "22:50"
 },
 {
  "0": 65,
  "1": "19:43",
  "2": "21:37",
  "3": "19:56",
  "4": "21:24",
  "5": "19:51",
  "6": "21:15",
  "7": "19:56",
  "8": "21:27",
  "9": "20:07",
  "10": "21:36",
  "11": "20:25",
  "12": "22:05",
  "13": "20:53",
  "14": "22:13",
  "15": "21:16",
  "16": "22:33",
  "17": "22:03",
  "18": "22:33",
  "19": "22:03",
  "20": "22:44"
 },
 {
  "0": 66,
  "1": "19:27",
  "2": "21:20",
  "3": "19:40",
  "4": "21:06",
  "5": "19:36",
  "6": "21:00",
  "7": "19:41",
  "8": "21:05",
  "9": "19:55",
  "10": "21:20",
  "11": "20:10",
  "12": "21:51",
  "13": "20:40",
  "14": "22:01",
  "15": "21:00",
  "16": "22:23",
  "17": "21:47",
  "18": "22:23",
  "19": "21:47",
  "20": "22:31"
 },
 {
  "0": 67,
  "1": "19:12",
  "2": "21:03",
  "3": "19:26",
  "4": "21:00",
  "5": "19:22",
  "6": "20:56",
  "7": "19:28",
  "8": "21:00",
  "9": "19:42",
  "10": "21:04",
  "11": "20:00",
  "12": "21:35",
  "13": "20:28",
  "14": "21:49",
  "15": "20:59",
  "16": "22:09",
  "17": "21:35",
  "18": "22:09",
  "19": "21:35",
  "20": "22:15"
 },
 {
  "0": 68,
  "1": "19:00",
  "2": "20:58",
  "3": "19:13",
  "4": "20:53",
  "5": "19:09",
  "6": "20:45",
  "7": "19:16",
  "8": "20:54",
  "9": "19:30",
  "10": "21:00",
  "11": "19:50",
  "12": "21:20",
  "13": "20:17",
  "14": "21:36",
  "15": "20:49",
  "16": "21:59",
  "17": "21:19",
  "18": "21:59",
  "19": "21:19",
  "20": "22:02"
 },
 {
  "0": 69,
  "1": "18:47",
  "2": "20:49",
  "3": "19:00",
  "4": "20:43",
  "5": "18:58",
  "6": "20:36",
  "7": "19:03",
  "8": "20:44",
  "9": "19:19",
  "10": "20:55",
  "11": "19:38",
  "12": "21:08",
  "13": "20:06",
  "14": "21:24",
  "15": "20:39",
  "16": "21:43",
  "17": "21:01",
  "18": "21:43",
  "19": "21:01",
  "20": "21:43"
 },
 {
  "0": 70,
  "1": "18:35",
  "2": "20:40",
  "3": "18:50",
  "4": "20:32",
  "5": "18:46",
  "6": "20:25",
  "7": "18:53",
  "8": "20:35",
  "9": "19:06",
  "10": "20:46",
  "11": "19:26",
  "12": "21:00",
  "13": "19:57",
  "14": "21:09",
  "15": "20:27",
  "16": "21:40",
  "17": "21:00",
  "18": "21:40",
  "19": "21:00",
  "20": "21:40"
 },
 {
  "0": 71,
  "1": "18:23",
  "2": "20:30",
  "3": "18:38",
  "4": "20:23",
  "5": "18:35",
  "6": "20:16",
  "7": "18:43",
  "8": "20:27",
  "9": "18:57",
  "10": "20:38",
  "11": "19:16",
  "12": "20:57",
  "13": "19:47",
  "14": "20:52",
  "15": "20:18",
  "16": "21:32",
  "17": "20:54",
  "18": "21:32",
  "19": "20:54",
  "20": "21:32"
 },
 {
  "0": 72,
  "1": "18:12",
  "2": "20:21",
  "3": "18:28",
  "4": "20:14",
  "5": "18:25",
  "6": "20:07",
  "7": "18:33",
  "8": "20:18",
  "9": "18:47",
  "10": "20:30",
  "11": "19:04",
  "12": "20:47",
  "13": "19:36",
  "14": "20:44",
  "15": "20:08",
  "16": "21:15",
  "17": "20:46",
  "18": "21:15",
  "19": "20:46",
  "20": "21:15"
 },
 {
  "0": 73,
  "1": "18:01",
  "2": "20:12",
  "3": "18:17",
  "4": "20:04",
  "5": "18:15",
  "6": "19:59",
  "7": "18:23",
  "8": "20:10",
  "9": "18:37",
  "10": "20:20",
  "11": "18:56",
  "12": "20:38",
  "13": "19:27",
  "14": "20:43",
  "15": "20:00",
  "16": "21:03",
  "17": "20:41",
  "18": "21:03",
  "19": "20:41",
  "20": "21:03"
 },
 {
  "0": 74,
  "1": "17:52",
  "2": "20:03",
  "3": "18:07",
  "4": "19:56",
  "5": "18:05",
  "6": "19:50",
  "7": "18:12",
  "8": "20:01",
  "9": "18:28",
  "10": "20:12",
  "11": "18:46",
  "12": "20:32",
  "13": "19:17",
  "14": "20:36",
  "15": "19:53",
  "16": "20:50",
  "17": "20:31",
  "18": "20:50",
  "19": "20:31",
  "20": "20:50"
 },
 {
  "0": 75,
  "1": "17:43",
  "2": "19:56",
  "3": "17:59",
  "4": "19:47",
  "5": "17:58",
  "6": "19:42",
  "7": "18:03",
  "8": "19:55",
  "9": "18:18",
  "10": "20:02",
  "11": "18:37",
  "12": "20:21",
  "13": "19:07",
  "14": "20:29",
  "15": "19:45",
  "16": "20:44",
  "17": "20:22",
  "18": "20:44",
  "19": "20:22",
  "20": "20:44"
 },
 {
  "0": 76,
  "1": "17:33",
  "2": "19:48",
  "3": "17:49",
  "4": "19:39",
  "5": "17:50",
  "6": "19:33",
  "7": "17:56",
  "8": "19:47",
  "9": "18:10",
  "10": "19:56",
  "11": "18:29",
  "12": "20:12",
  "13": "19:00",
  "14": "20:20",
  "15": "19:36",
  "16": "20:44",
  "17": "20:14",
  "18": "20:44",
  "19": "20:14",
  "20": "20:44"
 },
 {
  "0": 77,
  "1": "17:24",
  "2": "19:40",
  "3": "17:40",
  "4": "19:30",
  "5": "17:41",
  "6": "19:25",
  "7": "17:48",
  "8": "19:40",
  "9": "18:00",
  "10": "19:48",
  "11": "18:20",
  "12": "20:02",
  "13": "18:51",
  "14": "20:14",
  "15": "19:30",
  "16": "20:43",
  "17": "20:07",
  "18": "20:43",
  "19": "20:07",
  "20": "20:43"
 },
 {
  "0": 78,
  "1": "17:15",
  "2": "19:32",
  "3": "17:30",
  "4": "19:21",
  "5": "17:31",
  "6": "19:16",
  "7": "17:40",
  "8": "19:31",
  "9": "17:54",
  "10": "19:37",
  "11": "18:10",
  "12": "19:58",
  "13": "18:42",
  "14": "20:06",
  "15": "19:21",
  "16": "20:38",
  "17": "19:59",
  "18": "20:38",
  "19": "19:59",
  "20": "20:38"
 },
 {
  "0": 79,
  "1": "17:05",
  "2": "19:24",
  "3": "17:22",
  "4": "19:12",
  "5": "17:23",
  "6": "19:08",
  "7": "17:31",
  "8": "19:23",
  "9": "17:45",
  "10": "19:30",
  "11": "18:00",
  "12": "19:51",
  "13": "18:33",
  "14": "19:59",
  "15": "19:13",
  "16": "20:31",
  "17": "19:51",
  "18": "20:31",
  "19": "19:51",
  "20": "20:31"
 },
 {
  "0": 80,
  "1": "16:57",
  "2": "19:17",
  "3": "17:13",
  "4": "19:03",
  "5": "17:14",
  "6": "19:00",
  "7": "17:23",
  "8": "19:15",
  "9": "17:38",
  "10": "19:22",
  "11": "17:55",
  "12": "19:45",
  "13": "18:26",
  "14": "19:52",
  "15": "19:03",
  "16": "20:22",
  "17": "19:45",
  "18": "20:22",
  "19": "19:45",
  "20": "20:22"
 },
 {
  "0": 81,
  "1": "16:48",
  "2": "19:09",
  "3": "17:04",
  "4": "18:56",
  "5": "17:05",
  "6": "18:54",
  "7": "17:15",
  "8": "19:07",
  "9": "17:30",
  "10": "19:14",
  "11": "17:47",
  "12": "19:36",
  "13": "18:16",
  "14": "19:45",
  "15": "18:56",
  "16": "20:17",
  "17": "19:36",
  "18": "20:17",
  "19": "19:36",
  "20": "20:17"
 },
 {
  "0": 82,
  "1": "16:39",
  "2": "19:00",
  "3": "16:55",
  "4": "18:48",
  "5": "16:58",
  "6": "18:46",
  "7": "17:05",
  "8": "19:00",
  "9": "17:21",
  "10": "19:05",
  "11": "17:38",
  "12": "19:27",
  "13": "18:06",
  "14": "19:40",
  "15": "18:49",
  "16": "20:07",
  "17": "19:27",
  "18": "20:07",
  "19": "19:27",
  "20": "20:07"
 },
 {
  "0": 83,
  "1": "16:30",
  "2": "18:54",
  "3": "16:46",
  "4": "18:39",
  "5": "16:49",
  "6": "18:37",
  "7": "16:58",
  "8": "18:52",
  "9": "17:13",
  "10": "19:00",
  "11": "17:30",
  "12": "19:19",
  "13": "18:00",
  "14": "19:30",
  "15": "18:40",
  "16": "20:02",
  "17": "19:17",
  "18": "20:02",
  "19": "19:17",
  "20": "20:02"
 },
 {
  "0": 84,
  "1": "16:22",
  "2": "18:46",
  "3": "16:37",
  "4": "18:30",
  "5": "16:41",
  "6": "18:30",
  "7": "16:49",
  "8": "18:45",
  "9": "17:03",
  "10": "18:52",
  "11": "17:21",
  "12": "19:10",
  "13": "17:51",
  "14": "19:22",
  "15": "18:32",
  "16": "19:58",
  "17": "19:07",
  "18": "19:58",
  "19": "19:07",
  "20": "19:58"
 },
 {
  "0": 85,
  "1": "16:14",
  "2": "18:39",
  "3": "16:29",
  "4": "18:23",
  "5": "16:32",
  "6": "18:21",
  "7": "16:41",
  "8": "18:36",
  "9": "16:55",
  "10": "18:45",
  "11": "17:12",
  "12": "19:01",
  "13": "17:43",
  "14": "19:13",
  "15": "18:24",
  "16": "19:45",
  "17": "19:00",
  "18": "19:45",
  "19": "19:00",
  "20": "19:45"
 },
 {
  "0": 86,
  "1": "16:05",
  "2": "18:30",
  "3": "16:20",
  "4": "18:13",
  "5": "16:24",
  "6": "18:12",
  "7": "16:32",
  "8": "18:27",
  "9": "16:46",
  "10": "18:36",
  "11": "17:04",
  "12": "18:53",
  "13": "17:34",
  "14": "19:04",
  "15": "18:15",
  "16": "19:36",
  "17": "18:53",
  "18": "19:41",
  "19": "18:53",
  "20": "19:41"
 },
 {
  "0": 87,
  "1": "15:57",
  "2": "18:22",
  "3": "16:10",
  "4": "18:04",
  "5": "16:15",
  "6": "18:02",
  "7": "16:25",
  "8": "18:18",
  "9": "16:38",
  "10": "18:30",
  "11": "16:55",
  "12": "18:44",
  "13": "17:26",
  "14": "18:55",
  "15": "18:04",
  "16": "19:29",
  "17": "18:45",
  "18": "19:29",
  "19": "18:45",
  "20": "19:29"
 },
 {
  "0": 88,
  "1": "15:48",
  "2": "18:13",
  "3": "16:01",
  "4": "17:57",
  "5": "16:05",
  "6": "17:55",
  "7": "16:16",
  "8": "18:09",
  "9": "16:30",
  "10": "18:20",
  "11": "16:47",
  "12": "18:35",
  "13": "17:16",
  "14": "18:44",
  "15": "17:57",
  "16": "19:14",
  "17": "18:36",
  "18": "19:14",
  "19": "18:36",
  "20": "19:14"
 },
 {
  "0": 89,
  "1": "15:39",
  "2": "18:04",
  "3": "15:53",
  "4": "17:47",
  "5": "15:58",
  "6": "17:46",
  "7": "16:07",
  "8": "18:00",
  "9": "16:20",
  "10": "18:10",
  "11": "16:38",
  "12": "18:26",
  "13": "17:07",
  "14": "18:34",
  "15": "17:48",
  "16": "19:02",
  "17": "18:25",
  "18": "19:04",
  "19": "18:25",
  "20": "19:04"
 },
 {
  "0": 90,
  "1": "15:30",
  "2": "17:56",
  "3": "15:43",
  "4": "17:37",
  "5": "15:48",
  "6": "17:35",
  "7": "15:58",
  "8": "17:50",
  "9": "16:10",
  "10": "18:00",
  "11": "16:28",
  "12": "18:16",
  "13": "16:57",
  "14": "18:24",
  "15": "17:36",
  "16": "18:53",
  "17": "18:17",
  "18": "18:59",
  "19": "18:17",
  "20": "18:59"
 },
 {
  "0": 91,
  "1": "15:20",
  "2": "17:47",
  "3": "15:32",
  "4": "17:28",
  "5": "15:39",
  "6": "17:27",
  "7": "15:49",
  "8": "17:41",
  "9": "16:00",
  "10": "17:52",
  "11": "16:18",
  "12": "18:06",
  "13": "16:46",
  "14": "18:12",
  "15": "17:26",
  "16": "18:40",
  "17": "18:07",
  "18": "18:56",
  "19": "18:07",
  "20": "18:56"
 },
 {
  "0": 92,
  "1": "15:11",
  "2": "17:37",
  "3": "15:23",
  "4": "17:16",
  "5": "15:30",
  "6": "17:17",
  "7": "15:39",
  "8": "17:30",
  "9": "15:51",
  "10": "17:41",
  "11": "16:06",
  "12": "17:56",
  "13": "16:36",
  "14": "18:00",
  "15": "17:16",
  "16": "18:30",
  "17": "17:57",
  "18": "18:48",
  "19": "17:57",
  "20": "18:48"
 },
 {
  "0": 93,
  "1": "15:00",
  "2": "17:27",
  "3": "15:13",
  "4": "17:04",
  "5": "15:19",
  "6": "17:05",
  "7": "15:30",
  "8": "17:20",
  "9": "15:41",
  "10": "17:30",
  "11": "15:56",
  "12": "17:47",
  "13": "16:27",
  "14": "17:53",
  "15": "17:06",
  "16": "18:20",
  "17": "17:45",
  "18": "18:46",
  "19": "17:45",
  "20": "18:46"
 },
 {
  "0": 94,
  "1": "14:51",
  "2": "17:14",
  "3": "15:01",
  "4": "16:52",
  "5": "15:07",
  "6": "16:54",
  "7": "15:18",
  "8": "17:08",
  "9": "15:30",
  "10": "17:18",
  "11": "15:45",
  "12": "17:35",
  "13": "16:15",
  "14": "17:39",
  "15": "16:55",
  "16": "18:13",
  "17": "17:27",
  "18": "18:36",
  "19": "17:27",
  "20": "18:36"
 },
 {
  "0": 95,
  "1": "14:40",
  "2": "17:01",
  "3": "14:50",
  "4": "16:39",
  "5": "14:57",
  "6": "16:40",
  "7": "15:06",
  "8": "16:57",
  "9": "15:19",
  "10": "17:04",
  "11": "15:33",
  "12": "17:24",
  "13": "16:02",
  "14": "17:28",
  "15": "16:42",
  "16": "18:00",
  "17": "17:14",
  "18": "18:31",
  "19": "17:14",
  "20": "18:31"
 },
 {
  "0": 96,
  "1": "14:28",
  "2": "16:48",
  "3": "14:38",
  "4": "16:26",
  "5": "14:45",
  "6": "16:26",
  "7": "14:54",
  "8": "16:43",
  "9": "15:06",
  "10": "16:51",
  "11": "15:20",
  "12": "17:07",
  "13": "15:48",
  "14": "17:15",
  "15": "16:28",
  "16": "17:50",
  "17": "16:58",
  "18": "18:25",
  "19": "16:58",
  "20": "18:25"
 },
 {
  "0": 97,
  "1": "14:15",
  "2": "16:34",
  "3": "14:25",
  "4": "16:09",
  "5": "14:31",
  "6": "16:10",
  "7": "14:40",
  "8": "16:28",
  "9": "14:53",
  "10": "16:36",
  "11": "15:04",
  "12": "16:50",
  "13": "15:32",
  "14": "16:56",
  "15": "16:14",
  "16": "17:38",
  "17": "16:44",
  "18": "18:00",
  "19": "16:44",
  "20": "18:00"
 },
 {
  "0": 98,
  "1": "14:00",
  "2": "16:16",
  "3": "14:08",
  "4": "15:51",
  "5": "14:15",
  "6": "15:53",
  "7": "14:25",
  "8": "16:07",
  "9": "14:37",
  "10": "16:18",
  "11": "14:48",
  "12": "16:35",
  "13": "15:14",
  "14": "16:36",
  "15": "15:55",
  "16": "17:22",
  "17": "16:22",
  "18": "17:56",
  "19": "16:22",
  "20": "17:56"
 },
 {
  "0": 99,
  "1": "13:42",
  "2": "15:55",
  "3": "13:50",
  "4": "15:30",
  "5": "13:58",
  "6": "15:30",
  "7": "14:06",
  "8": "15:46",
  "9": "14:20",
  "10": "15:56",
  "11": "14:29",
  "12": "16:12",
  "13": "14:54",
  "14": "16:14",
  "15": "15:34",
  "16": "17:01",
  "17": "15:55",
  "18": "17:47",
  "19": "15:55",
  "20": "17:47"
 },
 {
  "0": 100,
  "1": "13:22",
  "2": "15:29",
  "3": "13:27",
  "4": "15:00",
  "5": "13:31",
  "6": "15:00",
  "7": "13:42",
  "8": "15:18",
  "9": "13:58",
  "10": "15:30",
  "11": "14:05",
  "12": "15:49",
  "13": "14:30",
  "14": "15:58",
  "15": "15:09",
  "16": "16:29",
  "17": "15:28",
  "18": "17:18",
  "19": "15:28",
  "20": "17:18"
 },
 {
  "0": "Points",
  "1": "MF",
  "3": "MF",
  "5": "MF",
  "7": "MF",
  "9": "MF",
  "11": "MF",
  "13": "MF",
  "15": "MF",
  "17": "MF",
  "19": "MF"
 },
 {
  "1": "Two-MileRun(2MR)(Overalltime-minutesandseconds)"
 },
 {
  "1": "17-21",
  "3": "22-26",
  "5": "27-31",
  "7": "32-36",
  "9": "37-41",
  "11": "42-46",
  "13": "47-51",
  "15": "52-56",
  "17": "57-61",
  "19": "Over62"
 }
]
}

export default data;